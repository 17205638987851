<template>
  <div class="mb-3">
    <div class="card mb-3">
      <div class="card-body">
        <div class="mb-0 table-responsive" v-if="approvals.length > 0" :key="counter">
          <table class="table datatable table-hover w-100">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="true"
                  data-class-name="text-center align-middle"
                  data-visible="false"
                >
                  {{ I18n.t("submitted_at", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  v-if="may_approve && status == 'submitted'"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="approval_select_all"
                      class="custom-control-input"
                      v-model="select_all"
                    />
                    <label
                      for="approval_select_all"
                      class="custom-control-label"
                      >{{ I18n.t("select_all") }}</label
                    >
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("approvable", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("current_content", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("event", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("content_changes", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t('activerecord.models.company') }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t('activerecord.attributes.contract.discount_percent') }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t('activerecord.attributes.contract.tax_percent') }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="true"
                  data-class-name="text-center align-middle"
                  v-if="status != 'submitted'"
                >
                  {{ I18n.t("approved_at", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="true"
                  data-class-name="text-center align-middle"
                   v-if="status != 'submitted'"
                >
                  {{ I18n.t("approver", locale_scope) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="approval in approvals"
                :key="`approval_${approval.id}`"
              >
                <td scope="row">{{ approval.submitted_at }}</td>
                <td
                  class="text-center align-middle"
                  v-if="may_approve && status == 'submitted'"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      :id="`approval_${approval.id}`"
                      v-model="selected_approvals"
                      :value="approval.id"
                      class="custom-control-input approval_checkbox"
                    />
                    <label
                      :for="`approval_${approval.id}`"
                      class="custom-control-label"
                    ></label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <span v-html="approval.show"></span>
                </td>
                <td class="text-center align-middle">
                  <span v-html="approval.current_content"></span>
                </td>
                <td class="text-center align-middle">
                  {{ approval.event }}
                </td>
                <td class="text-center align-middle">
                  <approval-modal :approval="approval" :title="approval.current_content"></approval-modal>
                </td>
                <td class="text-center align-middle">
                  {{ approval.company }}
                </td>
                <td class="text-center align-middle">
                  {{ approval.discount_percent }}
                </td>
                <td class="text-center align-middle">
                  {{ approval.tax_percent }}
                </td>
                <td
                  class="text-center align-middle"
                  v-if="status != 'submitted'"
                >
                  {{ approval.approved_at }}
                </td>
                <td class="text-center align-middle"  v-if="status != 'submitted'">
                  {{ approval.approver }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h6 class="card-title" v-else>無審核資源</h6>
      </div>
    </div>
    <div class="card" v-if="selected_approvals.length > 0">
      <div class="card-header">
        {{ I18n.t("action") }}
      </div>
      <div class="card-body">
        <p class="card-text">
          {{ I18n.t("selected_item", { count: selected_approvals.length }) }}
        </p>
        <div class="form-group text optional approval_message">
          <label for="approval_message" class="text optional">
            {{ I18n.t("message", locale_scope) }}
          </label>
          <textarea
            name="message"
            id="approval_message"
            v-model.trim="message"
            class="form-control text optional"
          ></textarea>
        </div>
        <button
          type="button"
          class="btn btn-danger"
          @click.prevent="submit('reject')"
        >
          {{ I18n.t("approval.aasm_event.reject") }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click.prevent="submit('approve')"
        >
          {{ I18n.t("approval.aasm_event.approve") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ApprovalModal from "./ApprovalModal.vue";

export default {
  components: {
    ApprovalModal,
  },
  props: {
    status: {
      type: String,
    },
    may_approve: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: "activerecord.attributes.approval",
      },
      sort_column: '0',
      approvals: [],
      selected_approvals: [],
      message: null,
      select_all: false,
      dataTable: null,
      counter: 0,
    };
  },
  mounted() {
    this.getApprovals();
  },
  methods: {
    resetState() {
      this.selected_approvals = [];
      this.message = null;
      this.select_all = false;
    },
    getApprovals() {
      axios.get(this.url).then(({ data }) => {
        this.approvals = data;
        this.$nextTick(() => {
            this.dataTable = $(this.$el.querySelector(".datatable"))
              .DataTable({
                aLengthMenu: [10, 25, 50],
                responsive: true,
                order: [[this.sort_column, "desc"]],
                language: {
                  url: `/datatable.${I18n.currentLocale()}.lang.json`,
                },
              })
              .columns.adjust();
        });
      });
    },
    submit(event) {
      Swal.fire({
        title: I18n.t("confirm_action"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: I18n.t("true"),
        cancelButtonText: I18n.t("cancel"),
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: true,
      }).then(({ value }) => {
        if (value) {
          const data = {
            authenticity_token: document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
            message: this.message,
            aasm_event: event,
            ids: this.selected_approvals,
          };
          axios
            .put("/approvals/batch_update", data)
            .then(({ data }) => {
              if (data.code == 200) {
                this.resetState();
                this.counter += 1;
                this.getApprovals();
                Toastr("success", data.message);
              } else if (data.code == 500) {
                Toastr("error", data.message);
              }
            })
            .catch((error) => {
              Toastr("error", error.body);
            });
        }
      });
    },
  },
  watch: {
    select_all: function(value) {
      this.selected_approvals = [];
      const vm = this
      if (value) {
        vm.$nextTick(() => {
          $('.approval_checkbox').each((index, input) => {
            vm.selected_approvals.push($(input).val())
            $(input).prop("checked", true);
          })
        })
      } else {
        $('.approval_checkbox').prop("checked", false);
      }
    },
    selected_approvals: function(value) {
      const approvals_length = this.approvals.length;
      $("#approval_select_all").prop("indeterminate", false);
      if (value.length == 0) {
        $("#approval_select_all").prop("checked", false);
      } else if (value.length == approvals_length) {
        $("#approval_select_all").prop("checked", true);
      } else {
        $("#approval_select_all").prop("indeterminate", true);
      }
    },
  },
};
</script>

<template>
  <div class="form-group">
    <label for="domain">{{ I18n.t('domain', locale_scope) }}
      <span v-if="domain.length > 0">
        <span class="valid" v-if="result">
          <i class="far fa-check-circle text-success"></i>
          <small class="text-success">此域名可以使用</small>
        </span>
        <span v-else>
          <i class="fas fa-ban text-danger"></i>
          <small class="text-danger">此為無效域名，請重新輸入</small>
        </span>
      </span>
    </label>
    <ValidationProvider :vid="'domain'" rules="required" :name="I18n.t('domain', locale_scope)" v-slot="{ errors }" immediate>
    <input type="text" id="domain" class="form-control" v-model.trim="domain" @keyup="verify" :name="name" :disabled="disabled">
    <small class="text-muted d-block">輸入後將驗證網域是否可使用，格式範例：example.com。</small>
    <span class="text-danger">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    name: {
      type: String
    },
    current_domain: {
      type: String
    },
    disabled: {
      type: Number
    }
  },
  data(){
    return {
      I18n,
      domain: this.current_domain || '',
      cancelSource: null,
      result: true,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/customer'
      },
    }
  },
  methods: {
    verify(){
      this.result = false
      if (this.cancelSource){
        this.cancelSource.cancel()
      }
      this.cancelSource = axios.CancelToken.source();
      axios.get('/workspace_domain_verify.json', {
        cancelToken: this.cancelSource.token,
        params: {
          domain: this.domain
        }
      }).then( ({data}) => {
        this.result = data
        this.cancelSource = null

        // 往上傳值
        this.$emit('getdomain', this.domain)
      }).catch((error) => {

      })
    },
  }
}
</script>

<style lang="scss">
</style>
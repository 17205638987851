<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover" data-order='[[ "1", "desc" ]]' :data-ajax="url">
      <thead>
        <tr>
          <th scope="col" data-name="message" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('message', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.notification'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>

<style lang="scss" >

</style>
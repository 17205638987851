<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('payment_conditions', locale_scope) }}
    </div>
    <div class="card-body">
      <div class="row">
        <template v-if="adjust_units">
          <!-- 加購服務付款方式 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_add_on_payment_method">{{ I18n.t('add_on_payment_method', locale_scope) }}</label>
              <select id="entitlement_add_on_payment_method" name="entitlement[add_on_payment_method]" class="custom-select" v-model="entitlement.add_on_payment_method">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(add_on_payment_method, add_on_payment_method_index) in add_on_payment_methods" :key="add_on_payment_method_index" :value="add_on_payment_method">
                  {{ I18n.t(`simple_form.labels.entitlement.add_on_payment_methods.${add_on_payment_method}`) }}
                </option>
              </select>
            </div>
          </div>
        </template>

        <!-- 開通服務付款方式 -->
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="entitlement_activate_payment_method">{{ I18n.t('activate_payment_method', locale_scope) }}</label>
            <ValidationProvider rules="required" :name="I18n.t('activate_payment_method', locale_scope)" v-slot="{ errors }" immediate>
            <select id="entitlement_activate_payment_method" name="entitlement[activate_payment_method]" class="custom-select" v-model="entitlement.activate_payment_method">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(activate_payment_method, activate_payment_method_index) in activate_payment_methods" :key="activate_payment_method_index" :value="activate_payment_method">
                {{ I18n.t(`simple_form.labels.entitlement.activate_payment_methods.${activate_payment_method}`) }}
              </option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    entitlement: {
      type: Object,
      requred: true
    },
    activate_payment_methods: {
      type: Array
    },
    add_on_payment_methods: {
      type: Array
    },
    adjust_units: {
      type: Boolean
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.workspace/entitlement'
      }
    }
  }
}
</script>

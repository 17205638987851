<template>
  <div>
    <!-- 設定出帳期間 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('set_billing_period', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="row">
          <label for="date" class="col-form-label col-sm-2">{{ I18n.t('billing_period', locale_scope) }}</label>
          <div class="d-flex align-items-center">
            <date-picker class="mr-3" :name="'special_billing[start_date]'" @changeDate="changeStartDate" :date-config="{ maxDate: date }"></date-picker>
            <span>至</span>
            <date-picker class="ml-3" :name="'special_billing[ebd_date]'" @changeDate="changeEndDate" :date-config="{ maxDate: date }"></date-picker>
          </div>
        </div>
        <small class="d-block text-danger mt-2">
          ※ 因應 GCP 帳務架構，當出帳期間非完整月份，會另外產出不足月的資料供 BU 驗證前次出帳是否需向客戶補收
          <br>
          例：設定的出帳期間為 1/11 ~ 2/10，會另外產出 1/1 ~ 1/10 的資料
        </small>
      </div>
    </div>

    <!-- 出帳資料設定 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('set_billing_data', locale_scope)}}
      </div>

      <div class="card-body">
        <!-- 選擇 Project ID： -->
        <div class="mb-3 row">
          <label for="project_id" class="form-control-label col-sm-2">{{ I18n.t('select_project', locale_scope)}}</label>
            <div class="col-sm-10">
              <input id="project_id" name="special_billing[project_id]" type="hidden">
              <multiselect
                v-model.trim="special_billing_project_ids"
                placeholder="請選擇"
                label="project_id"
                track-by="project_id"
                :options="specail_billing_active_project"
                :multiple="true"
                :show-labels="false"
                @tag="addProjectList"
              >
              </multiselect>
            </div>
        </div>

        <!-- so_cost 折扣%數 -->
        <div class="mb-3 row">
          <label for="edp_discount" class="col-sm-2 col-form-label">{{ I18n.t('edp_discount', locale_scope)}}</label>
          <div class="form-group col-sm-10">
            <div class="input-group">
              <input
                id="edp_discount"
                name='special_billing[edp_discount]'
                type="text"
                class="form-control"
                placeholder="0.0"
                v-model.trim="special_billing_edp_discount"
              >
              <div class="input-group-append">
                <span class="input-group-text">％</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 需濾掉 Credit ID -->
        <div class="mb-3 row">
          <label for="credit_id" class="col-sm-2 col-form-label">{{ I18n.t('filter_credits', locale_scope) }}</label>
          <div class="form-group col-sm-10">
            <div v-for="(filter_credit, filter_credits_index) in special_billing_filter_credits" :key="filter_credits_index" v-show="!isDeleted(filter_credit)">
              <div class="d-flex justify-content-between">
                <input type="hidden" v-model="filter_credits_index" :name="`special_billing[filter_credits][${filter_credits_index}][id]`">
                <input type="hidden" v-model="filter_credit._destroy" :name="`special_billing[filter_credits][${filter_credits_index}][_destroy]`">
                <input
                  type="text"
                  class="form-control form-input"
                  v-model.trim="filter_credit.credit_id"
                  :name="`special_billing[filter_credits][credit]`"
                  @keyup="onKeyup(filter_credit.credit_id, filter_credits_index)"
                >
                <div v-if="filter_credits_index === 0" class="ml-3 mb-3">
                  <div class="flex-shrink-0">
                    <button type="button"  class="btn btn-primary " @click.prevent="newFilterCredit">
                      <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>
                  </div>
                </div>
                <div v-if="filter_credits_index > 0" class="ml-3 mb-3">
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-danger" @click.prevent="deleteCredit(filter_credits_index)">
                      <font-awesome-icon :icon="['fas', 'trash']" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 出帳信件 -->
    <div class="mb-3 card">
      <div class="card-header">{{ I18n.t('set_billing_mail', locale_scope) }}</div>
      <div class="card-body">
        <div class="mb-3 row">
          <label for="special_billing_subject" class="col-sm-2 col-form-label"> {{ I18n.t('subject', locale_scope) }}</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="special_billing_subject" v-model="special_billing_subject">
            <small class="text-muted d-block mt-2">例：[GCP]教育部因材網 2023/10/01-2023/10/31 期間 - rawdata</small>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="receiver" class="form-control-label col-sm-2">{{ I18n.t('receiver', locale_scope) }}</label>
          <div class="col-sm-10">
            <multiselect
              v-model="special_billing_receiver_list"
              placeholder="請輸入"
              :tag-placeholder="I18n.t('create_option')"
              :options="special_billing_receiver_list"
              :multiple="true"
              :taggable="true"
              @tag="changeReceiverList"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success search-manager" type="button" @click="SendEmail">發信</button>
  </div>
</template>

<script>
import axios from 'axios';

export default{
  props: {
    project_list: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      locale_scope: {
        scope: 'simple_form.special_billing'
      },
      special_billing_start_date: "",
      special_billing_end_date: "",
      specail_billing_active_project: this.project_list.project_id,
      special_billing_project_ids: [],
      special_billing_edp_discount: "",
      credit: { credit_id: '', _destroy: false },
      special_billing_filter_credits: [{}],
      special_billing_subject: '',
      special_billing_receiver_list: [],
      colletcionDate: {},
      date: null
    };
  },
  mounted() {
    this.date = this.disableDate()
  },
  methods: {
    changeStartDate(date){
      this.special_billing_start_date = date
    },
    changeEndDate(date){
      this.special_billing_end_date = date
    },
    disableDate() {
      const date = new Date()
      date.setDate(date.getDate() - 3)
      return date
    },
    addProjectList(newProject) {
      const new_project = {
        project_id: newProject
      }
      this.special_billing_project_ids.push(new_project)
    },
    onKeyup(credit, idx) {
      if(credit) {
        const trim_crdit = credit.replace(/[\u4e00-\u9fa5]/g, '')
        this.special_billing_filter_credits[idx].credit_id = trim_crdit
      }
    },
    newFilterCredit() {
      const new_credit = [{ ...this.credit }]
      this.special_billing_filter_credits = [
        ...this.special_billing_filter_credits, ...new_credit
      ]
    },
    deleteCredit(index) {
      this.special_billing_filter_credits[index]._destroy = true
    },
    isDeleted(filter_credit) {
      return filter_credit._destroy == true
    },
    changeReceiverList(email) {
      this.special_billing_receiver_list.push(email.replace(/\s*/g, ""))
    },
    SendEmail() {
      const parser_project = this.special_billing_project_ids.map(project => project.project_id)
      const parser_filter_credits = this.special_billing_filter_credits.map(credit => credit.credit_id)
      const data = {
        authenticity_token: this.authenticity_token,
        special_billing: {
          start_date: this.special_billing_start_date,
          end_date:  this.special_billing_end_date,
          project_id: parser_project,
          edp_discount: Number(this.special_billing_edp_discount),
          filter_credits: parser_filter_credits,
          subject: this.special_billing_subject,
          receiver_list: this.special_billing_receiver_list
        }
      }
      axios.post(`${window.location.pathname}/generate_bill.json`, data)
        .then(({ data }) => {
        if (data.success) {
          Toastr('success', data.message)
          setTimeout(() => {
            location.reload()
          }, 4000)
        }
        else {
          Toastr('error', data.message)
        }
      }).catch((e) => {
        Toastr('error', e)
      })
    }
  },
  watch: {
    'special_billing_edp_discount': function (edp_discount) {
      this.special_billing_edp_discount = edp_discount.replace(/[\u4e00-\u9fa5A-Za-z]/, "")
    },
  }
}
</script>

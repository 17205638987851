<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "6", "desc" ]]' >
      <thead>
        <tr>
          <th scope="col" data-name="start_date" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('start_date', locale_scope) }}
          </th>
          <th scope="col" data-name="end_date" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('end_date', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="file_name" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('file_name', locale_scope) }}
          </th>
          <th scope="col" data-name="subsidiary" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('subsidiary', locale_scope) }}
          </th>
          <th scope="col" data-name="created_by" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('created_by', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('created_at') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
  export default {
    props: {
      url: {
        type: String
      }
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.workspace/download_event'
        },
        dataTable: null,
      }
    },
    mounted(){
    this.dataTable = $(this.$el.querySelector(".datatable"))
      .DataTable({
          reponsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`
          },
      }).columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      }
    }
  }
</script>

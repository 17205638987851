<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('simple_form.labels.contract.marketplace') }}
    </div>
    <div class="card-body">
      <div class="row">
        <!-- 折扣 % 數 -->
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label>{{ I18n.t('marketplace_discount_percent', locale_scope) }}</label>
            <div class="input-group">
              <input
                type="text"
                id="contract_marketplace_discount_percent"
                class="form-control"
                name='contract[marketplace_discount_percent]'
                v-model="marketplace_discount_percent"
              >
              <div class="input-group-append">
                <span class="input-group-text">％</span>
              </div>
            </div>
            <div v-html="discount_percent_msg"></div>
            <div v-html="discount_show_warning"></div>
          </div>
        </div>
        <!-- Support % 數 -->
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label> {{ I18n.t('marketplace_support_percent', locale_scope) }}</label>
            <div class="input-group">
              <input
                type="text"
                id="contract_marketplace_support_percent"
                class="form-control"
                name='contract[marketplace_support_percent]'
                v-model="marketplace_support_percent"
                inputmode="numeric"
              >
              <div class="input-group-append">
                <span class="input-group-text">％</span>
              </div>
            </div>
            <div v-html="support_percent_msg"></div>
            <div v-html="support_show_warning"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validPercent, percentMsg, showWarning }  from '../../../javascripts/valid_percent';

export default {
  props: {
    contract: {
      type: Object
    },
    locale_scope: {
      type: Object
    }
  },
  data() {
    return {
      marketplace_discount_percent: this.contract.marketplace_discount_percent,
      marketplace_support_percent: this.contract.marketplace_support_percent,
      discount_percent_msg: '',
      discount_show_warning: '',
      support_percent_msg: '',
      support_show_warning: '',
    }
  },
  mounted() {
    this.discount_percent_msg = percentMsg(this.marketplace_discount_percent)
    this.discount_show_warning = showWarning(this.marketplace_discount_percent)
    this.support_percent_msg = percentMsg(this.marketplace_support_percent, 1)
    this.support_show_warning = showWarning(this.marketplace_support_percent)
  },
  watch: {
    'marketplace_discount_percent':function(percent){
      this.marketplace_discount_percent = validPercent(percent)
      this.discount_percent_msg = percentMsg(percent)
      this.discount_show_warning = showWarning(percent)
    },
    'marketplace_support_percent': function(percent){
      this.marketplace_support_percent = validPercent(percent)
      this.support_percent_msg = percentMsg(percent, 1)
      this.support_show_warning = showWarning(percent)
    }
  }
}
</script>
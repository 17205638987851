<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.simple_form.labels.cud_blank_project.project_level_cud_service_setting') }}
      <span class="text-danger">&nbsp;&nbsp;{{ I18n.t('activerecord.simple_form.hints.cud_blank_project.project_level_cud_service') }}</span>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table id="DataTables_Table_cud_blank_projects" class="table datatable table-striped table-hover w-100" data-order='[[ "2", "asc" ]]'>
          <thead>
            <tr>
              <th scope="col" data-name="service_desc" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
                {{ I18n.t('activerecord.attributes.cud_blank_project.service_desc') }}
              </th>
              <th scope="col" data-name="sku_desc" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
                {{ I18n.t('activerecord.attributes.cud_blank_project.sku_desc') }}
              </th>
              <th scope="col" data-name="cost" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
                {{ I18n.t('activerecord.attributes.cud_blank_project.cost') }}
              </th>
              <th scope="col" data-name="need_charge" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
                {{ I18n.t('activerecord.attributes.cud_blank_project.need_charge') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="commitment in commitments" :key="`commitment_${commitment.id}`">
              <td>{{ commitment.service_desc }}</td>
              <td>{{ commitment.sku_desc }}</td>
              <td>{{ commitment.cost }}</td>
              <td>{{ displayText(commitment.need_charge) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project_form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      project: this.project_form.project,
      commitments: this.project_form.commitments
    }
  },
  mounted() {
    this.initializeDataTable();
    this.addCustomSearch();
  },
  methods: {
    initializeDataTable() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
    },
    addCustomSearch() {
      // 自定義搜尋是因為預設的搜尋 sku_desc 沒有符合預期
      $.fn.dataTable.ext.search.push((settings, data) => {
        let tableId = settings.sTableId;
        let searchInput = $('#DataTables_Table_cud_blank_projects_filter').find('input').val().toLowerCase();

        if (tableId !== 'DataTables_Table_cud_blank_projects' || searchInput === '') {
          return true;
        }

        const serviceDesc = data[0].toLowerCase();
        const skuDesc = data[1].toLowerCase();
        const cost = data[2].toLowerCase();
        const need_charge = data[3].toLowerCase();

        return serviceDesc.includes(searchInput) || skuDesc.includes(searchInput) || cost.includes(searchInput) || need_charge.includes(searchInput);
      });
    },
    displayText(need_charge) {
      if (need_charge === null){
        return ''
      } else if(need_charge){
        return I18n.t('true')
      } else {
        return I18n.t('false')
      }
    }
  }
}
</script>
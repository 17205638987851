<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span>{{ I18n.t('activerecord.models.edp_pricing') }}</span>
        <div>
          <span v-if="!edp.id" class="text-danger">若有大量SKU，請先建立EDP後再匯入</span>
          <span v-else>若需大量編輯，請回上頁直接匯入</span>
          <button type="button" class="btn btn-primary" @click.prevent="newEdpPricing">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="form-group" v-for="(edp_pricing, edp_pricing_index) in edp_pricings" :key="edp_pricing_index">
        <input type="hidden" v-model="edp_pricing.id" :name="`edp[edp_pricings_attributes][${edp_pricing_index}][id]`">
        <input type="hidden" v-model="edp_pricing.sku" :name="`edp[edp_pricings_attributes][${edp_pricing_index}][sku]`">
        <input type="hidden" v-model="edp_pricing._destroy" :name="`edp[edp_pricings_attributes][${edp_pricing_index}][_destroy]`">
        <template v-if="!isDeleted(edp_pricing)">
          <div class="row" >
            <ValidationProvider tag="div" class="col-12 col-sm" rules="required" :name="I18n.t('sku', locale_scope)" v-slot="{ errors }" immediate>
              <label :for="`edp_edp_pricings_attributes_${edp_pricing_index}_sku`">{{ I18n.t('sku', locale_scope) }}</label>
              <template v-if="isDefaultSku(edp_pricing)">
                <input type="text" v-model="edp_pricing.sku" :disabled="true" class="bg-gray-200 form-control">
                <span class="text-muted">未設定的SKU</span>
              </template>
              <template v-else>
                <the-mask v-model="edp_pricing.sku" :id="`edp_edp_pricings_attributes_${edp_pricing_index}_sku`" :masked="true" type="text" mask="FFFF-FFFF-FFFF" class="form-control" :tokens="skuFormat" placeholder="####-####-####"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </template>
            </ValidationProvider>
            <div class=" col-12 col-sm">
              <label :for="`edp_edp_pricings_attributes_${edp_pricing_index}_name`">{{ I18n.t('name', locale_scope) }}</label>
              <input type="text" v-model="edp_pricing.name" :id="`edp_edp_pricings_attributes_${edp_pricing_index}_name`" :name="`edp[edp_pricings_attributes][${edp_pricing_index}][name]`" class="form-control">
            </div>
            <ValidationProvider tag="div" class="col-12 col-sm " rules="required" :name="I18n.t('google_discount_percent', locale_scope)" v-slot="{ errors }" immediate>
              <label :for="`edp_edp_pricings_attributes_${edp_pricing_index}_google_discount_percent`">{{ I18n.t('google_discount_percent', locale_scope) }}</label>
              <div class="input-group">
                <input type="text" class="form-control" v-model.trim="edp_pricing.google_discount_percent" :id="`edp_edp_pricings_attributes_${edp_pricing_index}_google_discount_percent`" :name="`edp[edp_pricings_attributes][${edp_pricing_index}][google_discount_percent]`">
                <div class="input-group-append">
                  <span class="input-group-text">
                    ％
                  </span>
                </div>
              </div>
              <span class="text-danger d-block">{{ errors[0] }}</span>
              <span class="text-muted d-block">{{ I18n.t('simple_form.hints.edp_pricing.google_discount_percent') }}</span>
            </ValidationProvider>
            <ValidationProvider tag="div" class="col-12 col-sm" rules="required" :name="I18n.t('discount_percent', locale_scope)" v-slot="{ errors }" immediate>
              <label :for="`edp_edp_pricings_attributes_${edp_pricing_index}_discount_percent`">{{ I18n.t('discount_percent', locale_scope) }}</label>
              <div class="input-group">
                <input type="text" class="form-control" :id="`edp_edp_pricings_attributes_${edp_pricing_index}_discount_percent`" v-model.trim="edp_pricing.discount_percent" :name="`edp[edp_pricings_attributes][${edp_pricing_index}][discount_percent]`">
                <div class="input-group-append">
                  <span class="input-group-text">
                    ％
                  </span>
                </div>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
              <span class="text-muted d-block">{{ I18n.t('simple_form.hints.edp_pricing.discount_percent') }}</span>
            </ValidationProvider>
            <div class="col-auto">
              <label class="d-block">&nbsp;</label>
              <button type="button" class="ml-2 btn btn-danger" @click.prevent="deletePricing(edp_pricing)" v-if="!isDefaultSku(edp_pricing)">
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
            </div>
          </div>
        </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'
export default {
  components: {
    TheMask
  },
  props: {
    edp: {
      type: Object,
      requred: true
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.edp_pricing'
      },
      edp_pricings: this.edp.edp_pricings,
      edp_pricing_params: {},
      skuFormat: {
        F: {
          pattern: /[0-9a-zA-Z]/,
          transform: v => v.toLocaleUpperCase()
        }
      },
    }
  },
  created(){
    this.reset_edp_pricing_params()
    // 預設有一個all
    if(lodash.isEmpty(this.edp_pricings)){
      this.newEdpPricing()
      this.edp_pricings[0] = {
        ...this.edp_pricings[0],
        'sku': 'all',
        'name': 'All/Other'
      }
    }
  },
  methods: {
    nameWithSku({name, sku}){
      return `${name} (${sku})`
    },
    newEdpPricing(){
      const params = {
        ...this.edp_pricing_params
      }

      this.edp_pricings = [
        ...this.edp_pricings,
        params
      ]
    },
    deletePricing(edp_pricing){
      edp_pricing._destroy = '-1'
    },
    isDeleted(edp_pricing){
      return edp_pricing._destroy == '-1'
    },
    isDefaultSku(edp_pricing){
      return edp_pricing.sku == 'all'
    },
    reset_edp_pricing_params(){
      this.edp_pricing_params = {
        discount_percent: 0.0,
        google_discount_percent: 0.0,
        _destroy: null,
        name: null,
        sku: null
      }
    }
  },
  watch: {
    'edp.resume_original': function(value){
      if(!value){
        this.edp_pricings.forEach((edp_pricing) => {
          edp_pricing.google_discount_percent = 0.0
          edp_pricing.discount_percent = 0.0
        })
      }
    }
  }
}
</script>

<style>

</style>
<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="addon_fee_name">{{ I18n.t('name', locale_scope) }}</label>
          <input type="text" id="addon_fee_name" class="form-control" name='addon_fee[name]' v-model="addon_fee.name">
        </div>

        <div class="form-group">
          <label for="addon_fee_category">{{ I18n.t('category', locale_scope) }}</label>
          <select v-model="addon_fee.category" name="addon_fee[category]" class="custom-select">
            <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
            <option v-for="(category, category_index) in categories" :value="category" :key="category_index">
              {{ I18n.t(`simple_form.options.addon_fee.category.${category}`)}}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="addon_fee_charge">{{ I18n.t('charge', locale_scope) }}</label>
            <div class="row no-gutters">
              <div class="col-auto">
                <select class="custom-select" id="addon_fee_charge_type" v-model="addon_fee.charge_type" name="addon_fee[charge_type]">
                  <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                  <template v-if="positive_charge_types.includes(addon_fee.category)">
                    <option value="receipt">
                      {{ I18n.t(`simple_form.options.addon_fee.charge_type.receipt`) }}
                    </option>
                  </template>
                  <template v-else>
                    <option v-for="(charge_type, charge_type_index) in charge_types" :value="charge_type" :key="charge_type_index">
                      {{ I18n.t(`simple_form.options.addon_fee.charge_type.${charge_type}`) }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-auto">
                <select class="custom-select" id="addon_fee_currency" v-model="addon_fee.currency" name="addon_fee[currency]">
                  <option disabled value>{{ I18n.t('helpers.select.prompt') }}</option>
                  <option v-for="(currency, currency_index) in currency_options" :value="currency.text_value" :key="currency_index">
                    {{ currency.display_text }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm">
                <input type="text" id="addon_fee_charge" class="form-control" name='addon_fee[charge]' inputmode="decimal" :placeholder="I18n.t('charge', locale_scope)" required v-model="chargeAbs">
              </div>
          </div>
        </div>

        <span class="text-muted">若為一次性費用，{{ I18n.t('start_at', locale_scope) }}與{{ I18n.t('end_at', locale_scope) }}設定相同值即可</span>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="addon_fee_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <month-picker :name="'addon_fee[start_at]'" :current_date="addon_fee.start_at"></month-picker>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="addon_fee_end_at">{{ I18n.t('end_at', locale_scope) }}</label>
              <month-picker :name="'addon_fee[end_at]'" :current_date="addon_fee.end_at"></month-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';

export default {
  components: {
    flatPickr
  },
  props: {
    addon_fee_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.addon_fee'
      },
      addon_fee: this.addon_fee_form.addon_fee,
      currency_options: this.addon_fee_form.currency_options,
      categories: this.addon_fee_form.categories,
      charge_types: this.addon_fee_form.charge_types,
      positive_charge_types: ['ps']
    }
  },
  computed: {
    chargeAbs: {
      get() {
        return Math.abs(this.addon_fee.charge)
      },
      set(val) {
        this.addon_fee.charge = val
      }
    }
  },
  watch: {
    'addon_fee.category': function(category) {
      if(this.positive_charge_types.includes(category)) {
        this.addon_fee.charge_type = 'receipt'
      }
    }
  }
}
</script>
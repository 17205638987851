<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="manager_group_name">{{ I18n.t('name', locale_scope) }}</label>
          <input type="text" id="manager_group_name" class="form-control" name='manager_group[name]' v-model="manager_group.name">
        </div>
        <div class="form-group">
          <label for="manager_group_parent_id">{{ I18n.t('parent', locale_scope) }}</label>
          <select v-model="manager_group.parent_id" name="manager_group[parent_id]" id="manager_group_parent_id" class="custom-select">
            <option disabled value>{{ I18n.t('helpers.select.prompt')}}</option>
            <option v-for="parent_manager_group in manager_groups" :value="parent_manager_group.id" :key="parent_manager_group.id">{{ parent_manager_group.name }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('activerecord.models.manager_permission') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3 border-bottom col-12 col-sm-6" v-for="(permissions, category, group_index) in lodash.groupBy(manager_permissions, 'permission_code.category')" :key="category">
            <h6 class="text-muted card-text">{{ category }}</h6>
            <div class="row align-items-center justify-content-start" v-for="(manager_permission, index) in permissions" :key="index">
              <div class="col-auto">
                <h5 class="card-title"> {{ permission_code_names[manager_permission.permission_code_id] }}</h5>
              </div>
              <div class="col-auto">
                <div class="w-full">
                  <div class="form-group">
                    <input type="hidden" v-model="manager_permission.id" :name="`manager_group[manager_permissions_attributes][${group_index}${index}][id]`" :id="`manager_group_manager_permissions_attributes_${group_index}${index}_id`">
                    <input type="hidden" v-model="manager_permission.permission_code_id" :name="`manager_group[manager_permissions_attributes][${group_index}${index}][permission_code_id]`" :id="`manager_group_manager_permissions_attributes_${group_index}${index}_permission_code_id`">

                    <select v-model="manager_permission.category" :name="`manager_group[manager_permissions_attributes][${group_index}${index}][category]`" :id="`manager_group_manager_permissions_attributes_${group_index}${index}_category`" class="custom-select">
                      <option disabled value>{{ I18n.t('helpers.select.prompt')}}</option>
                      <option v-for="(category, category_index) in permission_categories" :value="category" :key="category_index">{{ I18n.t(`simple_form.labels.manager_permission.category.${category}`) }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    manager_group_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      I18n,
      lodash,
      locale_scope: {
        scope: 'activerecord.attributes.manager_group'
      },
      manager_groups: this.manager_group_form.manager_groups,
      manager_group: this.manager_group_form.manager_group,
      manager_permissions: this.manager_group_form.manager_group.manager_permissions,
      permission_categories: this.manager_group_form.permission_categories,
      permission_code_names: this.manager_group_form.permission_code_names
    }
  }
}
</script>

<style>

</style>

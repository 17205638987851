<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.billing_account_project_contract') }}
    </div>
    <div class="card-body">
      <div class="form-group" :class="content">
        <template v-for="(billing_account_project_contract, billing_account_project_contract_index) in selected_resources" >
          <input type="hidden" :name='`billing_account_project_contracts[${billing_account_project_contract_index}][id]`' :key="`id${billing_account_project_contract_index}`" :value="billing_account_project_contract.id">
          <input type="hidden" :name='`billing_account_project_contracts[${billing_account_project_contract_index}][contract_id]`' :key="`contract_id${billing_account_project_contract_index}`" :value="billing_account_project_contract.contract_id">
        </template>

        <multiselect v-model="billing_account_project_contracts" :options="grouped_selectable_resources" group-label="account_name" group-values="project_options" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="fullName" track-by="id" :show-labels="false" :multiple="true" @select="addSelectedResource" @remove="removeSelectedResource" >
            </multiselect>
      </div>
      <div v-if="disable_content">
        <span class="text-danger d-block">請先完成此公司下所有合約的審核流程後，才可異動關聯</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company_billing_account_project_contracts: {
      type: Array,
      required: true
    },
    contract: {
      type: Object,
      required: true
    },
    disable_content: {
      type: Boolean,
      required: true
    }
  },
  data(){
    return{
      selectable_resource: this.company_billing_account_project_contracts.filter(({contract_id}) => contract_id == null || contract_id == this.contract.id),
      billing_account_project_contracts: this.company_billing_account_project_contracts.filter(({ contract_id }) => this.contract.id != null && contract_id == this.contract.id),
      selected_resources: [],
      grouped_selectable_resources: [],
      content: this.disable_content ? 'disable_content' : null
    }
  },
  mounted(){
    this.selected_resources = this.billing_account_project_contracts
    const tmp_group = lodash.groupBy(this.selectable_resource, 'billing_account.name')
    for( let [account_name, project_options] of Object.entries(tmp_group) ){
      this.grouped_selectable_resources = [
        ...this.grouped_selectable_resources,
        {
          account_name,
          project_options
        }
      ]
    }
  },
  methods: {
    fullName({ billing_account, project, start_at, end_at, contract }){
      const contract_uid = contract ? contract.uid : ''
      return `${billing_account.name} / ${project.project_id}`
    },
    addSelectedResource(resource){
      resource.contract_id = this.contract.id
      let exist_resource = this.selected_resources.find(({ id }) => id == resource.id)
      if(exist_resource){
        exist_resource = resource
      } else {
        this.selected_resources = [
          ...this.selected_resources,
          resource
        ]
      }
    },
    removeSelectedResource(resource){
      let exist_resource = this.selected_resources.find(({ id }) => id == resource.id)
      exist_resource.contract_id = null
    }
  }
}
</script>

<style>
  .disable_content {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
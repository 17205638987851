<template>
  <div>
    <template v-if="can_edit">
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('create_title', locale_scope) }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 d-flex align-items-center">
              <span >需濾掉 Credit ID：</span>
              <input type="text" class="form-control w-75" v-model.trim="credit_id"/>
            </div>
            <div class="col-4 d-flex align-items-center">
              <span>結束時間：</span>
              <flat-pickr
                v-model="end_at"
                :config="date_config"
                class="form-control w-75"
                :placeholder="I18n.t('helpers.select.prompt')"
                name="end_at_date_picker">
              </flat-pickr>
            </div>
            <div class="col-1 d-flex align-items-center">
              <button @click="createCredit" class="btn btn-success" type="button">
              {{ I18n.t('create', locale_scope) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="table-responsive">
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('table_title', {scope: 'activerecord.attributes'}) }}
        </div>
        <div class="card-body">
          <div class="row mb-4 justify-content-start">
            <div class="col-2 text-start">
              {{ I18n.t('table_header', {scope: 'activerecord.attributes'}) }}
            </div>
            <template v-for="(btn_clicked, btn_label) in filterBtns">
              <div class="col-2">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" v-model="filterBtns[btn_label]">
                  <label class="custom-control-label" :id="btn_label" @click="changeBtn">{{ I18n.t(`simple_form.labels.credit_recycle.status.${btn_label}`) }}</label>
                </div>
              </div>
            </template>
            <div class="col-2">
              <button @click="reloadTable" class="btn btn-success" type="button">
              {{ I18n.t('table_search', {scope: 'activerecord.attributes'}) }}
              </button>
            </div>
          </div>
          <hr class="hr border border-5" />
          <table class="table datatable table-striped table-hover w-100" data-searchable="true" data-order='[[ "3", "desc" ]]'>
            <thead>
              <tr>
                <th scope="col" data-name="credit_id" data-sortable="true" data-class-name="text-center align-middle">
                  {{ I18n.t('name', table_scope) }}
                </th>
                <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle">
                  {{ I18n.t('status', table_scope) }}
                </th>
                <th scope="col" data-name="end_at" data-sortable="true" data-class-name="text-center align-middle">
                  {{ I18n.t('end_at', table_scope) }}
                </th>
                <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle">
                  {{ I18n.t('created_at', table_scope) }}
                </th>
                <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle">
                  {{ I18n.t('action') }}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';

export default {
  props: {
    can_edit: {
      type: Boolean,
      requred: true
    },
  },
  components: {
    flatPickr
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'credit_recycle'
      },
      table_scope: {
        scope: 'activerecord.attributes.credit_recycle'
      },
      date_config: {
        locale: MandarinTraditional,
        altFormat: 'Y-m-d',
      },
      credit_id: null,
      end_at: null,
      dataTable: null,
      filterBtns: {
        "pending": 1,
        "done": 1,
        "expired": 0,
        "deleted": 0
      },
    }
  },
  mounted() {
    this.dataTable = $(this.$el.querySelector(".datatable"))
      .DataTable({
        responsive: true,
        destroy: true,
        ajax: {
          url: `${window.location.pathname}/search?pending=1&done=1&expired=0&deleted=0`,
          dataSrc: '',
        },
        language: {
          url: `/datatable.${I18n.currentLocale()}.lang.json`,
        },
      }).columns.adjust();
  },
  methods: {
    changeBtn(e){
      this.filterBtns[e.srcElement.id] = this.filterBtns[e.srcElement.id] == 1 ? 0 : 1
    },
    reloadTable(){
      let queryStr = Object.entries(this.filterBtns).map(([btn_label, btn_clicked]) => {
        if (btn_clicked == 1) {
          return `${btn_label}=1`
        } else {
          return `${btn_label}=0`
        }
      }).join("&")
      this.dataTable = $(this.$el.querySelector(".datatable"))
      .DataTable({
        responsive: true,
        destroy: true,
        ajax: {
          url: `${window.location.pathname}/search?${queryStr}`,
          dataSrc: '',
        },
        language: {
          url: `/datatable.${I18n.currentLocale()}.lang.json`,
        },
      }).columns.adjust();
    },
    createCredit(){
      const data = {
        authenticity_token: this.authenticity_token,
        credit_id: this.credit_id,
        end_at: this.end_at,
      }

      axios.post(`${window.location.pathname}`, data)
        .then(({data}) => {
          Toastr(data["type"], `${I18n.t(`create_${data["type"]}`, this.locale_scope)}<br />${data["message"]}`);
            if (data["type"] == "success") {
              this.credit_id = null;
              this.end_at = null;
              this.filterBtns = {
                "pending": 1,
                "done": 1,
                "expired": 0,
                "deleted": 0
              };
              this.reloadTable();
            }
        }).catch((e) => {
          Toastr('error', I18n.t('create_error', this.locale_scope));
        })
    },
  },
}
</script>

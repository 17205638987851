<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="edp_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <date-picker :name="'edp[start_at]'" :current_date="edp.start_at"></date-picker>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="edp_end_at">{{ I18n.t('end_at', locale_scope) }}</label>
              <date-picker :name="'edp[end_at]'" :current_date="edp.end_at"></date-picker>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="edp_status">{{ I18n.t('status', locale_scope) }}</label>
              <select v-model="edp.status" name="edp[status]" class="custom-select">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(status, status_index) in statuses" :value="status" :key="status_index">
                  {{ I18n.t(`simple_form.options.edp.status.${status}`)}}
                </option>
              </select>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <edp-pricing-form :edp="edp"></edp-pricing-form>
  </div>
</template>

<script>
export default {
  props: {
    edp_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.edp'
      },
      edp: this.edp_form.edp,
      statuses: this.edp_form.statuses
    }
  },
}
</script>
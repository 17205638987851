<template>
  <div class="mb-3 card">
    <div class="card-header">
      <input name="contract[support_enabled]" type="hidden" value="0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="contract_support_enabled" name="contract[support_enabled]" v-model="contract.support_enabled">
        <label class="custom-control-label"  for="contract_support_enabled">{{ I18n.t('activerecord.models.contract_support') }}</label>
      </div>
    </div>

    <div class="card-body" v-show="contract.support_enabled">
      <input type="hidden" name="contract[common_support_attributes][category]" value="common">
      <input type="hidden" name="contract[common_support_attributes][id]" v-model="contract_support.id">
      <input type="hidden" name="contract[common_support_attributes][_destroy]" v-model="contract_support._destroy">

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="common_support_sub_category">{{ I18n.t('category', locale_scope) }}</label>
            <select v-model="contract_support.sub_category_of_common" id="common_support_sub_category" name="contract[common_support_attributes][sub_category_of_common]" class="custom-select">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(sub_category, sub_category_index) in sub_categories_of_common" :value="sub_category" :key="sub_category_index">
                {{ I18n.t(`simple_form.labels.contract_support.sub_category_of_common.${sub_category}`)}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="common_support_charge_percent">{{ I18n.t('charge_percent', locale_scope) }}</label>
            <div class="input-group">
              <input type="text" id="common_support_charge_percent" class="form-control" name='contract[common_support_attributes][charge_percent]' v-model="contract_support.charge_percent">
              <div class="input-group-append">
                <span class="input-group-text">％</span>
              </div>
            </div>
            <div v-html="percent_msg"></div>
            <div v-html="show_warning"></div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="common_support_charge_minimum">{{ I18n.t('charge_minimum', locale_scope) }}</label>
            <input type="text" id="common_support_charge_minimum" class="form-control" name='contract[common_support_attributes][charge_minimum]' v-model="contract_support.charge_minimum">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="common_support_currency">{{ I18n.t('currency', locale_scope) }}</label>
            <select v-model="contract_support.currency" id="common_support_currency" name="contract[common_support_attributes][currency]" class="custom-select">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(currency, currency_index) in currencies" :value="currency.text_value" :key="currency_index">
                {{ currency.display_text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <span class="text-muted">*{{ I18n.t('activerecord.models.contract_support') }}費用為：「使用費用x{{ I18n.t('charge_percent', locale_scope) }}」或「{{ I18n.t('charge_minimum', locale_scope) }}」最高值者</span>
    </div>
  </div>
</template>
<script>
import { validPercent, showWarning, percentMsg }  from '../../../javascripts/valid_percent';

export default {
  props: {
    contract: {
      type: Object
    },
    currencies: {
      type: Array
    },
    blank_contract_support: {
      type: Object
    },
    sub_categories_of_common: {
      type: Array
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.contract_support'
      },
      contract_support: this.contract.common_support || this.blank_contract_support,
      support_enabled: this.contract.support_enabled,
      show_warning: '',
      percent_msg: ''
    }
  },
  created(){
    if(!this.contract.support_enabled){
      this.contract_support._destroy = '-1'
    }
  },
  mounted() {
    this.show_warning = showWarning(this.contract_support.charge_percent)
    this.percent_msg = percentMsg(this.contract_support.charge_percent, 1)
  },
  watch: {
    'contract.support_enabled': function(status){
      if(status){
        this.contract_support._destroy = null
        this.contract_support.sub_category_of_common = 'TS'
      } else {
        this.contract_support._destroy = '-1'
      }
    },
    'contract.currency': function(currency){
      this.contract_support.currency = currency
    },
    'contract_support.charge_percent': function(percent){
      this.contract_support.charge_percent = validPercent(percent)
      this.show_warning = showWarning(percent)
      this.percent_msg = percentMsg(percent, 1)
    }
  }
}
</script>

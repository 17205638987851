<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('search_manager', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="flex-wrap d-flex justify-content-between">
          <div class="col-md-11 col-sm-12">
            <multiselect
              v-model="manager"
              :options="managers"
              :placeholder="I18n.t('helpers.select.prompt')"
              label="name"
              track-by="id"
              :show-labels="false"
              :custom-label="nameWithEmail"
            >
              <template slot="option" slot-scope="props">
                {{ props.option.name }} ({{ props.option.email }})
                <span v-html="props.option.enable_tag" class="float-right"></span>
              </template>
            </multiselect>
          </div>
          <button
            @click="assignCompanies"
            class="btn btn-success"
            type="button"
            :disabled="isButtonDisabled"
          >
            {{ I18n.t('search', locale_scope) }}
          </button>
        </div>
      </div>
    </div>
    <template v-if="isDataTableShow">
      <ManagerInchargeCompany
        :manager_incharge_companies="manager_incharge_companies"
        :enable_managers="enable_managers"
        :manager="manager"
        @reload="reloadTable"
        :key="reloadComponent"
        :disableSelect="disableSelect"
      >
      </ManagerInchargeCompany>
    </template>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    props: {
      transfer_manager_form: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'transfer_manager'
        },
        managers: this.transfer_manager_form.managers,
        manager: null,
        enable_tag: this.transfer_manager_form.enable_tag,
        isButtonDisabled: true,

        // ManagerInchargeCompany component
        enable_managers: this.transfer_manager_form.enable_managers,
        enable_manager: null,
        manager_incharge_companies: [],
        isDataTableShow: false,
        reloadComponent: 0,
        disableSelect: this.transfer_manager_form.disable_select
      }
    },
    methods: {
      assignCompanies(){
        const data = {
          authenticity_token: this.authenticity_token,
          manager_id: this.manager.id,
        }

        axios.get(`${window.location.pathname}/search.json`, {params: data})
             .then(({data}) => {
               this.manager_incharge_companies = data.companies;
               this.isDataTableShow = true;
               this.reloadComponent += 1;
             }).catch((e) => {
               Toastr('error', I18n.t('system_error', this.locale_scope))
             })
      },
      nameWithEmail({ name, email }){
        return `${name} (${email})`
      },
      reloadTable(){
        this.assignCompanies();
      },
    },
    watch: {
      'manager': function() {
        if (this.manager !== null) {
          this.isButtonDisabled = false
        };
      }
    }
  }
</script>

<template>
  <div>
    <table class="table table-striped table-hover w-100 text-center">
      <thead>
        <tr>
          <th scope="col">{{ I18n.t('subject', locale_scope) }}</th>
          <th scope="col">{{ I18n.t('description', locale_scope) }}</th>
          <th scope="col">{{ I18n.t('action') }}</th>
          <th scope="col">{{ I18n.t('response', locale_scope) }}</th>
          <th scope="col">{{ I18n.t('operator', locale_scope) }}</th>
          <th scope="col">{{ I18n.t('latest_updated_at', locale_scope) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="api_function in api_functions" :key="api_function.id">
          <td class="align-middle">{{ I18n.t(api_function.subject, locale_scope) }}</td>
          <td scope="col" class="align-middle">{{ api_function.description }}</td>
          <td scope="col" class="align-middle"><button class="btn btn-primary" @click.prevent="sendApiRequest(api_function.id)"> {{ I18n.t('execute', locale_scope) }}</button></td>
          <td scope="col" class="align-middle">{{ I18n.t(`latest_response_status.${api_function.latest_response_status}`, locale_scope) }}</td>
          <td scope="col" class="align-middle">{{ api_function.latest_used_manager }}</td>
          <td scope="col" class="align-middle">{{ api_function.latest_updated_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      api_functions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.api_function'
        },
        url: window.location.pathname
      }
    },
    methods: {
      sendApiRequest(id) {
        const data = {
          authenticity_token: this.authenticity_token
        }

        // 轉圈圈動畫
        this.showAlert()

        axios.put(`${this.url}/${id}/call`, data
        ).then((response) => {

          setTimeout(() => {
            // 關閉動畫，顯示 response 訊息
            Swal.close()

            const toast_type = response.data.type
            const toast_message = response.data.message

            Toastr(toast_type, toast_message)

            if(toast_type === 'success') {
              setTimeout(() => {
                window.location.href = this.url
              }, 2000)
            }

          }, 2000)

        }).catch((error) => {
          Swal.close()
          Toastr('error', error)
        })
      },
      showAlert() {
        Swal.fire({
          title: '資料更新中，請稍候',
          html: '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        })
      }
    }
  }
</script>
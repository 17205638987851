<template>
  <div>
    <div class="mb-2 d-flex justify-content-end">
      <a :href="`${url}/download?isolated=${isolated}`" class="btn btn-primary" data-method="post">{{ I18n.t('download_all') }}</a>
      <form :action="`${url}/download`" method="post" class="simple_form" accept-charset="UTF-8" v-if="selected_ids.length > 0">
        <input type="hidden" name="authenticity_token" v-model="authenticity_token">
        <input type="hidden" name="object_ids[]" v-for="(selected_id, index) in selected_ids" :value="selected_id" :key="index">
        <input type="hidden" name="isolated" :value="isolated">
        <button type="submit" class="ml-2 btn btn-primary"> {{ I18n.t('download') }} </button>
      </form>
    </div>
    <div class="table-responsive">
      <table class="table datatable table-striped table-hover w-100" :ref="`table-isolated-${isolated}`" :class="`isolated-${isolated}`" data-order='[[ "1", "asc" ]]'>
        <thead>
          <tr>
            <th scope="col" data-name="id" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" :id="`select_all-${isolated}`" class="custom-control-input">
                <label :for="`select_all-${isolated}`" class="custom-control-label">{{ I18n.t('select_all') }}</label>
              </div>
            </th>
            <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('name', locale_scope) }}
            </th>
            <th scope="col" data-name="size" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('size', locale_scope) }}
            </th>
            <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('created_at') }}
            </th>
            <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center' v-if="!isolated">
                {{ I18n.t('action') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    authenticity_token: {
      type: String
    },
    isolated: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.billing_file_folder'
      },
      dataTable: null,
      select_all: false,
      selected_ids: [],
      table_element: null,
      select_all_element: `#select_all-${this.isolated}`
    }
  },
  mounted() {
    this.table_element = this.$refs[`table-isolated-${this.isolated}`]
      this.dataTable = $(this.table_element)
        .DataTable({
          responsive: true,
          ajax: {
            url: `${window.location.pathname}.json?isolated=${this.isolated}`
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).on("draw.dt", ({currentTarget}) => {
          const $table = $(currentTarget)
          this.bindingCheckbox($table);
        }).columns.adjust();
        // 5分鐘重整更新media_link
      setInterval(() => {
        this.reloadTable()
      }, 5*60*1000);
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
      this.selected_ids = []
    },
    bindingCheckbox($table){
      const vm = this
      // 全選
      $table.off("change", vm.select_all_element)
      $table.on("change", vm.select_all_element, function(){
        if (this.checked) {
          $(vm.table_element).find('tbody :checkbox:not(:checked)').trigger("click");
        } else {
          $(vm.table_element).find('tbody :checkbox:checked').trigger("click");
        }
      })

      // 個別選
      $table.off("change", "tbody :checkbox")
      $table.on("change", "tbody :checkbox", ({target}) => {
        this.setId(target);
      });
    },
    setId(selected_input){
      const vm = this
      const $select_all = $(vm.select_all_element)
      const changed_id = $(selected_input).val()
      if(selected_input.checked){
        this.selected_ids.push(changed_id)
      } else {
        const index = this.selected_ids.indexOf(changed_id);
        if (index > -1) {
          this.selected_ids.splice(index, 1);
        }
      }
      $select_all.prop("indeterminate", false);
      if (this.selected_ids.length === 0) {
        $select_all.prop("checked", false);
      } else if (this.selected_ids.length === $(vm.table_element).find('tbody :checkbox').length) {
        $select_all.prop("checked", true);
      } else {
        $select_all.prop("checked", false);
        $select_all.prop("indeterminate", true);
      }

    }
  },
};
</script>

<style lang="scss" >

</style>
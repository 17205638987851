<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "5", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="name" data-sortable="false" data-class-name="text-center align-middle">
            {{ I18n.t('name', locale_scope) }}
          </th>
          <th scope="col" data-name="netsuite_id" data-sortable="false" data-class-name="text-center align-middle">
            {{ I18n.t('netsuite_id', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle">
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="billing_accounts_count" data-sortable="true" data-class-name="text-center align-middle">
            {{ I18n.t('billing_accounts_count', locale_scope) }}
          </th>
          <th scope="col" data-name="active_contracts_count" data-sortable="true" data-class-name="text-center align-middle">
            {{ I18n.t('active_contracts_count', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle">
            {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle">
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.company'
      },
      dataTable: null,
    }
  },
  mounted() {
    this.dataTable = $(this.$el.querySelector(".datatable"))
      .DataTable({
        responsive: true,
        ajax: {
          url: `${window.location.pathname}.json`
        },
        language: {
          url: `/datatable.${I18n.currentLocale()}.lang.json`,
        },
      }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>

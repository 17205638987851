<template >
  <div :key="key" @click.prevent="performAction">
    <button class="btn btn-secondary" v-if="processing">
      <font-awesome-icon :icon="['fas', 'spinner']" spin />
    </button>
    <slot v-else></slot>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    addon_data: {
      type: Object
    }
  },
  data(){
    return {
      I18n,
      data: {
        authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      processing: false,
      key: 0
    }
  },
  methods: {
    performAction(){
      // this.processing = true
      const data = this.data
      axios.post(`${this.url}.json`, data).then((response) => {
        const toast_type = response.data.type
        const toast_message = response.data.message
        const toast_title = response.data.title
        this.processing = false
        Toastr(toast_type, toast_message, toast_title)
        this.key += 1
      }).catch((error) => {
        this.processing = false
        Toastr('error', I18n.t('error'))
      })
    }
  }
}
</script>

<style>

</style>
<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.workspace/support_plan_record') }}
    </div>
    <div class="card-body">
      <div class="form-group">
        <!-- 方案版本 -->
        <label for="support_plan">{{ I18n.t('version', locale_scope) }}</label>
        <input type="hidden" name="workspace_support_plan_record[support_plan_id]" v-model="select_support_plan_id">
        <multiselect v-model="support_plan" :options="active_support_plan_options" :placeholder="I18n.t('helpers.select.prompt')" label="support_plan_name" track-by="id" :show-labels="false">
        </multiselect>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <!-- 合約開始日 -->
            <label for="workspace_support_plan_record_start_date">{{ I18n.t('start_date', locale_scope) }}</label>
            <date-picker :name="'workspace_support_plan_record[start_date]'" :current_date="support_plan_record.start_date"></date-picker>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <!-- 合約結束日 -->
            <label for="workspace_support_plan_record_end_date">{{ I18n.t('end_date', locale_scope) }}</label>
            <date-picker :name="'workspace_support_plan_record[end_date]'" :current_date="support_plan_record.end_date"></date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      support_plan_info: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.workspace/support_plan_record'
        },
        active_support_plan_options: this.support_plan_info.support_plan_options.filter(support_plan => support_plan.status === 'active'),
        support_plan_record: this.support_plan_info.support_plan_record,
        support_plan: this.support_plan_info.support_plan_options.find(support_plan => support_plan.id === this.support_plan_info.support_plan_record.support_plan_id),
        select_support_plan_id: this.support_plan_info.support_plan_record.support_plan_id
      }
    },
    watch: {
      'support_plan': function(select_support_plan){
        if(select_support_plan === null) {
          this.select_support_plan_id = null
          return
        }

        this.select_support_plan_id = select_support_plan.id
      }
    }
  }
</script>

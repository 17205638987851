<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "6", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('name', locale_scope)  }}
          </th>
          <th scope="col" data-name="category" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('category', locale_scope)  }}
          </th>
          <th scope="col" data-name="currency" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('currency', locale_scope) }}
          </th>
          <th scope="col" data-name="charge" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('charge', locale_scope)  }}
          </th>
          <th scope="col" data-name="end_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('duration', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center' v-if="editable">
              {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.addon_fee'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url,
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>

<style lang="scss" >

</style>
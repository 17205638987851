<template>
  <div class="table-responsive">
  <table class="table datatable table-striped table-hover w-100" data-order='[[ "4", "desc" ]]' data-searchable="false" :data-ajax="url">
    <thead>
      <tr>
        <th scope="col" data-name="item_type" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('item_type', locale_scope) }}
        </th>
        <th scope="col" data-name="event" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('event', locale_scope) }}
        </th>
        <th scope="col" data-name="project_id" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('object_changes', locale_scope) }}
        </th>
        <th scope="col" data-name="whodunnit" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('whodunnit', locale_scope) }}
        </th>
        <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('created_at', locale_scope) }}
        </th>
      </tr>
    </thead>
  </table>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    versions: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.version'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>

<style lang="scss" >

</style>
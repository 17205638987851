export function validPercent(percent) {
  let value = percent
  if(value) {
    // 只允許數字及小數點
    value = value.replace(/^0+(?=\d)|[^0-9.]/g, "");

    // 處理大於 100 的情況
    if (parseFloat(value) > 100) {
      value = '100';
    }
    // 小數點後最多保留兩位小數
    value = value.replace(/(\.\d{2})\d+/, '$1');

    // 處理多餘的小數點，只保留第一個小數點
    value = value.replace(/\.{2,}/, '.').replace(/(\.\d*)\.*/, '$1');

    return value
  }
}

export function showWarning(percent) {
  if (percent && percent !== `0.0` && percent.match(/\./)) {
    return '<span class="text-danger d-block">若無小數位，請移除小數點</span>'
  }
  return ''
}

export function percentMsg(percent, plus = null) {
  const operator = plus ? "+" : "-"

  if (percent) {
    let result

    if (operator === "+") {
      result = 100 + Number(percent);
    }else{
      result = 100 - Number(percent);
    }

    return `<small class="text-muted">(100 ${operator} ${(percent)}) = ${result} %</small>`
  }else {
    return `<small class="text-muted">(100 ${operator} 0) = 100 %</small>`
  }
}

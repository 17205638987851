<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order="[]">
      <thead>
        <tr>
          <th scope="col" data-name="id" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            id
          </th>
          <th scope="col" data-name="text_value" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            text_value
          </th>
          <th scope="col" data-name="decimal_value" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            decimal_value
          </th>
          <th scope="col" data-name="boolean_value" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            boolean_value
          </th>
          <th scope="col" data-name="display_text" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            display_text
          </th>
          <th scope="col" data-name="note" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            note
          </th>
          <th scope="col" data-name="position" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            position
          </th>
          <th scope="col" data-name="status" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            status
          </th>
          <th scope="col" data-name="action" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            Action
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
  export default {
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        I18n,
        dataTable: null
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    }
  }
</script>
<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.billing_account') }}
    </div>
    <div class="card-body pb-0">
      <div class="form-group">
        <template v-if="selected_accounts.length > 0">
          <input type="hidden" name='company[billing_account_ids][]' v-for="(selected_account, index) in selected_accounts" :key="index" :value="selected_account.id">
        </template>
        <template v-else>
          <input type="hidden" name='company[billing_account_ids][]'>
        </template>
        <multiselect v-model="selected_accounts" :options="selectable_accounts" :custom-label="nameWithAccountId" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" :show-labels="false" :multiple="true">
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    billing_accounts: {
      type: Array,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data(){
    return{
      I18n,
      selected_accounts: this.company.billing_accounts,
      selectable_accounts: this.billing_accounts.concat(this.company.billing_accounts)
    }
  },
  methods: {
    nameWithAccountId ({ name, account_id }) {
      return `${name} [${account_id}]`
    }
  }
}
</script>

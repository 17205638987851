<template>
  <div class="mb-3 card">
    <div class="card-body">
      <b-button v-b-toggle.collapse-general aria-expanded="false" variant="primary" @click="switchCommon">{{ I18n.t('common', locale_scope) }}</b-button>
      <b-button v-b-toggle.collapse-flexible variant="primary" @click="switchFlexible">{{ I18n.t('flexible_case', locale_scope) }}</b-button>
    </div>
    <div class="card-body">
      <!-- 彈性月結 -->
      <b-collapse id="collapse-flexible" v-if="download_event.is_flexible">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="download_event_pick_month">{{ I18n.t('pick_month', locale_scope) }}</label>
              <input type="hidden" name='download_event[start_date]' v-model="download_event.start_date" />
              <input type="hidden" name='download_event[end_date]' v-model="download_event.end_date" />
              <input type="hidden" name='download_event[is_flexible]' v-model="download_event.is_flexible" />
              <month-picker :name="'[download_event]pick_month'" @changeDate="changeFlexibleDate"></month-picker>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="download_event_subsidiary">{{ I18n.t('subsidiary', locale_scope) }}</label>
              <input type="hidden" name='download_event[subsidiary]' v-model="selected_subsidiary.name" />
              <multiselect v-model="selected_subsidiary" :options="subsidiaries" label="name" track-by="id">
              </multiselect>
            </div>
          </div>
        </div>
      </b-collapse>
      <!-- 一般 -->
      <b-collapse id="collapse-general" v-else-if="is_general">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="download_event_start_date">{{ I18n.t('start_date', locale_scope) }}</label>
              <date-picker :name="'download_event[start_date]'" :current_date="download_event.start_date" @changeDate="changeStartDate"></date-picker>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="download_event_end_date">{{ I18n.t('end_date', locale_scope) }}</label>
              <date-picker :name="'download_event[end_date]'" :current_date="download_event.end_date" @changeDate="changeEndDate"></date-picker>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="download_event_subsidiary">{{ I18n.t('subsidiary', locale_scope) }}</label>
              <input type="hidden" name='download_event[subsidiary]' v-model="selected_subsidiary.name" />
              <multiselect v-model="selected_subsidiary" :options="subsidiaries" label="name" track-by="id">
              </multiselect>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      download_event_form: {
        type: Object,
        require: true
      }
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.workspace/download_event'
        },
        download_event: this.download_event_form.download_event,
        subsidiaries: this.download_event_form.subsidiaries,
        selected_subsidiary: {},
        is_general: false
      }
    },
    methods: {
      changeStartDate(date){
        this.download_event.start_date = date
      },
      changeEndDate(date){
        this.download_event.end_date = date
      },
      changeFlexibleDate(date){
        if(date){
          this.download_event.start_date = dayjs(date).format('YYYY-MM-DD')
          this.download_event.end_date = dayjs(date).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
        }
      },
      switchCommon(){
        this.download_event.is_flexible = false
        this.download_event.start_date = null
        this.download_event.end_date = null
        this.is_general = true
      },
      switchFlexible(){
        this.is_general = false
        this.download_event.start_date = null
        this.download_event.end_date = null
        this.download_event.is_flexible = true
      }
    }
  }
</script>
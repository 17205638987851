<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.company') }}
    </div>
    <div class="card-body">
      <div class="form-group">
        <ValidationProvider rules="required" :name="I18n.t('activerecord.models.company')" v-slot="{ errors }" immediate>
        <input type="hidden" name='customer[company_id]' v-model="select_company_id" />
        <multiselect v-model="customer.company" :options="companies" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id">
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer_form: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      I18n,
      customer: this.customer_form.customer,
      companies: this.customer_form.companies,
      select_company_id: this.customer_form.customer.company_id
    }
  },
  watch: {
    'customer.company': function(company){
      this.select_company_id = company ? company.id : null
    }
  },
}
</script>
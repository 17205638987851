<template>
  <div>
    <!-- 20240320 評估目前 bu 已未使用，先將此功能關閉 -->
    <!-- <button class="btn btn-secondary btn-import" v-b-modal.modal-import-by-file>匯入</button> -->
    <!-- <b-modal id="modal-import-by-file" title="匯入" centered ok-title="上傳檔案" ok-only no-stacking @ok="upload">
      <p class="my-2">請選擇以下其一種方式 &#58;</p>
      <b-form-file
        accept=".csv, .xlsx"
        v-model="file"
        class="mt-3 mb-3"
        plain
        @change="fileChange"
      ></b-form-file>
      <b-button v-b-modal.modal-import-by-console>手動輸入客戶資料&#40;匯入來源自 Google Console&#41;</b-button>
    </b-modal> -->

    <b-button v-b-modal.modal-import-by-console>匯入</b-button>
    <b-modal id="modal-import-by-console" title="輸入客戶資料" centered ok-title="送出" ok-only @ok="sync">
      <div class="form-group">
        <label for="customer_console_domain">{{ I18n.t('console_domain', locale_scope) }}</label>
        <ValidationProvider rules="required" :name="I18n.t('console_domain', locale_scope)" v-slot="{ errors }" immediate>
        <select id="customer_console_domain" name="console_domain" v-model="selected_console_domain" class="custom-select">
          <option disabled value>{{ I18n.t('helpers.select.prompt') }}</option>
          <option v-for="(console_domain, console_domain_index) in console_domains" :key="console_domain_index" :value="console_domain">
            {{ I18n.t(`simple_form.labels.customer.console_domain.${console_domain}`) }}
          </option>
        </select>
        <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <label for="customer_domain">{{ I18n.t('domain', locale_scope) }}</label>
        <ValidationProvider rules="required" :name="I18n.t('domain', locale_scope)" v-slot="{ errors }" immediate>
        <input type="text" class="form-control" name="domain" v-model.trim="domain" />
        <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <label for="customer_customer_id">{{ I18n.t('customer_id', locale_scope) }}</label>
        <ValidationProvider rules="required" :name="I18n.t('customer_id', locale_scope)" v-slot="{ errors }" immediate>
        <input type="text" class="form-control" name="customer_id" v-model.trim="customer_id" />
        <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  props: {
    import_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      file: null,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/customer'
      },
      url: window.location.pathname,
      console_domains: this.import_form.console_domains,
      selected_console_domain: '',
      domain: null,
      customer_id: null
    }
  },
  methods: {
    fileChange(e){
      // 放入檔案
      this.file = e.target.files[0]
    },
    upload(bvModalEvt) {
      bvModalEvt.preventDefault()
      const formData = new FormData()
      const file = this.file;

      formData.append('authenticity_token', this.authenticity_token)
      formData.append('workspace_customer[import]', file)

      if(file){
        axios.put(`${this.url}/import`, formData
        ).then((response) => {
          const toast_type = response.data.type
          const toast_message = response.data.message
          Toastr(toast_type, toast_message)

        }).catch((error) => {
          Toastr('error', error)
        })

        this.$nextTick(() => {
          this.$bvModal.hide('modal-import-by-file')
          this.file = null
        })
      } else {
        Toastr('error', '請選擇檔案')
      }
    },
    sync(bvModalEvt) {
      bvModalEvt.preventDefault()
      const vaild_formdata = this.selected_console_domain.length > 1 && this.domain && this.customer_id

      if(!vaild_formdata) {
        Toastr('error', '請檢查必填欄位')
        return
      }

      const formData = new FormData()

      formData.set('authenticity_token', this.authenticity_token)
      formData.set('console_domain', this.selected_console_domain)
      formData.set('domain', this.domain)
      formData.set('customer_id', this.customer_id)

      axios.put(`${this.url}/manual_sync`, formData
      ).then((response) => {
        Toastr(response.data.type, response.data.message)
      })

      this.$nextTick(() => {
        this.$bvModal.hide('modal-import-by-console')
        this.selected_console_domain = ''
        this.domain = null
        this.customer_id = null
      })
    }
  }
}
</script>
<style lang="scss">
  .btn-import {
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
  }
</style>
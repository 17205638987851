<template>
  <div class="mb-3 card">
    <div class="card-body">
      <div ref="message" class="mb-4 text-danger d-none">
        合約內容不相同，請先修改合約
      </div>
      <div class="row">
        <div class="col-6" v-for="(grouped_contracts, contract_group_id, contract_group_index) in contracts" :key="contract_group_index">
          <div class="mb-3 card">
            <div class="card-header">
              {{ `Group ${contract_group_index + 1}` }}
            </div>
            <draggable v-bind="dragOptions" class="py-0 card-body" :list="grouped_contracts" :move="checkContent" group="contract" handle=".handle" @add="changeGroup(contract_group_id)" @end="leaveGroup(contract_group_id)">
              <transition-group class="py-3 d-block">
              <div class="px-4 py-2 mb-2 text-white cursor-grab bg-primary d-flex align-items-center justify-content-between" v-for="(contract, contract_index) in grouped_contracts" :key="`${contract_group_id}${contract_index}`">
                <i class="fa fa-align-justify handle"></i>
                <span class="mr-2 text-break">
                  <input type="hidden" :name="`company[contracts_attributes][${contract_group_id}${contract_index}][id]`" :value="contract.id">
                  <input type="hidden" :name="`company[contracts_attributes][${contract_group_id}${contract_index}][contract_group_id]`" :value="contract_group_id">
                  <a class="text-light" :href="`contracts/${contract.id}`">{{ customLabel(contract) }}</a>
                </span>
                <span>
                  <span class="pl-2" role='button'  v-if="grouped_contracts.length > 1" @click.prevent="newGroup(contract_group_id, contract)">
                    <i class="fas fa-plus fa-fw"></i>
                  </span>
                </span>
              </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    contract_group_form: {
      type: Object
    },
  },
  data() {
    return {
      I18n,
      contracts: this.contract_group_form.contracts
    };
  },
  computed: {
    dragOptions() {
      return {
        ghostClass: 'bg-info',
      }
    }
  },
  methods: {
    checkContent: function(event) {
      // 比較拖拉的 contract 與目標群組的 contract 是否相同
      const allow_properties = ['tax_percent', 'discount_percent', 'support_enabled', 'delegate_enabled','rate_date', 'rate_category', 'marketplace_discount_percent', 'marketplace_support_percent', 'cud_profit_percent']
      const moving_element = event.draggedContext.element
      const compare_element = event.relatedContext.list[0]
      const copied_element = lodash.pick(moving_element, allow_properties)
      const copied_compare_element = lodash.pick(compare_element, allow_properties)
      const result = lodash.isEqual(copied_element, copied_compare_element)

      if (!result) {
        $(this.$refs['message']).removeClass('d-none')
        return false
      } else {
        $(this.$refs['message']).addClass('d-none')
      }
    },
    changeGroup(contract_group_id){
      this.contracts[contract_group_id] = lodash.map(this.contracts[contract_group_id], (contract) => {
        contract.contract_group_id = contract_group_id
        return contract
      })
    },
    leaveGroup(contract_group_id){
      if (lodash.isEmpty(this.contracts[contract_group_id])) {
        this.$delete(this.contracts, contract_group_id)
      }
    },
    newGroup(old_contract_group_id, contract){
      let contracts = lodash.clone(this.contracts)
      let current_group = this.contracts[old_contract_group_id]

      if (current_group.length == 1) {
        return false
      }

      contracts[old_contract_group_id] = lodash.pull(contracts[old_contract_group_id], contract)

      const new_contract_group_id = `000${Date.now()}`
      contract.contract_group_id = new_contract_group_id
      contracts[new_contract_group_id] = [contract]

      this.contracts = contracts
    },
    customLabel(contract){
      // 轉為浮點數，可以去除小數點後多餘的 0
      let percent = parseFloat(contract.cud_profit_percent);
      let cud_profit_percent = this.formatProfitPercent(percent)

      return `${contract.uid} (CUD Profit ${cud_profit_percent})`
    },
    formatProfitPercent(profitPercent) {
      let percentString = profitPercent.toString();

      // 如果有小數點，則取小數點後的字串
      let decimalPart = '';
      let decimalIndex = percentString.indexOf('.');

      if (decimalIndex !== -1) {
        decimalPart = percentString.substring(decimalIndex);
        percentString = percentString.substring(0, decimalIndex);
      }

      return percentString + decimalPart + ' %';
    }
  }
};
</script>
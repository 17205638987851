<template>
  <div>
    <customer-company :customer_form="customer_form"></customer-company>
    <div class="mb-3 card">
      <div class="card-header">
        {{ '移轉內容' }}
      </div>
      <div class="card-body">
        <div class="row">
          <!-- console domain -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_console_domain">{{ I18n.t('console_domain', locale_scope) }}</label>
              <select id="customer_console_domain" name="customer[console_domain]" class="custom-select" v-model="customer.console_domain">
                <option disabled value>{{ I18n.t('helpers.select.prompt') }}</option>
                <option v-for="(console_domain, console_domain_index) in console_domains" :key="console_domain_index" :value="console_domain">
                  {{ I18n.t(`simple_form.labels.customer.console_domain.${console_domain}`) }}
                </option>
              </select>
              <small class="text-muted d-block">需要移轉至哪個 Partner Console。</small>
            </div>
          </div>
          <!-- 移轉網域 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_domain">{{ I18n.t('domain', locale_scope) }}</label>
              <input type="text" id="customer_domain" class="form-control" name='customer[domain]' v-model.trim="customer.domain" />
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 移轉權杖 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_transfer_token">{{ I18n.t('transfer_token', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('transfer_token', locale_scope)" v-slot="{ errors }" immediate>
              <input type="text" id="customer_transfer_token" class="form-control" name='customer[transfer_token]' v-model.trim="customer.transfer_token" />
              <input type="hidden" name="customer[category]" v-model="customer.category" />
              <small class="text-muted d-block">移轉權杖的有效期限為 14 天，請確認效期。</small>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <!-- 移轉 Cloud Identity ID -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_cloud_identity_id">{{ I18n.t('cloud_identity_id', locale_scope) }}</label>
              <input type="text" id="customer_cloud_identity_id" class="form-control" name='customer[cloud_identity_id]' v-model.trim="customer.cloud_identity_id" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 負責人員 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('assign_to', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <ValidationProvider rules="required" :name="I18n.t('assign_to', locale_scope)" v-slot="{ errors }" immediate>
          <input type="hidden" name='customer[assign_to_id]' v-model="assign_to.id">
          <multiselect v-model="assign_to" :options="managers" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" :show-labels="false" :custom-label="nameWithEmail">
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <!-- 業務窗口 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('business_info', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="customer_business_contact">{{ I18n.t('business_contact', locale_scope) }}</label>
          <ValidationProvider rules="required" :name="I18n.t('business_contact', locale_scope)" v-slot="{ errors }" immediate>
          <input type="text" id="customer_business_contact" class="form-control" name='customer[business_contact]' v-model.trim="customer.business_contact" />
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row" >
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_business_contact_phone">{{ I18n.t('business_contact_phone', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('business_contact_phone', locale_scope)" v-slot="{ errors }" immediate>
              <input type="tel" id="customer_business_contact_phone" class="form-control" name='customer[business_contact_phone]' v-model.trim="customer.business_contact_phone" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_business_contact_email">{{ I18n.t('business_contact_email', locale_scope) }}</label>
              <ValidationProvider rules="required|email" :name="I18n.t('business_contact_email', locale_scope)" v-slot="{ errors }" immediate>
              <input type="email" id="customer_business_contact_email" class="form-control" name='customer[business_contact_email]' v-model.trim="customer.business_contact_email" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服務方案 -->
    <workspace-support-plan-record-form :support_plan_info="support_plan_info"></workspace-support-plan-record-form>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  props: {
    customer_form: {
      type: Object,
      requred: true
    }
  },
  data(){
    return {
      I18n,
      lodash,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/customer'
      },
      customer: this.customer_form.customer,
      companies: this.customer_form.companies,
      managers: this.customer_form.managers,
      assign_to: this.customer_form.managers.find(manager => manager.id === this.customer_form.customer.assign_to_id) || {},
      console_domains: this.customer_form.console_domains,
      support_plan_info: {
        support_plan_options: this.customer_form.support_plan_options,
        support_plan_record: this.customer_form.new_support_plan_record
      }
    }
  },
  methods: {
    nameWithEmail({ name, email }){
      if (lodash.isEmpty(this.managers) || lodash.isUndefined(name) || lodash.isUndefined(email)){
        return ''
      } else {
        return `${name} (${email})`
      }
    },
  },
  watch: {
    'customer.transfer_token': function(transfer_token){
      if(transfer_token){
        this.customer.category = 'transfer'
      }
    }
  },
}
</script>
<template>
  <div class="position-relative search-wrapper w-100" @mouseenter="showDropdown" @mouseleave="hideDropdown">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <label class="m-0" for="search-dropdown">
            <font-awesome-icon :icon="['fas', 'search']" />
          </label>
        </span>
      </div>
      <input type="text" :placeholder="I18n.t('search')" class="search-element form-control" id="search-dropdown" aria-label="Search Dropdown" aria-haspopup="true" v-model.trim="keyword" @keyup="searchSubmit">
      <label for="search-dropdown" v-if="keyword.length > 0" class="pr-3 search-element position-absolute d-flex align-items-center" @click="clearSearch">
          <font-awesome-icon :icon="['fas', 'times']" />
      </label>
    </div>
    <div class="overflow-auto shadow-sm card position-absolute search-result" v-if="dropdown_status">
      <template v-for="(result, result_index) in search_result">
        <div class="card-header" :key="result_index">
          {{ result.source }}
        </div>
        <div class="list-group list-group-flush" :key="result.source">
          <a :href="content.url" v-for="(content, content_index) in result.contents" :key="`${result_index}${content_index}`" class="list-group-item list-group-item-action">
            <div class="flex-wrap d-flex justify-content-between">
              <p class="font-weight-bold">{{ content.name }}</p>
              <p v-if="content.status">
                <span class="mr-3" v-html="content.assign_to"></span>
                <span v-html="content.status"></span>
              </p>
            </div>
            <em v-if="content.resource_id">{{ content.resource_id }}</em>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return {
      I18n,
      keyword: '',
      cancelSource: null,
      dropdown_status: false,
      search_result: [],
    }
  },
  methods: {
    searchSubmit(){
      if (this.cancelSource){
        this.cancelSource.cancel()
      }
      this.cancelSource = axios.CancelToken.source();
      axios.get('/search.json', {
        cancelToken: this.cancelSource.token,
        params: {
          keyword: this.keyword
        }
      }).then( ({data}) => {
        this.search_result = data
        this.dropdown_status = data.length
        this.cancelSource = null
      }).catch((error) => {

      })
    },
    showDropdown(){
      this.dropdown_status = true
    },
    hideDropdown(){
      this.dropdown_status = false
    },
    clearSearch(){
      this.keyword = ''
      this.search_result = []
    },
  }
}
</script>

<style>

</style>
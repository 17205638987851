<template>
  <div class="col-12">
    <div class="form-group">
      <label for="contract_cud_profit_percent">{{ I18n.t('cud_profit_percent', locale_scope) }}</label>
      <div class="input-group">
        <input
          type="text"
          id="contract_cud_profit_percent"
          class="form-control"
          name="contract[cud_profit_percent]"
          v-model="contract.cud_profit_percent"
        >
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div v-html="show_cud_profit_percent_warning"></div>
    </div>
    <div class="form-group">
        <label> {{ I18n.t('discount_percent', locale_scope) }}</label>
      <div class="input-group">
        <input
          type="text"
          id="contract_discount_percent"
          class="form-control"
          name='contract[discount_percent]'
          v-model="contract.discount_percent"
        >
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div v-html="discount_percent_msg"></div>
      <div v-html="show_discount_percent_warning"></div>
    </div>
  </div>
</template>
<script>
import { validPercent, showWarning, percentMsg }  from '../../../javascripts/valid_percent';

export default {
  props: {
    contract: {
      type: Object
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.contract'
      },
      show_cud_profit_percent_warning: '',
      show_discount_percent_warning: '',
      discount_percent_msg: ''
    }
  },
  mounted() {
    this.show_cud_profit_percent_warning = showWarning(this.contract.cud_profit_percent)
    this.show_discount_percent_warning = showWarning(this.contract.discount_percent)
    this.discount_percent_msg = percentMsg(this.contract.discount_percent)
  },
  watch: {
    'contract.discount_percent': function(percent) {
      this.contract.discount_percent = validPercent(percent)
      this.show_discount_percent_warning = showWarning(percent)
      this.discount_percent_msg = percentMsg(percent)
    },
    'contract.cud_profit_percent': function(percent) {
      this.contract.cud_profit_percent = validPercent(percent)
      this.show_cud_profit_percent_warning = showWarning(percent)
    }
  },
}
</script>

<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="company_name">{{ I18n.t('name', locale_scope) }}</label>
          <ValidationProvider rules="required" :name="I18n.t('name', locale_scope)" v-slot="{ errors }" immediate>
            <input type="text" id="company_name" class="form-control " name="company[name]" v-model.trim="company.name" />
            <small class="text-muted d-block">請勿使用如"/"、"\"等特殊符號，以免出帳錯誤。詳情請<a href="https://cloud.google.com/storage/docs/naming-objects" target="_blank">點此Cloud Storage文件</a></small>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label for="company_description">{{ I18n.t('description', locale_scope) }}</label>
          <input type="text" id="company_description" class="form-control " name="company[description]" v-model.trim="company.description">
        </div>

        <div class="form-group">
          <label for="company_tax_number">{{ I18n.t('tax_number', locale_scope) }}</label>
          <input type="text" id="company_tax_number" class="form-control " name="company[tax_number]" v-model.trim="company.tax_number">
        </div>

        <div class="form-group">
          <label for="company_country">{{ I18n.t('country', locale_scope) }}</label>
          <country-select id="company_country" className="custom-select" :countryName="true" name="company[country]" v-model="country" :country="country" topCountry="Taiwan" :placeholder="I18n.t('helpers.select.prompt')" />
        </div>

        <template v-if="country == 'Taiwan'">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="form-group">
                  <label for="address_city">{{ I18n.t('city', locale_scope) }}</label>
                  <county v-model="city" id="address_city" name="company[city]" class="custom-select"></county>
              </div>
            </div>

            <div class="col-12 col-sm-3">
              <div class="form-group">
                <label for="address_district">{{ I18n.t('district', locale_scope) }}</label>
                <zipcode v-model="district" id="address_district" :filter-by-county="city" value-template=":city" text-template=":city" name="company[district]" class="custom-select" required></zipcode>
                <zipcode v-model="district" id="address_zipcode" value-template=":id" name="company[zipcode]" class="d-none"></zipcode>
              </div>
            </div>

            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="company_road">{{ I18n.t('road', locale_scope) }}</label>
                <input type="text" id="company_road" class="form-control " name="company[road]" v-model.trim="company.road">
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="form-group">
            <label for="company_road">{{ I18n.t('road', locale_scope) }}</label>
            <input id="company_road" class="form-control " name="company[road]" v-model.trim="company.road">
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="company_district">{{ I18n.t('district', locale_scope) }}</label>
                <input type="text" id="company_district" class="form-control " name="company[district]" v-model.trim="company.district">
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label for="company_city">{{ I18n.t('city', locale_scope) }}</label>
                <input type="text" id="company_city" class="form-control " name="company[city]" v-model.trim="company.city">
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label for="company_zipcode">{{ I18n.t('zipcode', locale_scope) }}</label>
                <input type="text" id="company_zipcode" class="form-control " name="company[zipcode]" v-model.trim="company.zipcode">
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('inhouse') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_subsidiary_id">{{ I18n.t('activerecord.models.subsidiary') }}</label>
              <ValidationProvider rules="required" :name="I18n.t('activerecord.models.subsidiary')" v-slot="{ errors }" immediate>
                <input type="hidden" name="company[subsidiary_id]" v-model="subsidiary.id">
                <multiselect v-model="subsidiary" :options="subsidiaries" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" :show-labels="false">
                </multiselect>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_invoice_display_name">{{ I18n.t('invoice_display_name', locale_scope) }}</label>
              <input id="company_invoice_display_name" class="form-control" name="company[invoice_display_name]" type="text" v-model.trim="company.invoice_display_name">
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_billing_language">{{ I18n.t('billing_language', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('billing_language', locale_scope)" v-slot="{ errors }" immediate>
                <select id="company_billing_language" class="custom-select" name="company[billing_language]" v-model="company.billing_language">
                  <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                  <option v-for="(billing_language, billing_language_index) in billing_languages" :value="billing_language" :key="billing_language_index">{{ I18n.t(`simple_form.labels.company.billing_language.${billing_language}`) }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_netsuite_id">{{ I18n.t('netsuite_id', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('netsuite_id', locale_scope)" v-slot="{ errors }" immediate>
                <input id="company_netsuite_id" class="form-control" name="company[netsuite_id]" type="text" v-model.trim="company.netsuite_id">
                <small class="text-muted d-block">{{ I18n.t('netsuite_id_changed_reminder', locale_scope) }}</small>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_internal_id">{{ I18n.t('internal_id', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('internal_id', locale_scope)" v-slot="{ errors }" immediate>
                <input id="company_internal_id" class="form-control " name="company[internal_id]" type="text" v-model.trim="company.internal_id">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_salesforce_id_google_tw">{{ I18n.t('salesforce_id_google_tw', locale_scope) }}</label>
                <input id="company_salesforce_id_google_tw" class="form-control" name="company[salesforce_id_google_tw]" type="text"
                v-model.trim="company.salesforce_id_google_tw">
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <company-merge-to
              :companies="companies"
              :company="company"
              :action_name="action_name"
            >
            </company-merge-to>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_currency">{{ I18n.t('currency', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('currency', locale_scope)" v-slot="{ errors }" immediate>
                <input type="hidden" name="company[currency]" v-model="currency.text_value">
                <multiselect
                  v-model="currency"
                  :options="currency_options"
                  :placeholder="I18n.t('helpers.select.prompt')"
                  label="display_text"
                  track-by="id"
                  :show-labels="false"
                >
                </multiselect>
                <small class="text-muted d-block">異動時，請務必檢查 Support Fee 及額外費用設定</small>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_invoice_comment">{{ I18n.t('invoice_comment', locale_scope) }}</label>
              <input id="company_invoice_comment" class="form-control" name="company[invoice_comment]" type="text" v-model.trim="company.invoice_comment" maxlength="45">
              <small class="text-muted d-block">最多可輸入45個字</small>
            </div>
          </div>
        </div>

        <div class="form-group">
          <input name="company[manual_billing]" type="hidden" value="0">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" id="company_manual_billing" name="company[manual_billing]" value="1" class="custom-control-input" v-model="company.manual_billing">
            <label for="company_manual_billing" class="custom-control-label">{{ I18n.t('manual_billing', locale_scope) }}</label>
            <div>
              <small class="text-muted">如有勾選，出帳時則不會寄出非台幣 Invoice 信件（含測試信）</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <company-billing-account :billing_accounts="billing_accounts" :company="company"></company-billing-account>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('assign_to', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <input type="hidden" name="company[assign_to_id]" v-model="assign_to.id">
          <multiselect v-model="assign_to" :options="managers" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" :show-labels="false" :custom-label="nameWithEmail">
          </multiselect>
        </div>
      </div>
    </div>

    <company-contact
      :company="company"
      :contact_categories="contact_categories"
      :blank_company_contact="blank_company_contact"
    >
    </company-contact>

    <comment-form
      :commentable="'company'"
      :comments="comments"
      :comment_categories="comment_categories"
    >
    </comment-form>
  </div>
</template>

<script>
import { CountrySelect } from 'vue-country-region-select'
import { Zipcode, County } from 'twzipcode-vue'
export default {
  components: {
    CountrySelect,
    // 縣市
    County,

    // 郵遞區號
    Zipcode,
  },
  props: {
    company_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.company'
      },
      company: this.company_form.company,
      companies: this.company_form.companies,
      managers: this.company_form.managers,
      subsidiaries: this.company_form.subsidiaries,
      billing_accounts: this.company_form.billing_accounts,
      blank_company_contact: this.company_form.blank_company_contact,
      contact_categories: this.company_form.contact_categories,
      comment_categories: this.company_form.comment_categories,
      comments: this.company_form.company.comments,
      country: this.company_form.company.country || 'Taiwan',
      city: this.company_form.company.city || "",
      district: this.company_form.company.district || "",
      zipcode: this.company_form.company.zipcode || "",
      road: this.company_form.company.road || "",
      currency: this.company_form.currency_options.find(currency => currency.text_value === this.company_form.company.currency) || {},
      currency_options: this.company_form.currency_options,
      billing_languages: this.company_form.billing_languages,
      assign_to: this.company_form.managers.find(manager => manager.id === this.company_form.company.assign_to_id) || {},
      subsidiary: this.company_form.subsidiaries.find(subsidiary => subsidiary.id === this.company_form.company.subsidiary_id) || {},
      invoice_comment: this.company_form.company.invoice_comment || "",
      salesforce_id_google_tw: this.company_form.company.salesforce_id_google_tw || "",
      action_name: this.company_form.action_name
    }
  },
  methods: {
    nameWithEmail({ name, email }){
      if (lodash.isEmpty(this.managers) || lodash.isUndefined(name) || lodash.isUndefined(email)){
        return ''
      } else {
        return `${name} (${email})`
      }
    },
  },
  watch: {
    'company.name': function(name) {
      this.company.invoice_display_name = name
    },
    'company.salesforce_id_google_tw': function(salesforce_id_google_tw) {
      // 僅接受中英文、數字
      const format = /[^\u4e00-\u9fa5A-Za-z0-9]/
      if (salesforce_id_google_tw.match(format)) {
        this.company.salesforce_id_google_tw = salesforce_id_google_tw.replace(format, "")
      }
    },
  }
}
</script>

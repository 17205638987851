<template>
  <div>
    <div class="mb-3 card is-show">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table datatable table-striped table-hover w-100" :ref="`table-comopany-${comopany}`" data-order='[[ "1", "asc" ]]'>
            <thead>
              <tr>
                <th scope="col" data-name="ids" data-sortable="false" data-class-name="text-center align-middle">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" :id="`select_all-${comopany}`" class="custom-control-input">
                    <label :for="`select_all-${comopany}`" class="custom-control-label">
                        {{ I18n.t('select_all') }}
                    </label>
                  </div>
                </th>
                <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle">
                    {{ I18n.t('company_name', locale_scope) }}
                </th>
                <th scope="col" data-name="netsuite_id" data-sortable="true" data-class-name="text-center align-middle">
                    {{ I18n.t('netsuite_id', locale_scope) }}
                </th>
                <th scope="col" data-name="status" data-sortable="false" data-class-name="text-center align-middle">
                    {{ I18n.t('status', locale_scope) }}
                </th>
                <th scope="col" data-name="created_at" data-sortable="false" data-class-name="text-center align-middle">
                    {{ I18n.t('created_at') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="manager_incharge_company in manager_incharge_companies" :key="manager_incharge_company.key">
                <td scope="col" data-class-name="text-center align-middle" value="manager_incharge_company.id" >
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" :id="manager_incharge_company.id" :value="manager_incharge_company.id" class="custom-control-input">
                      <label :for="manager_incharge_company.id" class="custom-control-label"></label>
                  </div>
                </td>
                <td scope="col" data-class-name="text-center align-middle">
                  {{ manager_incharge_company.name }}
                </td>
                <td scope="col" data-class-name="text-center align-middle">
                  {{ manager_incharge_company.netsuite_id }}
                </td>
                <td scope="col" data-class-name="text-center align-middle" v-html="manager_incharge_company.status_tag"></td>
                <td scope="col" data-class-name="text-center align-middle">
                  {{ manager_incharge_company.created_at }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('transfer_manager.transfer_manager') }}
        <span style="color: red"> ({{ I18n.t('transfer_manager.enable_manager_only') }}) </span>
      </div>
      <div class="card-body">
        <div class="flex-wrap d-flex justify-content-between">
          <div class="col-md-11 col-sm-12">
            <multiselect
              v-model="enable_manager"
              :options="enable_managers"
              :placeholder="I18n.t('helpers.select.prompt')"
              label="name"
              track-by="id"
              :show-labels="false"
              :custom-label="nameWithEmail"
              :disabled="disableSelect"
            >
            </multiselect>
          </div>
          <button
            @click="transferManager"
            class="btn btn-success search-manager"
            type="button"
            :disabled="isButtonDisabled"
          >
            {{ I18n.t('transfer_manager.transfer') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    props: {
      manager_incharge_companies: {
        type: Array,
        required: true,
      },
      enable_managers: {
        type: Array,
        required: true,
      },
      manager: {
        tyep: Object,
        required: true,
      },
      disableSelect: {
        type: Boolean,
        required: true,
      },
      comopany: {
        type: Boolean,
        default: true
      }
    },
    data(){
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.company'
        },
        dataTable: null,
        select_all: false,
        select_all_element: `#select_all-${this.comopany}`,
        selected_ids: [],
        enable_manager: null,
        isButtonDisabled: true,
      }
    },
    mounted() {
      this.table_element = this.$refs[`table-comopany-${this.comopany}`]
      this.dataTable = $(this.$el.querySelector(".datatable"))
          .DataTable({
            aLengthMenu: [10, 25, 50],
            responsive: true,
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`,
            }
          }).on("draw.dt", ({currentTarget}) => {
            const $table = $(currentTarget)
            this.bindingCheckbox($table);
          }).columns.adjust();
    },
    methods: {
      bindingCheckbox($table){
        // 全選
        $table.off("change", this.select_all_element)
        $table.on("change", this.select_all_element, function(){
          if (this.checked) {
            $('tbody :checkbox:not(:checked)', this.table_element).trigger("click");
            } else {
            $('tbody :checkbox:checked', this.table_element).trigger("click");
          };
        })

        // 單選
        $table.off("change", "tbody :checkbox")
        $table.on("change", "tbody :checkbox", ({target}) => {
          this.setId(target);
        });
      },
      setId(selected_input){
        const $select_all = $(this.select_all_element)
        const changed_id = $(selected_input).val()
        if(selected_input.checked){
          this.selected_ids.push(changed_id)
        } else {
          const index = this.selected_ids.indexOf(changed_id);
          if (index > -1) {
            this.selected_ids.splice(index, 1);
          }
        };

        //控制 □全選 不同狀態的畫面顯示（單選/多選/全選）
        $select_all.prop("indeterminate", false);
        if (this.selected_ids.length === 0) {
          $select_all.prop("checked", false);
        } else if (this.selected_ids.length === $(this.table_element).find('tbody :checkbox').length) {
          $select_all.prop("checked", true);
        } else {
          $select_all.prop("checked", false);
          $select_all.prop("indeterminate", true);
        };
      },
      nameWithEmail({ name, email }){
        return `${name} (${email})`
      },
      transferManager(){
        const data = {
          authenticity_token: this.authenticity_token,
          new_assign_id: this.enable_manager.id,
          selected_companies: this.selected_ids,
          orig_assign_id: this.manager.id
        }

        axios.post(`${window.location.pathname}/transfer.json`, data)
             .then(({data}) => {
               if(data.success) {
                 Toastr('success', I18n.t('successfully_updated'))
                 this.$emit('reload');
               } else {
                 Toastr('warning', data.message)
               }
             }).catch((e) => {
               Toastr('error', I18n.t('transfer_manager.system_error'))
             });
      },
    },
    watch: {
      'enable_manager': function() {
        if (this.enable_manager !== null) {
          this.isButtonDisabled = false
        };
      }
    }
  }
</script>

<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "4", "asc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-class-name="text-center align-middle">{{ I18n.t('sku', locale_scope) }}</th>
          <th scope="col" data-class-name="text-center align-middle">{{ I18n.t('name', locale_scope) }}</th>
          <th scope="col" data-class-name="text-center align-middle">{{ I18n.t('google_discount_percent', locale_scope) }}</th>
          <th scope="col" data-class-name="text-center align-middle">{{ I18n.t('discount_percent', locale_scope) }}</th>
          <th scope="col" data-class-name="text-center align-middle" data-visible="false">{{ I18n.t('created_at') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="edp_pricing in edp_pricings" :key="edp_pricing.id">
          <td>{{ edp_pricing.sku }}</td>
          <td>{{ edp_pricing.name }}</td>
          <td>{{ `${edp_pricing.google_discount_percent}%` }}</td>
          <td>{{ `${edp_pricing.discount_percent}%` }}</td>
          <td>{{ formatTime(edp_pricing.created_at) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {

  props: {
    edp_pricings: {
      type: Array,
      required: true
    },
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.edp_pricing'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
    formatTime(datetime){
      return dayjs(datetime).format('YYYY-MM-DD')
    }
  },
}
</script>

<style>

</style>
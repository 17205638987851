<template>
  <div class="mb-3 card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span>{{ I18n.t('add_on', locale_scope) }}</span>
      <button type="button" class="btn btn-primary" @click.prevent="newAddOn">
        <font-awesome-icon :icon="['fas', 'plus']" />
      </button>
    </div>
    <div class="card-body" v-if="selected_add_ons.length > 0">
      <div class="form-group" v-for="(selected_add_on, selected_add_on_index) in selected_add_ons" :key="selected_add_on_index" v-show="!isDeleted(selected_add_on)">
        <input type="hidden" v-model="selected_add_on.id" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][id]`">
        <input type="hidden" v-model="selected_add_on.created_by_id" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][created_by_id]`">
        <input type="hidden" v-model="selected_add_on._destroy" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][_destroy]`">
        <input type="hidden" v-model="selected_add_on.workspace_customer_id" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][workspace_customer_id]`">
        <div class=" justify-content-between">
          <div class="row">
            <div class="col-12 col-sm-5">
              <div class="form-group">
                <!-- 選擇時間 -->
                <date-time-picker :name="`entitlement[add_ons_attributes][${selected_add_on_index}][start_at]`" :current_date="selected_add_on.start_at" :placeholder="I18n.t('adjust_at', locale_scope)"></date-time-picker>
              </div>
            </div>
            <div class="col-12 col-sm-5">
              <div class="form-group">
                <!-- 加購SKU -->
                <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][product_id]`" :ref="`add_ons_${selected_add_on_index}_product_id`" />
                <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][product_display_name]`" :ref="`add_ons_${selected_add_on_index}_product_display_name`" />
                <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][sku_display_name]`" :ref="`add_ons_${selected_add_on_index}_sku_display_name`" />
                <ValidationProvider rules="required" :name="I18n.t('sku', locale_scope)" v-slot="{ errors }" immediate>
                <multiselect v-model="selected_add_on.sku" :options="add_on_skus" :placeholder="I18n.t('sku', locale_scope)" :custom-label="skuWithName" track-by="name" :id="`add_ons_${selected_add_on_index}`" @input="setValue"></multiselect>
                <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][sku_id]`" :ref="`add_ons_${selected_add_on_index}_sku_id`" />
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <!-- 合約 -->
                <select :id="`add_ons_${selected_add_on_index}_duration`" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][duration]`" class="custom-select">
                  <option disabled value> {{ '選擇合約' }}</option>
                  <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                    {{ duration.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <!-- Offer -->
                <ValidationProvider rules="required" :name="I18n.t('offer_plan', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_offer_plan" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][offer_plan]`" v-model="selected_add_on.offer_plan" class="custom-select">
                  <option v-for="(offer_plan, offer_plan_index) in offer_plan_groups" :key="offer_plan_index" :value="offer_plan.value">
                    {{ offer_plan.text }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <!-- 授權數 -->
                <ValidationProvider rules="required|max_units" :name="I18n.t('renewal_units', locale_scope)" v-slot="{ errors }" immediate>
                <input type="number" min="0" class="form-control" v-model.trim="selected_add_on.units" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][units]`" :placeholder="I18n.t('units', locale_scope)">
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-12 col-sm-2">
              <div class="form-group">
                <!-- 刪除 -->
                <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteComment(selected_add_on_index)">
                  <font-awesome-icon :icon="['fas', 'trash']" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { extend } from "vee-validate"

extend("max_units", {
  // drive & cloud identity premium
  validate(value) {
    return value < 50001;
  },
  message: '授權數量上限必須介於 1 至 50000 之間',
})

export default {
  props: {
    entitlement: {
      type: Object,
      required: true
    },
    created_by_id: {
      type: Number,
      required: true
    },
    add_on_skus: {
      type: Array
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/entitlement'
      },
      selected_add_ons: [],
      duration_groups:[
        { value: '1', text: '1 個月' },
        { value: '2', text: '2 個月' },
        { value: '3', text: '3 個月' },
        { value: '4', text: '4 個月' },
        { value: '5', text: '5 個月' },
        { value: '6', text: '6 個月' },
        { value: '7', text: '7 個月' },
        { value: '8', text: '8 個月' },
        { value: '9', text: '9 個月' },
        { value: '10', text: '10 個月' },
        { value: '11', text: '11 個月' },
        { value: '12', text: '1 年' },
        { value: '24', text: '2 年' },
        { value: '36', text: '3 年' },
        { value: '0', text: '免費' }
      ],
      purchasable_offers: [],
      offer_plan_groups: [
        { value: 'Flexible Plan', text: 'Flexible Plan' },
        { value: 'Annual Plan (Monthly Payment)', text: 'Annual Plan (Monthly Payment)' }
      ]
    }
  },
  methods: {
    newAddOn() {
      const new_add_on = [{
        id: null,
        product_display_name: null,
        product_id: null,
        sku_id: null,
        sku_display_name: null,
        duration: null,
        workspace_customer_id: this.entitlement.workspace_customer_id,
        _destroy: null,
        created_by_id: this.created_by_id,
        offer_plan: null
      }]
      this.selected_add_ons = [
        ...this.selected_add_ons, ...new_add_on
      ]
    },
    isDeleted(selected_add_on){
      return selected_add_on._destroy == '-1'
    },
    skuWithName({marketing_info}){
      return marketing_info.display_name
    },
    deleteComment(index) {
      this.selected_add_ons[index]._destroy = '-1'
    },
    isDeleted(selected_add_on){
      return selected_add_on._destroy == '-1'
    },
    setValue(selected_sku, id){
      $(this.$refs[`${id}_sku_display_name`]).val(selected_sku.marketing_info.display_name)
      $(this.$refs[`${id}_sku_id`]).val(selected_sku.name.split('/')[3])
      $(this.$refs[`${id}_product_id`]).val(selected_sku.name.split('/')[1])
      $(this.$refs[`${id}_product_display_name`]).val(selected_sku.product.marketing_info.display_name)
    }
  },
}
</script>
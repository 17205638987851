import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    current_manager: null,
    billing_account: {
      syncing: false,
    },
    manager_groups: [],
  },
  mutations: {
    loadManager(state, current_manager) {
      state.current_manager = current_manager;
    },
    toggleSyncBillingAccount(state, billing_account, status) {
      state.billing_account.syncing = status;
    },
    updateManagerGroupTree: (state, payload) => {
      state.manager_groups = payload;
    },
  },
  actions: {
    getManager({ commit }, current_manager) {
      commit("loadManager", current_manager);
    },
    startSyncBilllingAccount({ commit }, billing_account) {
      commit("toggleSyncBillingAccount", billing_account, true);
    },
    endSyncBilllingAccount({ commit }, billing_account) {
      commit("toggleSyncBillingAccount", billing_account, false);
    },
    updateManagerGroupTree({ commit }, payload) {
      commit("updateManagerGroupTree", payload);
    },
  },
});

export default store;

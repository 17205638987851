<template>
  <div>
    <ValidationObserver>
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('info') }}
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>{{ I18n.t('company', locale_scope) }}</label>
            <ValidationProvider
              :rules="`${billing_account.poc ? '' : 'required'}`"
              :name="I18n.t('activerecord.models.company')"
              v-slot="{ errors }"
              immediate
            >
            <input
              type="hidden"
              name="billing_account[company_id]"
              v-model="select_company_id"
            >
            <multiselect
              v-model="billing_account.company"
              :options="companies"
              :placeholder="I18n.t('helpers.select.prompt')"
              label="name"
              track-by="id"
              :allow-empty="billing_account.poc"
            >
            </multiselect>
            <span class="block text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <input name="billing_account[poc]" type="hidden" value="0">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="billing_account_poc"
                name="billing_account[poc]"
                value="1" class="custom-control-input"
                v-model="billing_account.poc"
              >
              <label for="billing_account_poc" class="custom-control-label">{{ I18n.t('poc', locale_scope) }}</label>
              <div class="d-flex align-items-center">
                <small class="text-muted">{{ I18n.t('simple_form.hints.billing_account.poc') }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: {
    billing_account_form: {
      type: Object,
      required: true
    },
  },
  data(){
    return{
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.billing_account'
      },
      billing_account: this.billing_account_form.billing_account,
      companies: this.billing_account_form.companies,
      select_company_id: this.billing_account_form.billing_account.company_id
    }
  },
  watch: {
    'billing_account.company': function(company){
      this.select_company_id = company ? company.id : null
    }
  },
}
</script>
<template>
  <div>
    <div class="form-group">
      <label for="subsidiary_name">{{ I18n.t('name', locale_scope) }}</label>
      <input type="text" id="subsidiary_name" class="form-control" name='subsidiary[name]' v-model="subsidiary.name">
    </div>
    <div class="form-group">
      <label for="subsidiary_description">{{ I18n.t('description', locale_scope) }}</label>
      <input type="text" id="subsidiary_description" class="form-control" name='subsidiary[description]' v-model="subsidiary.description">
    </div>
    <div class="form-group">
      <label for="subsidiary_subsidiary_group">{{ I18n.t('subsidiary_group', locale_scope) }}</label>
      <input type="hidden" name="subsidiary[subsidiary_group]" v-model.trim="subsidiary.subsidiary_group" >
      <multiselect v-model.trim="subsidiary.subsidiary_group" :options="subsidiary_groups" :placeholder="I18n.t('helpers.select.prompt')" :show-labels="false" :taggable="true" @tag="addGroup" :allow-empty="false" :tag-placeholder="I18n.t('create_option')" >
        <template slot="noOptions">
          {{ I18n.t('list_empty') }}
        </template>
      </multiselect>
      <small class="text-muted"> {{I18n.t('can_create_option')}} </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subsidiary_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.subsidiary'
      },
      subsidiary: this.subsidiary_form.subsidiary,
      subsidiary_groups: this.subsidiary_form.subsidiary_groups
    }
  },
  methods: {
    addGroup(new_group) {
      this.subsidiary.subsidiary_group = new_group
      this.subsidiary_groups.push(new_group)
    }
  },
}
</script>

<style>

</style>
<template>
<div>
  <div class="d-flex mb-2">
    <div class="mr-2 badge badge-primary">{{ I18n.t('activerecord.attributes.project.general_tag') }}</div>
    <div class="mr-2 badge badge-secondary">{{ I18n.t('activerecord.attributes.project.cud_tag') }}</div>
  </div>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-server-side="false" data-order='[[ "2", "desc" ]]' >
      <thead>
        <tr>
          <th scope="col" data-name="company_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center col-3">
            {{ I18n.t('current_company', locale_scope) }}
          </th>
          <th scope="col" data-name="company_status" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('company', locale_scope) }}<br>
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="billing_account_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center col-3">
            {{ I18n.t('billing_account_id', locale_scope) }}
          </th>
          <th scope="col" data-name="billing_account_status" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('billing_account', locale_scope) }}<br>
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="project_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('project_id', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</div>
</template>

<script>
  export default {
    components: {
    },
    props: {
      url: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.billing_account_project_contract'
        },
        dataTable: null,
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url,
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    },
  };
</script>
//於合約列表頁使用批次停用
import axios from 'axios'
import "./sweetalert";

window.addEventListener('load', () => {
  const batch_suspend = document.getElementById('batch_suspend')
  if (!batch_suspend) return

  batch_suspend.addEventListener('click', function(e) {
    const checkBoxes = document.querySelectorAll('.contract-checkbox:checked')
    const contract_ids = Array.from(checkBoxes).map((box) => { return box.id })

    if (contract_ids.length === 0) {
      Toastr('info', '請選取要停用之合約')
      return
    }

    Swal.fire({
      title: I18n.t('confirm_action'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: I18n.t('true'),
      cancelButtonText: I18n.t('cancel'),
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: true
    }).then((result) => {
      if (!result.isConfirmed) return
      axios.defaults.headers.common['X-CSRF-TOKEN'] = document.getElementsByName('csrf-token')[0].content
      axios.post(`${window.location.pathname}/batch_suspend`,{ contract_ids: contract_ids })
           .then(({data}) => {
             if(data.success) {
               let seletedCheckBoxes = Array.from(document.querySelectorAll('.contract-checkbox'))
                                            .filter((box) => { 
                                              return data.contract_table[box.id] 
                                            })

               seletedCheckBoxes.forEach((box) => {
                 box.parentElement.parentElement.parentElement.querySelector('.btn-toolbar.justify-content-center').innerHTML = `<a class="btn btn-secondary mx-1" href="/approvals/${data.contract_table[box.value]}">未完成審核流程</a>`
                 box.click()
                 box.parentElement.parentElement.querySelector('.custom-checkbox').innerHTML = '<div></div>'
               })
             }
               let noticeType = data.success ? 'success' : 'error'
               Toastr(noticeType, data.message)

               }).catch((error) => {
                 Toastr('error', error)
               })
    });
  })
})
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import calender from "dayjs/plugin/calendar";
require("dayjs/locale/zh-tw");
require("dayjs/locale/en");
dayjs.locale("zh-tw");
dayjs.extend(AdvancedFormat);
dayjs.extend(CustomParseFormat);
dayjs.extend(calender);
window.dayjs = dayjs;

<template>
  <div>
    <!-- 基本資料 -->
    <div class="mb-3 card" :class="content">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="row">
          <input type="hidden" name="copied" v-model="copied">
          <input type="hidden" name="contract[company_id]" v-model="contract.company_id">
          <input type="hidden" name="contract[contract_group_id]" v-model="contract.contract_group_id">
          <!-- 產品類型1為GCP -->
          <input type="hidden" name="contract[product_id]" value="1">
          <div class="col-12">
            <div class="form-group">
              <label for="company_currency">{{ I18n.t('activerecord.attributes.company.currency') }}</label>
              <input id="company_currency" readonly class="form-control" name="company[currency]" v-model=currency.display_text>
              <small class="text-muted">如需變更幣別，請至公司編輯頁面</small>
            </div>
          </div>
          <contract-discount :contract="contract"></contract-discount>
          <contract-tax :contract="contract" :tax_options="tax_options"></contract-tax>
        </div>
      </div>
    </div>

    <div :class="content">
      <!-- 第三方 -->
      <contract-marketplace
        :contract="contract"
        :locale_scope="locale_scope"
      >
      </contract-marketplace>
    </div>

    <!-- 帳戶專案合約關聯 -->
    <contract-project
      :company_billing_account_project_contracts="company_billing_account_project_contracts"
      :contract="contract"
      :disable_content="wait_approval"
    >
    </contract-project>

    <div :class="content">
      <!-- Support Plan -->
      <contract-support
        :contract="contract"
        :currencies="selectable_currencies()"
        :blank_contract_support="blank_contract_support"
        :sub_categories_of_common="sub_categories_of_common"
      >
      </contract-support>

      <!-- FET Support -->
      <contract-fet-support
        :contract="contract"
        :currencies="selectable_currencies()"
        :blank_fet_support="blank_fet_support"
        :delegate_names="delegate_names"
      >
      </contract-fet-support>

      <!-- 匯率 -->
      <contract-exchange-rate
        :contract="contract"
        :rate_categories="rate_categories"
        :rate_dates="rate_dates"
      >
      </contract-exchange-rate>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';

export default {
  components: {
    flatPickr
  },
  props: {
    contract_form: {
      type: Object,
      requred: true
    },
  },
  data() {
    return {
      locale_scope: {
        scope: 'activerecord.attributes.contract'
      },
      copied: this.contract_form.copied,
      contract: this.contract_form.contract,
      company_billing_account_project_contracts: this.contract_form.company_billing_account_project_contracts,
      managers: this.contract_form.managers,
      currency_options: this.contract_form.currency_options,
      statuses: this.contract_form.statuses,
      rate_categories: this.contract_form.rate_categories,
      rate_dates: this.contract_form.rate_dates,
      tax_options: this.contract_form.tax_options,
      blank_contract_support: this.contract_form.blank_contract_support,
      blank_fet_support: this.contract_form.blank_fet_support,
      delegate_names: this.contract_form.delegate_names,
      config: {
        locale: MandarinTraditional,
        altFormat: 'Y-m-d',
      },
      content: this.contract_form.copied ? 'copied_content' : null,
      sub_categories_of_common: this.contract_form.sub_categories_of_common_support,
      currency: this.contract_form.currency_options.find(currency => currency.text_value === this.contract_form.currency),
      wait_approval: this.contract_form.wait_approval
    }
  },
  methods: {
    selectable_currencies(){
      let usd_currency = this.currency_options.find(currency => currency.text_value === 'usd')

      if (lodash.isEmpty(this.currency)){
        return [usd_currency]
      } else {
        return (lodash.uniqBy([usd_currency, this.currency], 'text_value'))
      }
    }
  }
}
</script>

<style>
.copied_content {
  pointer-events: none;
  opacity: 0.5;
}
</style>
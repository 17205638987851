<template>
  <div>
    <!-- 客戶清單 -->
    <entitlement-customer @getCustomer="setSelectTransferableSkus" :entitlement_form="entitlement_form" :customers="customers"></entitlement-customer>
    <!-- 移轉主約 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('detail', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="row">
          <!-- 開始時間 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('start_at', locale_scope)" v-slot="{ errors }" immediate>
              <date-time-picker id="entitlement_start_at" :name="'entitlement[start_at]'" :current_date="entitlement.start_at" @changeDateTime="changeDateTime"></date-time-picker>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <!-- 期間 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_duration">{{ I18n.t('duration', locale_scope) }}</label>
              <select id="entitlement_duration" name="entitlement[duration]" class="custom-select" v-model="entitlement.duration">
                <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                  {{ duration.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- 選擇SKU -->
        <div class="form-group">
          <label for="entitlement_sku_id">{{ I18n.t('sku', locale_scope) }}</label>
          <input type="hidden" name="entitlement[product_id]" v-model="selected_product_id" />
          <input type="hidden" name="entitlement[product_display_name]" v-model="selected_product_display_name" />
          <input type="hidden" name="entitlement[sku_display_name]" v-model="selected_sku_display_name" />
          <input type="hidden" name="entitlement[category]" value="transfer" />

          <ValidationProvider rules="required" :name="I18n.t('sku', locale_scope)" v-slot="{ errors }" immediate>
          <input type="hidden" name="entitlement[sku_id]" v-model="selected_sku" />
          <multiselect v-model.trim="entitlement.sku_id" :options="transferable_skus" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="skuWithName">
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row">
          <!-- Offer -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_offer_plan">{{ I18n.t('offer_plan', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('offer_plan', locale_scope)" v-slot="{ errors }" immediate>
              <select id="entitlement_offer_plan" name="entitlement[offer_plan]" v-model="entitlement.offer_plan" class="custom-select">
                <option v-for="(offer_plan, offer_plan_index) in offer_plan_groups" :key="offer_plan_index" :value="offer_plan.value">
                  {{ offer_plan.text }}
                </option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <!-- 授權數 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_units">{{ I18n.t('units', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('units', locale_scope)" v-slot="{ errors }" immediate>
              <input type="number" min="1" id="entitlement_units" class="form-control" name='entitlement[units]' v-model.trim="entitlement.units" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 移轉加購 -->
    <div class="mb-3 card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span>{{ I18n.t('add_on', locale_scope) }}</span>
        <button type="button" class="btn btn-primary" @click.prevent="newAddOn">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </button>
      </div>
      <div class="card-body" v-if="selected_add_ons.length > 0">
        <div class="form-group" v-for="(selected_add_on, selected_add_on_index) in selected_add_ons" :key="selected_add_on_index" v-show="!isDeleted(selected_add_on)">
          <input type="hidden" v-model="selected_add_on.id" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][id]`">
          <input type="hidden" v-model="selected_add_on.created_by_id" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][created_by_id]`">
          <input type="hidden" v-model="selected_add_on.category" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][category]`">
          <input type="hidden" v-model="selected_add_on._destroy" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][_destroy]`">
          <input type="hidden" v-model="selected_add_on.workspace_customer_id" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][workspace_customer_id]`">
          <input type="hidden" v-model="entitlement.start_at" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][start_at]`">
          <div class="d-flex justify-content-between">
            <!-- 合約 -->
            <select :id="`add_ons_${selected_add_on_index}_duration`" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][duration]`" class="custom-select">
              <option disabled value> {{ '選擇合約' }}</option>
              <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                {{ duration.text }}
              </option>
            </select>
            <!-- Offer -->
            <select :id="`add_ons_${selected_add_on_index}_offer_plan`" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][offer_plan]`" v-model.trim="selected_add_on.offer_plan" class="custom-select">
              <option disabled value> {{ '付款方案' }}</option>
              <option v-for="(offer_plan, offer_plan_index) in offer_plan_groups" :key="offer_plan_index" :value="offer_plan.value">
                {{ offer_plan.text }}
              </option>
            </select>
            <!-- 加購SKU -->
            <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][product_id]`" :ref="`add_ons_${selected_add_on_index}_product_id`" />
            <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][product_display_name]`" :ref="`add_ons_${selected_add_on_index}_product_display_name`" />
            <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][sku_display_name]`" :ref="`add_ons_${selected_add_on_index}_sku_display_name`" />
            <multiselect v-model="selected_add_on.sku" :options="transferable_skus" :placeholder="I18n.t('sku', locale_scope)" :custom-label="skuWithName" :id="`add_ons_${selected_add_on_index}`" @input="setSkuValue"></multiselect>
            <input type="hidden" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][sku_id]`" :ref="`add_ons_${selected_add_on_index}_sku_id`" />
            <!-- 授權數 -->
            <input type="number" min="1" class="form-control" v-model.trim="selected_add_on.units" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][units]`" :placeholder="I18n.t('units', locale_scope)">
            <!-- 開通服務付款方式: 比照主約訂閱 -->
            <input type="hidden" v-model="entitlement.activate_payment_method" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][activate_payment_method]`" />
            <!-- 加購服務付款方式: 比照主約訂閱 -->
            <input type="hidden" v-model="entitlement.add_on_payment_method" :name="`entitlement[add_ons_attributes][${selected_add_on_index}][add_on_payment_method]`" />
            <!-- 刪除 -->
            <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteComment(selected_add_on_index)">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 移轉付款條件 -->
    <entitlement-payment-condition :entitlement="entitlement" :activate_payment_methods="activate_payment_methods"></entitlement-payment-condition>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
export default {
  components: {
    Multiselect,
  },
  props: {
    entitlement_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      lodash,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/entitlement'
      },
      entitlement: this.entitlement_form.entitlement,
      selected_sku: this.entitlement_form.entitlement.sku_id,
      selected_sku_display_name: this.entitlement_form.entitlement.sku_display_name,
      selected_product_id: this.entitlement_form.entitlement.product_id,
      selected_product_display_name: this.entitlement_form.entitlement.product_display_name,
      duration_groups:[
        { value: '1', text: '1 個月' },
        { value: '2', text: '2 個月' },
        { value: '3', text: '3 個月' },
        { value: '4', text: '4 個月' },
        { value: '5', text: '5 個月' },
        { value: '6', text: '6 個月' },
        { value: '7', text: '7 個月' },
        { value: '8', text: '8 個月' },
        { value: '9', text: '9 個月' },
        { value: '10', text: '10 個月' },
        { value: '11', text: '11 個月' },
        { value: '12', text: '1 年' },
        { value: '24', text: '2 年' },
        { value: '36', text: '3 年' }
      ],
      transferable_skus: [],
      created_by_id: this.entitlement_form.created_by_id,
      selected_add_ons: [],
      customers: this.entitlement_form.transferred_customers,
      offer_plan_groups: [
        { value: 'Flexible Plan', text: 'Flexible Plan' },
        { value: 'Annual Plan (Monthly Payment)', text: 'Annual Plan (Monthly Payment)' }
      ],
      activate_payment_methods: this.entitlement_form.activate_payment_methods,
      add_on_payment_methods: this.entitlement_form.add_on_payment_methods
    }
  },
  methods: {
    setSelectTransferableSkus(){
      axios.get('/list_transferable_skus.json', {
        params: {
          customer_id: this.entitlement_form.entitlement.workspace_customer.customer_id,
          console_domain: this.entitlement_form.entitlement.workspace_customer.console_domain
        }
      }).then((response) => {
        const data = response.data
        const ineligible_data = data.filter((value) => { return !value.transfer_eligibility.is_eligible })
        const drive_product_id = 'Uv2DWGG1lwqAXX'
        const drive_data = data.filter((value) => { return value.sku.name.split('/')[1] === drive_product_id })

        if(ineligible_data.length > 0){
          this.transferable_skus = []
          Toastr('error', `以下 SKU 無法進行移轉程序 : <br /> ${ineligible_data[0].sku.marketing_info.display_name} <br /> 原因 : ${ineligible_data[0].transfer_eligibility.ineligibility_reason}`)
        } else {
          this.transferable_skus = data
          Toastr('success', '已更新方案選項')
          // 自動生成多個加購欄位
          if(drive_data.length > 0 && drive_data.length != this.selected_add_ons.length){
            _.times(drive_data.length, this.newAddOn)
          }
        };
      }).catch((error) => {
        Toastr('error', '此客戶無法操作移轉')
      })
    },
    skuWithName({sku}){
      return sku.marketing_info.display_name
    },
    changeDateTime(date){
      this.entitlement.start_at = date
    },
    newAddOn(){
      if(this.entitlement.workspace_customer){
        const new_add_on = [{
          id: null,
          product_display_name: null,
          product_id: null,
          sku_id: null,
          sku_display_name: null,
          duration: null,
          workspace_customer_id: this.entitlement.workspace_customer.id,
          _destroy: null,
          created_by_id: this.created_by_id,
          category: 'transfer',
          start_at: null,
          offer_plan: null,
          activate_payment_methods: null,
          add_on_payment_methods: null
        }]
        this.selected_add_ons = [
          ...this.selected_add_ons, ...new_add_on
        ]
      } else {
        Toastr('error', '請選擇客戶')
      }
    },
    isDeleted(selected_add_on){
      return selected_add_on._destroy == '-1'
    },
    deleteComment(index) {
      this.selected_add_ons[index]._destroy = '-1'
    },
    isDeleted(selected_add_on){
      return selected_add_on._destroy == '-1'
    },
    setSkuValue(transfer_data, id){
      if(transfer_data){
        $(this.$refs[`${id}_sku_display_name`]).val(transfer_data.sku.marketing_info.display_name)
        $(this.$refs[`${id}_sku_id`]).val(transfer_data.sku.name.split('/')[3])
        $(this.$refs[`${id}_product_id`]).val(transfer_data.sku.name.split('/')[1])
        $(this.$refs[`${id}_product_display_name`]).val(transfer_data.sku.product.marketing_info.display_name)
      }
    },
    clearSelectedOffer(){
      this.entitlement.offer_plan = null
    },
    clearSelectedDuration(){
      this.entitlement.duration = null
    }
  },
  watch: {
    'entitlement.sku_id': function(transfer_data){
      this.selected_sku = transfer_data ? transfer_data.sku.name.split('/')[3] : null
      this.selected_sku_display_name = transfer_data ? transfer_data.sku.marketing_info.display_name : null
      this.selected_product_id = transfer_data ? transfer_data.sku.name.split('/')[1] : null
      this.selected_product_display_name = transfer_data ? transfer_data.sku.product.marketing_info.display_name : null

      this.clearSelectedOffer()
      this.clearSelectedDuration()
    }
  },
}
</script>
<template>
  <div>
    <div class="d-flex mb-2">
      <div class="mr-2 badge badge-primary">{{ I18n.t('activerecord.attributes.project.general_tag') }}</div>
      <div class="mr-2 badge badge-secondary">{{ I18n.t('activerecord.attributes.project.cud_tag') }}</div>
    </div>
    <div class="table-responsive">
      <table class="table datatable table-striped table-hover w-100" data-server-side="false" data-order='[[ "2", "desc" ]]' >
        <thead>
          <tr>
            <th scope="col" data-name="company_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('current_company', locale_scope) }}
            </th>
            <th scope="col" data-name="billing_account_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('billing_account_id', locale_scope) }}
            </th>
            <th scope="col" data-name="project_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('project_id', locale_scope) }}
            </th>
            <th scope="col" data-name="contract_id" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('billing_contract', locale_scope) }}
            </th>
            <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('action') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(billing_account_project_contract, index) in billing_account_project_contracts" :key="index">
            <td class="text-center align-middle col-2">
              <span v-html="billing_account_project_contract.company_link_tag"></span>
            </td>
            <td class="text-center align-middle">
              <span v-html="billing_account_project_contract.billing_account_link"></span>
            </td>
            <td class="text-center align-middle">
              <span v-html="billing_account_project_contract.project_id_tag"></span>
            </td>
            <td class="text-center align-middle col-2">
              <template v-if="(billing_account_project_contract.editable) && editIndex === index">
                <multiselect
                  v-model="selected_contract"
                  :options="contracts"
                  :placeholder="I18n.t('helpers.select.prompt')"
                  label="uid"
                  :show-labels="false"
                  track-by="id"
                  :max-height="80">
                </multiselect>
              </template>
              <template v-else>
                <span v-html="billing_account_project_contract.contract_link"></span>
              </template>
            </td>
            <td class="text-center align-middle col-2">
              <template v-if="(billing_account_project_contract.editable)">
                <template v-if="editIndex === index">
                  <div class="btn-group" role="group">
                    <b-button @click="submit(billing_account_project_contract, index)" variant="success">
                      {{ I18n.t('save') }}
                    </b-button>
                    <b-button @click="cancelEdit(billing_account_project_contract, index)" variant="secondary">
                      {{ I18n.t('cancel') }}
                    </b-button>
                  </div>
                </template>
                <template v-else>
                  <b-button @click="edit(billing_account_project_contract, index)" variant="primary">
                    {{ I18n.t('edit') }}
                  </b-button>
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    components: {
    },
    props: {
      billing_account_project_contracts_form: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.billing_account_project_contract'
        },
        dataTable: null,
        billing_account_project_contracts: this.billing_account_project_contracts_form.billing_account_project_contracts,
        contracts: this.billing_account_project_contracts_form.contracts,
        editIndex: -1,
        selected_contract: null
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
    },
    methods: {
      edit(billing_account_project_contract, index){
        this.selected_contract = billing_account_project_contract.contract
        this.editIndex = index
      },
      submit(billing_account_project_contract){
        if(this.selected_contract == null) {
          Toastr('error', '請選擇出帳合約')
          return
        }
        const id = billing_account_project_contract.id
        const data = {
          authenticity_token: this.authenticity_token,
          billing_account_project_contract: {
            contract_id: this.selected_contract.id
          }
        }

        axios.put(`/billing_account_project_contracts/${id}`, data)
        .then((response) => {
          const toast_type = response.data.type
          const toast_message = response.data.message

          if(toast_type === 'success') {
            location.reload()
          } else {
            Toastr(toast_type, toast_message)
          }
        }).catch((error) => {
          Toastr('error', error)
        })
      },
      cancelEdit(){
        this.editIndex = -1
        this.selected_contract = null
      }
    },
  };
</script>
<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "5", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="name"  data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('name', locale_scope) }}
          </th>
          <th scope="col" data-name="code" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('code', locale_scope) }}
          </th>
          <th scope="col" data-name="price" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('price', locale_scope) }}
          </th>
          <th scope="col" data-name="duration" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('duration', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="action" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
</div>
</template>

<script>
export default {
  props: {
    ids: {
      type: Array
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.dat'
      },
      dataTable: null,
    }
  },
  mounted() {
    this.dataTable = $(this.$el.querySelector(".datatable"))
      .DataTable({
        responsive: true,
        ajax: {
          url: `${window.location.pathname}.json`,
          data: (d) => { d.id = this.ids }
        },
        language: {
          url: `/datatable.${I18n.currentLocale()}.lang.json`,
        },
      }).columns.adjust();
  },
  methods: {
    reloadTable() {
      this.dataTable.ajax.reload()
    },
  },
};
</script>

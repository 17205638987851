<template>
  <div class="form-group">
    <label for="company_merge_to">{{ I18n.t('merge_to', locale_scope) }}</label>
    <input type="hidden" name="company[merge_to_id]" v-model.trim="selected_merge_to_id">
    <multiselect v-model="selected_merge_to" :options="companies" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id">
      <template slot="noOptions">
        {{ I18n.t('list_empty') }}
      </template>
    </multiselect>
    <small class="text-muted d-block">不填則預設是自己</small>
  </div>
</template>

<script>
export default {
  props: {
    companies: {
      type: Array,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    action_name: {
      type: String,
      required: true,
    }
  },
  data(){
    return{
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.company'
      },
      selected_merge_to: this.action_name === 'edit' ? this.company.merge_to : { id: null }
    }
  },
  computed: {
    selected_merge_to_id(){
      return this.selected_merge_to ? this.selected_merge_to.id : null
    }
  }
}
</script>

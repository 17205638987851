<template>
  <div>
    <manager-group-item :editable="editable" v-model="manager_groups"></manager-group-item>
    <span v-show="false" v-html="manager_groups"></span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    editable: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.manager_group'
      },
    }
  },
  mounted(){
    axios.get(`${window.location.pathname}.json`).then(({ data }) => {
      this.manager_groups = data;
    });
  },
  computed: {
    manager_groups: {
      get() {
        return this.$store.state.manager_groups
      },
      set(value){
        this.$store.dispatch("updateManagerGroupTree", value);
      }
    }
  },
  updated(){
    const data = {
        authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        manager_groups: this.manager_groups
      };
    axios.put(`${window.location.pathname}/batch_update`, data).then(({ data }) => {

    })
  },
}
</script>

<template>

  <div class="form-group">
    <input type="hidden" name='company_id' v-model="select_company_id">
    <div class="row">
      <div class="col">
        <multiselect v-model="company" :options="companies" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="nameWithInvoiceDisplay" track-by="id" :show-labels="false" >
        </multiselect>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" @click.prevent="submitTestEmail"> {{ I18n.t('submit') }}</button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
export default {
  props: {
    companies: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '一般'
    }
  },
  data(){
    return{
      I18n,
      authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      company: null,
      select_company_id: null
    }
  },
  watch: {
    'company': function(company){
      this.select_company_id = company.id
    }
  },
  methods: {
    submitTestEmail(){
      const data = {
        authenticity_token: this.authenticity_token,
        company_id: this.company.id,
        target: this.target
      }

      axios.post(`${this.url}.json`, data).then((response) => {
        const toast_type = response.data.type
        const toast_message = response.data.message
        const toast_title = response.data.title
        Toastr(toast_type, toast_message, toast_title)
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    },
    nameWithInvoiceDisplay({ name, invoice_display_name }) {
      if(invoice_display_name == name){
        return name
      } else {
        return `${invoice_display_name} - (${name})`
      }
    }
  }
}
</script>

<style>

</style>
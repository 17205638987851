<template>
  <div>
    <div class="mb-2">
      <div class="d-flex">
        <div class="mr-2 badge badge-primary">一般</div>
        <div class="mr-2 badge badge-warning"> {{ I18n.t('activerecord.attributes.project.poc') }}</div>
        <div class="mr-2 badge badge-secondary">{{ I18n.t('activerecord.attributes.project.cud') }}</div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table datatable table-striped table-hover w-100" data-order='[[ "2", "desc" ]]' >
        <thead>
          <tr>
            <th scope="col" data-name="billing_account" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('activerecord.models.billing_account') }}
            </th>
            <th scope="col" data-name="project_id" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('project_id', locale_scope) }}
            </th>
            <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('status', locale_scope) }}
            </th>
            <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('action') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    url: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.project'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>

<style lang="scss" >

</style>
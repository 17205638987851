<template>
<div>
  <!-- 基本資料 -->
  <ValidationObserver>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('info') }}
    </div>
    <div class="card-body">
      <div class="form-group">
        <input name="project[cud]" type="hidden" value="0">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" id="project_cud" name="project[cud]" value="1" class="custom-control-input" v-model="project.cud">
          <label for="project_cud" class="custom-control-label">{{ I18n.t('cud', locale_scope) }}</label>
          <div class="d-flex align-items-center">
            <small style="color: red;">※ 若有勾選時，請務必至 Console 將「billing-portal@mf-billing-portal.iam.gserviceaccount.com」設定「compute viewer」權限，方可取得 Rawdata</small>
          </div>
        </div>
      </div>
      <div class="form-group" :class="switch_disable_class">
        <input name="project[not_billing]" type="hidden" value="0">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" id="project_not_billing" name="project[not_billing]" value="1" class="custom-control-input" v-model="project.not_billing">
          <label for="project_not_billing" class="custom-control-label">{{ I18n.t('not_billing', locale_scope) }}</label>
        </div>
      </div>
    </div>
  </div>
  </ValidationObserver>

  <!-- Project Level 的 CUD Commitment 相關服務是否出帳 -->
  <template v-if="showCudServiceList()">
    <cud-blank-project-form :project_form="project_form"></cud-blank-project-form>
  </template>
</div>

</template>

<script>
  export default {
    props: {
      project_form: {
        type: Object
      }
    },
    data(){
      return{
        locale_scope: {
          scope: 'activerecord.attributes.project'
        },
        project: this.project_form.project,
        commitments: this.project_form.commitments
      }
    },
    computed: {
      switch_disable_class() {
        if(this.project.cud){
          return 'disable'
        } else {
          return ''
        }
      }
    },
    watch: {
      'project.cud': function(cud){
        if(cud){
          this.project.not_billing = true
        }
      }
    },
    methods: {
      showCudServiceList() {
        return (this.commitments.length > 0)
      }
    },
  }
</script>

<style scoped>
  .disable {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
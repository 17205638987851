<template>
  <div class="my-3 card">
    <div class="card-header">
      IGS 出帳
    </div>
    <div class="card-body">
      <div class="simple_form">
        <!-- <div class="form-group">
        <label for="year_month">{{ I18n.t('year_month', locale_scope) }}</label>
        <month-picker :name="'year_month'" :current_date="year_month" @changeDate="changeDate"></month-picker>
      </div> -->
        <div class="form-group">
          <button class="btn btn-success" @click.prevent="submitBilling" data-disabled-with="處理中......"> {{ I18n.t('submit') }} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.billing_event'
      },
      year_month: dayjs().subtract(1, 'month').format('YYYY-MM')
    }
  },
  methods: {
    changeDate(date){
      this.year_month = date
    },
    submitBilling(event){
      event.target.disabled = true
      event.target.classList.add('opacity-50', 'cursor-not-allowed')
      const authenticity_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      const year_month = this.year_month
      const invoice_type = 'igs'
      const data = {
        authenticity_token, year_month, invoice_type
      }

      axios.post(`${window.location.pathname}`, data).then((response) => {
        const toast_type = response.data.type
        const toast_message = response.data.message
        Toastr(toast_type, toast_message)
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    }
  }
}
</script>

<style>

</style>
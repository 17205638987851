<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="col-4">
          <div class="form-group">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ I18n.t('exchange_rate', locale_scope) }}&emsp;TWD&emsp;</span>
              </div>
              <input type="number" step="any" class="form-control" v-model.trim="new_amount"/>
              <div class="input-group-append">
                <button class="btn btn-success" type="button" id="gws_payment_exchange_rate" :disabled="!can_edit" @click="updateExchangeRate(new_amount)">{{ I18n.t('save') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h5>{{ I18n.t('latest_exchange_rate', locale_scope) }}</h5>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-center">{{ I18n.t('exchange_rate', locale_scope) }}</th>
              <th scope="col" class="text-center">{{ I18n.t('latest_updated_manager', locale_scope) }}</th>
              <th scope="col" class="text-center">{{ I18n.t('latest_updated_at', locale_scope) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="exchange_rate !== null">
              <td class="text-center">TWD&emsp;{{ exchange_rate.amount }}</td>
              <td class="text-center">{{ exchange_rate.latest_updated_manager }}</td>
              <td class="text-center">{{ exchange_rate.updated_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.gws_exchange_rate'
        },
        exchange_rate: null,
        can_edit: null,
        current_manager_id: null,
        new_amount: null
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      getInfo() {
        axios.get('/gws_exchange_rates/info').then(({ data }) => {
          this.exchange_rate = data.exchange_rate
          this.can_edit = data.current_manager_edit_policy
          this.current_manager_id = data.current_manager_id
        })
      },
      updateExchangeRate(new_amount) {
        if(new_amount) {
          const data = {
            authenticity_token: this.authenticity_token,
            amount: new_amount,
            updated_by_manager_id: this.current_manager_id
          }

          axios.put(`/gws_exchange_rates/${this.exchange_rate.id}`, data
          ).then((response) => {
            const toast_type = response.data.type
            const toast_message = response.data.message

            this.new_amount = null
            Toastr(toast_type, toast_message)

            // 抓取最新匯率資料
            setTimeout(() => {
              this.getInfo()
            }, 1000)

          }).catch((error) => {
            Toastr('error', error)
          })
        } else {
          Toastr('error', '請填寫匯率')
        }
      }
    }
  }
</script>
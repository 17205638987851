<template>
  <div>
    <customer-company :customer_form="customer_form"></customer-company>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('org_info', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="row">
          <!-- console domain -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_console_domain">{{ I18n.t('console_domain', locale_scope) }}</label>
              <select id="customer_console_domain" name="customer[console_domain]" class="custom-select" v-model="customer.console_domain">
                <option disabled value>{{ I18n.t('helpers.select.prompt') }}</option>
                <option v-for="(console_domain, console_domain_index) in console_domains" :key="console_domain_index" :value="console_domain">
                  {{ I18n.t(`simple_form.labels.customer.console_domain.${console_domain}`) }}
                </option>
              </select>
            </div>
          </div>
          <!-- 機構名稱 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_org_display_name">{{ I18n.t('org_display_name', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('org_display_name', locale_scope)" v-slot="{ errors }" immediate>
              <input type="text" id="customer_org_display_name" class="form-control" name='customer[org_display_name]' v-model.trim="customer.org_display_name" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- 網域 -->
        <ValidationProvider :vid="'domain'" rules="required" :name="I18n.t('domain', locale_scope)">
        <verify-domain :name="'customer[domain]'" v-model.trim="customer.domain" @getdomain="getDomain" :current_domain="customer.domain" :disabled="customer.id"></verify-domain>
        </ValidationProvider>

        <div class="row" >
          <!-- 語言代碼 -->
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="customer_language_code">{{ I18n.t('language_code', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('language_code', locale_scope)" v-slot="{ errors }" immediate>
              <b-form-select v-model="customer.language_code" :options="languages" name="customer[language_code]">
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- 國家/地區 -->
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="customer_region_code">{{ I18n.t('region_code', locale_scope) }}</label>
              <select v-model="customer.region_code"
              name="customer[region_code]" class="custom-select">
                <option v-for="(region_code, region_code_index) in region_codes" :key="region_code_index" :value="region_code">
                  {{ I18n.t(`simple_form.labels.customer.region_code.${region_code}`) }}
                </option>
              </select>
            </div>
          </div>

          <!-- 移轉權杖 -->
          <div class="col-12 col-sm-4" v-if="customer.transfer_token">
            <div class="form-group">
              <label for="customer_transfer_token">{{ I18n.t('transfer_token', locale_scope) }}</label>
              <input type="text" id="customer_transfer_token" class="form-control" name='customer[transfer_token]' v-model.trim="customer.transfer_token" />
            </div>
          </div>
        </div>

        <template v-if="customer.region_code == 'TW'">
          <div class="row" >
            <!-- 縣市 -->
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <label for="customer_administrative_area">{{ I18n.t('administrative_area', locale_scope) }}</label>
                <ValidationProvider :rules="infoRule()" :name="I18n.t('administrative_area', locale_scope)" v-slot="{ errors }" immediate>
                <county v-model="administrative_area" id="customer_administrative_area" name="customer[administrative_area]" class="custom-select" />
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- 鄉鎮市區 -->
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <label for="customer_locality">{{ I18n.t('locality', locale_scope) }}</label>
                <zipcode v-model="locality" id="customer_locality" :filter-by-county="administrative_area" value-template=":city" text-template=":zipcode :city" name="customer[locality]" class="custom-select" required @input="setPostalCode"></zipcode>
                <!-- 郵遞區號 -->
                <zipcode v-model="postal_code" id="customer_postal_code"
                ref="customer_postal_code"
                class="d-none" name="customer[postal_code]"></zipcode>
              </div>
            </div>

            <!-- 街道地址 -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="customer_address_lines">{{ I18n.t('address_lines', locale_scope) }}</label>
                <ValidationProvider :rules="infoRule()" :name="I18n.t('address_lines', locale_scope)" v-slot="{ errors }" immediate>
                <input type="text" id="customer_address_lines" class="form-control" name='customer[address_lines]' v-model.trim="customer.address_lines" />
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </template>

        <!-- 非台灣地區，輸入地址相關欄位 -->
        <template v-else>
          <div class="form-group">
            <label for="customer_address_lines">{{ I18n.t('address_lines', locale_scope) }}</label>
            <ValidationProvider :rules="infoRule()" :name="I18n.t('address_lines', locale_scope)" v-slot="{ errors }" immediate>
            <input type="text" id="customer_address_lines" class="form-control" name='customer[address_lines]' v-model.trim="customer.address_lines" />
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="row" >
            <div class="col">
              <div class="form-group">
                <label for="customer_administrative_area">{{ I18n.t('administrative_area', locale_scope) }}</label>
                <ValidationProvider :rules="infoRule()" :name="I18n.t('administrative_area', locale_scope)" v-slot="{ errors }" immediate>
                <input type="text" id="customer_administrative_area" class="form-control" name='customer[administrative_area]' v-model.trim="customer.administrative_area">
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="customer_locality">{{ I18n.t('locality', locale_scope) }}</label>
                <ValidationProvider :rules="infoRule()" :name="I18n.t('locality', locale_scope)" v-slot="{ errors }" immediate>
                <input type="text" id="customer_locality" class="form-control" name='customer[locality]' v-model.trim="customer.locality">
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="customer_postal_code">{{ I18n.t('postal_code', locale_scope) }}</label>
                <ValidationProvider :rules="infoRule()" :name="I18n.t('postal_code', locale_scope)" v-slot="{ errors }" immediate>
                <input type="text" id="customer_postal_code" class="form-control" name='customer[postal_code]' v-model.trim="customer.postal_code" />
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- 管理員聯絡資訊 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('contact_info', locale_scope) }}
        <small class="text-muted d-block">系統會使用您提供的姓名和電子郵件地址為 Google Workspace、G Suite 與 Cloud Identity 訂單建立初始管理員帳戶。</small>
      </div>
      <div class="card-body">
        <div class="row" >
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_admin_first_name">{{ I18n.t('admin_first_name', locale_scope) }}</label>
              <ValidationProvider :rules="infoRule()" :name="I18n.t('admin_first_name', locale_scope)" v-slot="{ errors }" immediate>
              <input type="text" id="customer_admin_first_name" class="form-control" name='customer[admin_first_name]' v-model.trim="customer.admin_first_name" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_admin_last_name">{{ I18n.t('admin_last_name', locale_scope) }}</label>
              <ValidationProvider :rules="infoRule()" :name="I18n.t('admin_last_name', locale_scope)" v-slot="{ errors }" immediate>
              <input type="text" id="customer_admin_last_name" class="form-control" name='customer[admin_last_name]' v-model.trim="customer.admin_last_name" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- 管理員電子信箱 -->
        <!-- TODO: 待補，讓電子郵件後面自動帶上填入的網域 -->
        <div class="form-group">
          <label for="customer_admin_email">{{ I18n.t('admin_email', locale_scope) }}</label>
          <ValidationProvider rules="required|email" :name="I18n.t('admin_email', locale_scope)" v-slot="{ errors }" immediate>
          <input type="email" id="customer_admin_email" class="form-control" name='customer[admin_email]' v-model.trim="customer.admin_email" />
          <small class="text-muted d-block">請輸入屬於該客戶主網域的電子郵件地址。</small>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!-- 管理員備用信箱 -->
        <div class="form-group">
          <label for="customer_alternate_email">{{ I18n.t('alternate_email', locale_scope) }}</label>
          <ValidationProvider rules="required|not_contain:@domain" :name="I18n.t('alternate_email', locale_scope)" v-slot="{ errors }" immediate >
          <input type="text" id="customer_alternate_email" class="form-control" name='customer[alternate_email]' v-model.trim="customer.alternate_email" />
          <small class="text-muted d-block">請輸入不屬於該客戶主網域的電子郵件地址。</small>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row">
          <!-- 管理員初始密碼 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_initial_password">{{ I18n.t('initial_password', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('initial_password', locale_scope)" v-slot="{ errors }" immediate>
              <input type="text" id="customer_initial_password" class="form-control" name='customer[initial_password]' v-model.trim="customer.initial_password" :disabled="customer.customer_id" />
              <small class="text-muted d-block">將作為客戶首次登錄「管理控制台」的密碼。</small>
              <span v-if="customer.status == 'draft'" class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <!-- 管理員電話 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_phone_number">{{ I18n.t('phone_number', locale_scope) }}</label>
              <input type="tel" id="customer_phone_number" class="form-control" name='customer[phone_number]' v-model.trim="customer.phone_number" />
              <small class="text-muted d-block">請使用國際電話號碼格式 (例如 +55 11 3048-1234 #567)。分機號碼為選填項目。</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 負責人員 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('assign_to', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <ValidationProvider rules="required" :name="I18n.t('assign_to', locale_scope)" v-slot="{ errors }" immediate>
          <input type="hidden" name='customer[assign_to_id]' v-model="assign_to.id">
          <multiselect v-model="assign_to" :options="managers" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" :show-labels="false" :custom-label="nameWithEmail">
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <!-- 客戶窗口 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('business_info', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="customer_business_contact">{{ I18n.t('business_contact', locale_scope) }}</label>
          <ValidationProvider rules="required" :name="I18n.t('business_contact', locale_scope)" v-slot="{ errors }" immediate>
          <input type="text" id="customer_business_contact" class="form-control" name='customer[business_contact]' v-model.trim="customer.business_contact" />
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row" >
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_business_contact_phone">{{ I18n.t('business_contact_phone', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('business_contact_phone', locale_scope)" v-slot="{ errors }" immediate>
              <input type="tel" id="customer_business_contact_phone" class="form-control" name='customer[business_contact_phone]' v-model.trim="customer.business_contact_phone" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="customer_business_contact_email">{{ I18n.t('business_contact_email', locale_scope) }}</label>
              <ValidationProvider rules="required|email" :name="I18n.t('business_contact_email', locale_scope)" v-slot="{ errors }" immediate>
              <input type="email" id="customer_business_contact_email" class="form-control" name='customer[business_contact_email]' v-model.trim="customer.business_contact_email" />
              <small class="text-muted d-block">為啟用通知寄送信箱。</small>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服務方案 -->
    <workspace-support-plan-record-form :support_plan_info="support_plan_info"></workspace-support-plan-record-form>
  </div>
</template>

<script>
import { CountrySelect } from 'vue-country-region-select'
import { Zipcode, County } from 'twzipcode-vue'
import { extend } from "vee-validate"

extend("not_contain", {
  params: ["target"],
  validate(value, { target }) {
    return !value.endsWith(target);
  },
  message: I18n.t("simple_form.labels.customer.errors.alternate_email"),
})

export default {
  components: {
    CountrySelect,
    County,
    Zipcode,
  },
  props: {
    customer_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      I18n,
      lodash,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/customer'
      },
      customer: this.customer_form.customer,
      // https://www.techonthenet.com/js/language_tags.php
      languages: [
        { value: 'en-US', text: 'English (United States)' },
        { value: 'zh-TW', text: '中文(台灣)' },
        { value: 'zh-CN', text: '中文(中國)' },
        { value: 'zh-HK', text: '中文(香港)' }
      ],
      // https://chromium-i18n.appspot.com/ssl-address/data
      region_codes: ['TW', 'HK', 'CN'],
      administrative_area: this.customer_form.customer.administrative_area,
      locality: this.customer_form.customer.locality || '',
      postal_code: this.customer_form.customer.postal_code || '',
      address_lines: this.customer_form.customer.address_lines,
      companies: this.customer_form.companies,
      managers: this.customer_form.managers,
      assign_to: this.customer_form.managers.find(manager => manager.id === this.customer_form.customer.assign_to_id) || {},
      console_domains: this.customer_form.console_domains,
      support_plan_info: {
        support_plan_options: this.customer_form.support_plan_options,
        support_plan_record: this.customer_form.latest_support_plan_record
      }
    }
  },
  methods: {
    // 選擇縣市後，去比對 zipcodes ，assign value 給 postal_code
    setPostalCode(locality){
      const city_locality = `${this.administrative_area} ${locality}`
      const zipcodes = this.$refs['customer_postal_code'].zipcodes

      const selected_zipcode = zipcodes.find(({text}) => {
        return text.split(' ').slice(1).join(' ') == city_locality
      })

      this.postal_code = selected_zipcode.value
    },
    getDomain(val){
      this.customer.domain = val
    },
    nameWithEmail({ name, email }){
      if (lodash.isEmpty(this.managers) || lodash.isUndefined(name) || lodash.isUndefined(email)){
        return ''
      } else {
        return `${name} (${email})`
      }
    },
    infoRule(){
      if (this.customer.id && this.customer.is_normal_create === false){
        return ''
      } else {
        return 'required'
      }
    }
  },
}
</script>
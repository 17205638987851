<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="form-group">
              <label for="dat_name">{{ I18n.t('activerecord.attributes.dat.name') }}</label>
              <div>
                <input type="hidden" name='dat[name]' v-model.trim="dat.name" />
                <multiselect 
                  v-model.trim="dat.name" 
                  :allow-empty="false"
                  :options="dat_names" 
                  :placeholder="I18n.t('helpers.select.prompt')"
                  :show-labels="false" 
                  :taggable="true" 
                  :tag-placeholder="I18n.t('create_option')"
                  @tag="addDatName">
                  <template slot="noOptions">
                    {{ I18n.t('list_empty') }}
                  </template>
                </multiselect>
                <small class="text-muted d-block">可以直接輸入新選項</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="form-group">
              <label for="dat_code">{{ I18n.t('activerecord.attributes.dat.code') }}</label>
              <div>
                <input type="hidden" name='dat[code]' v-model.trim="dat.code" />
                <multiselect 
                  v-model.trim="dat.code" 
                  :allow-empty="false" 
                  :options="dat_codes" type="number"
                  :placeholder="I18n.t('helpers.select.prompt')" 
                  :show-labels="false" 
                  :taggable="true" 
                  :tag-placeholder="I18n.t('create_option')"
                  @tag="addDatCode">
                  <template slot="noOptions">
                    {{ I18n.t('list_empty') }}
                  </template>
                </multiselect>
                <small class="text-muted d-block">可以直接輸入新選項</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="form-group">
              <label for="dat_price">{{ I18n.t('activerecord.attributes.dat.price') }}</label>
              <div class="input-group">
                <span class="input-group-text">台幣TWD</span>
                <input type="text" name='dat[price]' class="form-control" v-model.trim="dat.price" placeholder="0">
              </div>
              <small class="text-muted d-block">此處輸入的金額即為 DAT 檔案上顯示的金額</small>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="dat_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <month-picker :name="'dat[start_at]'" :current_date="dat.start_at"></month-picker>
              <small class="text-muted">若為一次性設定，開始月份與結束月份設定相同值即可</small>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="dat_end_at">{{ I18n.t('end_at', locale_scope) }}</label>
              <month-picker :name="'dat[end_at]'" :current_date="dat.end_at"></month-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    dat_form: {
      type: Object,
      requred: true
    },
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.dat'
      },
      dat: this.dat_form.dat,
      dat_names: this.dat_form.names || [],
      dat_codes: this.dat_form.codes || []
    }
  },
  methods: {
    addDatName(new_dat_name) {
      this.dat.name = new_dat_name
      this.dat_names.push(new_dat_name)
    },
    addDatCode(new_dat_code) {
      this.dat.code = new_dat_code
      this.dat_codes.push(new_dat_code)
    }
  },
  watch: {
    'dat.name': function(name){
      this.dat.name = name.replace(/\s/g, "")
    },
    'dat.code': function(code){
      this.dat.code = code.replace(/\s/g, "")
    }
  }
}
</script>
<template>
    <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "3", "desc" ]]' :ref="`table-contract-${contract}`" :class="`contract-${contract}`">
        <thead>
          <tr>
            <th scope="col" data-name="uid" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" :id="`select_all-${contract}`" class="custom-control-input">
                <label :for="`select_all-${contract}`" class="custom-control-label">
                  {{ I18n.t('select_all') }}
                </label>
                </div>
            </th>
            <th scope="col" data-name="uid" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('uid', locale_scope) }}
            </th>
            <th scope="col" data-name="billing_account_project_contracts_count" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('billing_account_project_contracts_count', locale_scope) }}
            </th>
            <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('status', locale_scope) }}
            </th>
            <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('created_at') }}
            </th>
            <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('action') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    ids: {
      type: Array
    },
    contract: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.contract'
      },
      dataTable: null,
      select_all: false,
      selected_ids: [],
      table_element: null,
      select_all_element: `#select_all-${this.contract}`
    }
  },
  mounted() {
    this.table_element = this.$refs[`table-contract-${this.contract}`]
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          aLengthMenu: [10, 25, 50],
          responsive: true,
          ajax: {
            url: this.url,
            data: (d) => {
              d.id = this.ids
            },
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).on("draw.dt", ({currentTarget}) => {
          const $table = $(currentTarget)
          this.bindingCheckbox($table);
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
      this.selected_ids = []
    },
    bindingCheckbox($table){
      const vm = this
      // 全選
      $table.off("change", vm.select_all_element)
      $table.on("change", vm.select_all_element, function(){
        if (this.checked) {
          $('tbody :checkbox:not(:checked)', vm.table_element).trigger("click");
          } else {
          $('tbody :checkbox:checked', vm.table_element).trigger("click");
          }
      })

      // 個別選
      $table.off("change", "tbody :checkbox")
      $table.on("change", "tbody :checkbox", ({target}) => {
        this.setId(target);
      });
    },
    setId(selected_input){
      const vm = this
      const $select_all = $(vm.select_all_element)
      const changed_id = $(selected_input).val()

      if(selected_input.checked){
        this.selected_ids.push(changed_id)
      } else {
        const index = this.selected_ids.indexOf(changed_id);
        if (index > -1) {
          this.selected_ids.splice(index, 1);
        }
      }

      //控制□全選狀態畫面顯示
      $select_all.prop("indeterminate", false);
      if (this.selected_ids.length === 0) {
        $select_all.prop("checked", false);
      } else if (this.selected_ids.length === $(vm.table_element).find('tbody :checkbox').length) {
        $select_all.prop("checked", true);
      } else {
        $select_all.prop("checked", false);
        $select_all.prop("indeterminate", true);
      }
    }
  },
};
</script>

<template>
  <div class="table-responsive">
    <div class="d-flex justify-content-end">
      <multiselect v-model="select_condition" :options="conditions" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" class="w-25"></multiselect>
      <div class="d-flex">
        <date-picker :name="'start_date'" @changeDate="changeStartDate" :placeholder="I18n.t('set_date', locale_scope)"></date-picker>
        <date-picker :name="'end_date'" @changeDate="changeEndDate" :placeholder="I18n.t('set_date', locale_scope)"></date-picker>
      </div>
      <button class="btn btn-secondary btn-sm" @click.prevent="searchRange">查詢</button>
    </div>
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "5", "desc" ]]' >
      <thead>
        <tr>
          <th scope="col" data-name="created_at" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('domain') }}
          </th>
          <th scope="col" data-name="sku_display_name" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('sku', locale_scope) }}
          </th>
          <th scope="col" data-name="assigned_units" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('assigned_units', locale_scope) }}
          </th>
          <th scope="col" data-name="units" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('units', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="start_time" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('start_at', locale_scope) }}
          </th>
          <th scope="col" data-name="end_time" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('end_at', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    url: {
      type: String
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/entitlement'
      },
      dataTable: null,
      conditions: [
        {id: 1, name: '續約日期', value: 'end_at'},
        {id: 2, name: '開始時間', value: 'start_at'},
      ],
      select_condition: {},
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD')
    }
  },
  mounted(){
    this.dataTable = $(this.$el.querySelector(".datatable"))
      .DataTable({
        reponsive: true,
        ajax: {
          url: this.url
        },
        language: {
          url: `/datatable.${I18n.currentLocale()}.lang.json`
        },
      }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
    changeStartDate(date){
      this.start_date = date
    },
    changeEndDate(date){
      this.end_date = date
    },
    searchRange(){
      const start_date = this.start_date
      const end_date = this.end_date
      const select_condition = this.select_condition.value
      const data = {
        start_date, end_date, select_condition
      }
      axios.get(`${window.location.pathname}.json`, {params: data}).then((response) => {
        if(response.data){
          const new_url = `${window.location.pathname}?start_date=${start_date}&end_date=${end_date}&select_condition=${select_condition}`

          history.pushState({}, null, new_url)
          this.dataTable.ajax.url(new_url)
          this.dataTable.ajax.reload()
        } else {
          Toastr('error', '查無資料')
        }
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    }
  },
}
</script>
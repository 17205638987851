<template>
  <div>
    <div class="mb-2">
      <div class="d-flex">
        <div class="mr-2 badge badge-primary">{{ I18n.t('activerecord.attributes.billing_account.general_tag') }}</div>
        <div class="mr-2 badge badge-warning"> {{ I18n.t('activerecord.attributes.billing_account.poc') }}</div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table datatable table-striped table-hover w-100" data-order='[[ "3", "desc" ]]' >
        <thead>
          <tr>
            <th scope="col" data-name="company_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center col-3">
              {{ I18n.t('current_company', locale_scope) }}
            </th>
            <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle" class="text-center col-3">
              {{ I18n.t('name', locale_scope) }}
            </th>
            <th scope="col" data-name="account_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('account_id', locale_scope) }}
            </th>
            <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('status', locale_scope) }}
            </th>
            <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
              {{ I18n.t('action') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    ids: {
      type: Array
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.billing_account'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url,
            data: (d) => {
              d.id = this.ids
            },
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>

<style lang="scss" >

</style>
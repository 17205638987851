<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "0", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="billing_account_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('activerecord.attributes.cud_blank_project.billing_account_id') }}
          </th>
          <th scope="col" data-name="project_id" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('activerecord.attributes.cud_blank_project.project_id') }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        dataTable: null
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    },
  };
</script>
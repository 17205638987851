<template>
  <div>
    <!-- 選擇發信公司 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('activerecord.models.company') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <ValidationProvider rules="required" :name="I18n.t('activerecord.models.company')" v-slot="{ errors }" immediate>
          <multiselect v-model="company" :options="companies" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id"></multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- 相關資訊 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info', email_function_locale) }}
      </div>
      <div class="card-body">
        <!-- Netsuite ID -->
        <div class="form-group">
          <label for="netsuite_id">{{ I18n.t('netsuite_id', company_locale) }}</label>
          <div class="form-control">{{ netsuite_id }}</div>
        </div>
        <!-- 虛擬帳號 -->
        <div class="form-group">
          <label for="virtual_account_number">{{ I18n.t('virtual_account_number', net_suite_setting_locale) }}</label>
          <div class="form-control">{{ virtual_account_number }}</div>
        </div>
        <!-- 虛擬帳號戶名 -->
        <div class="form-group">
          <label for="virtual_account_name">{{ I18n.t('virtual_account_name', net_suite_setting_locale) }}</label>
          <div class="form-control">{{ virtual_account_name }}</div>
        </div>
        <!-- 收件者 -->
        <div class="form-group">
          <label for="main_email">{{ I18n.t('main_email', company_locale) }}</label>
          <div class="form-control">
            <div v-for="main in main_emails" :key="main.email" class="badge badge-primary mr-1 d-inline">{{ main.email }}</div>
          </div>
        </div>
        <!-- 副本 -->
        <div class="form-group">
          <label for="cc_email">{{ I18n.t('cc_email', company_locale) }}</label>
          <div class="form-control">
            <div v-for="cc in cc_emails" :key="cc.email" class="badge badge-secondary mr-1 d-inline">{{ cc.email }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-success" @click.prevent="sendVirtualAccountNotification">{{ I18n.t('send_email', email_function_locale) }}</button>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      companies: {
        type: Array
      },
      email_function: {
        type: Object
      },
      index_url: {
        type: String
      },
      selected_company_id: {
        type: String
      }
    },
    data() {
      return {
        email_function_locale: {
          scope:'activerecord.attributes.email_function'
        },
        company_locale: {
          scope:'activerecord.attributes.company'
        },
        net_suite_setting_locale: {
          scope: 'activerecord.attributes.net_suite_setting'
        },
        company: null,
        netsuite_id: null,
        virtual_account_number: null,
        virtual_account_name: null,
        main_emails: null,
        cc_emails: null,
        url: window.location.pathname
      }
    },
    mounted() {
      if (this.selected_company_id) {
        this.company = this.companies.find(company => company.id == this.selected_company_id)
      }
    },
    watch: {
      'company': function(company) {
        if(company) {
          this.select_company_id = company.id
          this.netsuite_id = company.netsuite_id
          this.main_emails = company.company_contacts.filter(email => email.category === 'main') || null
          this.cc_emails = company.company_contacts.filter(email => email.category === 'cc') || null

          if(company.net_suite_setting) {
            this.virtual_account_number = company.net_suite_setting.virtual_account_number
            this.virtual_account_name = company.net_suite_setting.virtual_account_name

          } else {
            this.virtual_account_number = null
            this.virtual_account_name = null
          }

        } else {
          this.select_company_id = null
          this.netsuite_id = null
          this.virtual_account_number = null
          this.virtual_account_name = null
          this.main_emails = null
          this.cc_emails = null
        }
      }
    },
    methods: {
      sendVirtualAccountNotification() {
        const data = {
          authenticity_token: this.authenticity_token,
          company_id: this.select_company_id,
          netsuite_id: this.netsuite_id
        }

        // 轉圈圈動畫
        this.showAlert()

        axios.put(`${this.url}/send_email`, data
        ).then((response) => {

          setTimeout(() => {
            // 關閉動畫，顯示 response 訊息
            Swal.close()

            const toast_type = response.data.type
            const toast_message = response.data.message

            Toastr(toast_type, toast_message)

            // 成功發信後導回功能總表
            if(toast_type === 'success') {
              setTimeout(() => {
                window.location.href = this.index_url
              }, 2000)
            }
          }, 2000)

        }).catch((error) => {
          Swal.close()
          Toastr('error', error)
        })
      },
      showAlert() {
        Swal.fire({
          title: '資料更新中，請稍候',
          html: '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        })
      },
    }
  }
</script>
<template>
  <div :key="key">
    <div class="mb-3 card">
      <div class="card-body">
        <billing-file-index :url="upload_url" :authenticity_token="authenticity_token" :isolated="isolated"></billing-file-index>
      </div>
    </div>
    <div class="mb-3 card" v-if="editable">
      <div class="card-header">
        <span>{{ I18n.t('edit') }}</span>
        <small class="text-danger">同檔名會直接覆蓋</small>
      </div>
      <div class="uppy-area-wrapper">
        <div class="h-100 uppy-area"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";

import XHRUpload from "@uppy/xhr-upload";
import ZH_TW from "@uppy/locales/lib/zh_TW";

export default {
  props: {
    upload_url: {
      type: String
    },
    editable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isolated: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data(){
    return {
      I18n,
      authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      key: 0
    }
  },
  updated() {
    if(this.editable){
      this.initUppy()
    }
  },
  methods: {
    initUppy(){
      const uppy = Uppy({
        logger: Uppy.debugLogger,
        meta: {
          authenticity_token: this.authenticity_token
        }
      });
      uppy.use(Dashboard, {
        showProgressDetails: true,
        closeModalOnClickOutside: true,
        hideProgressAfterFinish: true,
        locale: ZH_TW,
        inline: true,
        target: ".uppy-area",
        replaceTargetContent: true,
        height: "100%",
        width: "100%",
      });
      uppy.use(XHRUpload, {
        endpoint: this.upload_url,
        method: 'put',
        formData: true
      });
      uppy.on('complete', (result) => {
        this.key += 1
      })
    }
  },
};
</script>
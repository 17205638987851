<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.workspace/customer') }}
    </div>
    <div class="card-body">
      <div class="form-group">
        <ValidationProvider rules="required" :name="I18n.t('activerecord.models.workspace/customer')" v-slot="{ errors }" immediate>
        <input type="hidden" name='entitlement[workspace_customer_id]' v-model="select_customer_id" />
        <multiselect v-model="entitlement.workspace_customer" :custom-label="nameWithDomain" :options="customers" :placeholder="I18n.t('helpers.select.prompt')" label="org_display_name" track-by="id">
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entitlement_form: {
      type: Object,
      requred: true
    },
    customers: {
      type: Array,
      requred: true
    }
  },
  data(){
    return {
      I18n,
      entitlement: this.entitlement_form.entitlement,
      select_customer_id: this.entitlement_form.entitlement.workspace_customer_id
    }
  },
  watch: {
    'entitlement.workspace_customer': function(customer){
      this.select_customer_id = customer ? customer.id : null
      this.$emit('getCustomer')
    }
  },
  methods: {
    nameWithDomain({ org_display_name, domain, console_domain }) {
      console_domain = I18n.t(console_domain, { scope: 'simple_form.labels.customer.console_domain' })

      return org_display_name ? `${org_display_name} [${domain}] [${console_domain}]` : null
    }
  }
}
</script>
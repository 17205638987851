<template>
  <flat-pickr
                v-model="date"
                :config="config"
                class="form-control"
                :placeholder="I18n.t('helpers.select.prompt')"
                :name="name">
  </flat-pickr>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';
export default {
  components: {
    flatPickr
  },
  props: {
    name: {
      type: String
    },
    current_date: {
      type: String
    }
  },
  data(){
    return {
      I18n,
      date: null,
      config: {
        locale: MandarinTraditional,
        defaultDate: this.current_date,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            altInput: true,
            altFormat: 'Y-m',
            dateFormat: 'Y-m'
          })
        ]
      }
    }
  },
  watch: {
    'date': function(new_date){
      this.$emit('changeDate', this.date)
    }
  }
}
</script>

<style>

</style>
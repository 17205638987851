<template>
  <div class="table-responsive">
    <div class="mb-3 d-flex align-items-center">
      <div class="mr-2">TWD：台銀 USD -> TWD</div> |
      <div class="mx-2">HKD：匯豐 USD -> HKD</div> |
      <div class="mx-2">CNY：台銀 USD -> TWD -> CNY</div>
    </div>
    <table class="table datatable table-striped table-hover w-100" data-server-side="true" data-order='[[ "0", "desc" ]]' >
      <thead>
        <tr>
          <th scope="col" data-name="rate_date" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('rate_date', locale_scope) }}
          </th>
          <th scope="col" data-name="currency" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('currency', locale_scope) }}
          </th>
          <th scope="col" data-name="cash_average" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('cash_average', locale_scope) }}
          </th>
          <th scope="col" data-name="cash_buying" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('cash_buying', locale_scope) }}
          </th>
          <th scope="col" data-name="cash_selling" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('cash_selling', locale_scope) }}
          </th>
          <th scope="col" data-name="spot_average" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('spot_average', locale_scope) }}
          </th>
          <th scope="col" data-name="spot_buying" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('spot_buying', locale_scope) }}
          </th>
          <th scope="col" data-name="spot_selling" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('spot_selling', locale_scope) }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.exchange_rate'
        },
        dataTable: null,
      }
    },
    mounted() {
        this.dataTable = $(this.$el.querySelector(".datatable"))
          .DataTable({
            responsive: true,
            ajax: {
              url: `${window.location.pathname}.json`
            },
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`,
            },
          }).columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    },
  };
</script>
<template>
  <flat-pickr
                v-model="date"
                :config="config"
                class="form-control"
                :placeholder="I18n.t('helpers.select.prompt')"
                :name="name">
        </flat-pickr>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';
export default {
  components: {
    flatPickr
  },
  props: {
    name: {
      type: String
    },
    current_date: {
      type: String
    }
  },
  data(){
    return {
      I18n,
      date: null,
      config: {
        locale: MandarinTraditional,
        defaultDate: this.current_date,
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
        altFormat: 'Y-m-d H:i',
      }
    }
  },
  watch: {
    'date': function(new_date_time){
      this.$emit('changeDateTime', this.date)
    }
  }
}
</script>

<style>

</style>
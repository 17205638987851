<template>
  <draggable v-bind="dragOptions" class="list-group" tag="div" :list="manager_groups" :disable="!editable" :value="value" :group="{ name: 'ancestry_depth' }">
    <div class="list-group-item border-secondary" :key="manager_group.id" v-for="manager_group in realValue">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="d-flex align-items-center">
          <p class="mb-0 handle px-1" v-if="editable && manager_group.id != 1"><i class="fas fa-arrows-alt fa-fw"></i></p>
          <div>
            <p class="mb-0 fw-bold">
              <a :href="`/manager_groups/${manager_group.id}`">{{ manager_group.name }}</a>
            </p>
            <p class="mb-0">{{ I18n.t('managers_count', locale_scope) }}：{{ manager_group.managers_count }}</p>
          </div>
        </div>
        <div class="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups" v-if="editable">
          <div class="btn-group" role="group" aria-label="edit group">
            <a :href="`/manager_groups/${manager_group.id}/edit`" class="btn btn-primary">{{ I18n.t('edit') }}</a>
          </div>
          <div class="btn-group" role="group" aria-label="delete group" v-if="![1,2].includes(manager_group.id)">
            <a :data-confirm="I18n.t('confirm_action')" rel="nofollow" data-method="delete" :href="`/manager_groups/${manager_group.id}`" class="btn btn-danger">{{ I18n.t('destroy') }}</a>
          </div>
        </div>
      </div>
      <manager-group-item class="item-sub pb-4 bg-light" :manager_groups="manager_group.children" :editable="editable"></manager-group-item>
    </div>

  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: {
    manager_groups: {
      type: Array,
      required: false,
      default: null
    },
    value: {
      type: Array,
      required: false,
      default: null
    },
    editable: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.manager_group'
      },
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: "description",
        disabled: false,
        ghostClass: "bg-secondary",
        swapThreshold: 0.5,
        fallbackOnBody: true,
        handle: '.handle'
      };
    },
    // https://github.com/SortableJS/Vue.Draggable/blob/017ab498428efef966d72ea2be547cb0213cd6bb/example/components/nested/nested-test.vue
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.manager_groups;
    }
  }
}
</script>

<style scoped>
.item-container {
  max-width: 20rem;
  margin: 0;
}
.item {
  padding: 1rem;
  border: solid black 1px;
  background-color: #fefefe;
}
.item-sub {
  margin: 0 0 0 1rem;
}
.handle{
  cursor: grab;
}
.swap-threshold-indicator{
  background-color: red;
}
</style>
<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('option', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="custom-control custom-radio" v-for="(renewal_option, renewal_option_index) in renewal_options" :key="renewal_option_index">
          <input type="radio" class="custom-control-input" :id="`entitlement_${renewal_option_index}`" :value="renewal_option" name="entitlement[renewal_option]" v-model="entitlement.renewal_option">
          <label class="custom-control-label" :for="`entitlement_${renewal_option_index}`">{{ I18n.t(`simple_form.labels.entitlement.renewal_option.${renewal_option}`) }}</label>
        </div>
      </div>
    </div>

    <!-- 續約/恢復 -->
    <div v-if="entitlement.renewal_option == 'to_renewal' || entitlement.renewal_option == 'to_activate'">
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('detail', locale_scope) }}
        </div>
        <div class="card-body">
          <div v-if="entitlement.renewal_option == 'to_activate'" class="row">
          <!-- 啟用日 -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_adjust_at">{{ I18n.t('adjust_at', locale_scope) }}</label>
                <date-time-picker id="entitlement_adjust_at" :name="'entitlement[adjust_at]'" :current_date="entitlement.adjust_at" @changeDateTime="changeDateTime"></date-time-picker>
              </div>
            </div>
          </div>

          <!-- 選擇SKU -->
          <div class="form-group" v-if="entitlement.product_display_name != 'Google Drive storage'">
            <label for="entitlement_renewal_sku_id">{{ I18n.t('sku', locale_scope) }}</label>
            <input type="hidden" name="entitlement[renewal_sku_display_name]" v-model="selected_renewal_sku_display_name" />
            <ValidationProvider rules="required" :name="I18n.t('renewal_sku_id', locale_scope)" v-slot="{ errors }" immediate>
            <input type="hidden" name="entitlement[renewal_sku_id]" v-model="selected_renewal_sku" />
            <multiselect v-model="selected_workspace_sku" :options="workspace_skus" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="skuWithName" track-by="name"></multiselect>
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="row">
            <!-- Offer -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_renewal_offer_plan">{{ I18n.t('renewal_offer_plan', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_offer_plan', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_renewal_offer_plan" name="entitlement[renewal_offer_plan]" v-model="entitlement.renewal_offer_plan" class="custom-select">
                  <option v-for="(renewal_offer_plan, renewal_offer_plan_index) in renewal_offer_plan_groups" :key="renewal_offer_plan_index" :value="renewal_offer_plan.value">
                    {{ renewal_offer_plan.text }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- 合約期間 -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_renewal_duration">{{ I18n.t('renewal_duration', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_duration', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_renewal_duration" name="entitlement[renewal_duration]" class="custom-select" v-model="entitlement.renewal_duration">
                  <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                    {{ duration.text }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- 授權數 -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_renewal_units">{{ I18n.t('renewal_units', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_units', locale_scope)" v-slot="{ errors }" immediate>
                <input type="number" min="0" id="entitlement_renewal_units" class="form-control" name='entitlement[renewal_units]' v-model.trim="entitlement.renewal_units" @input="checked" />
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('payment_conditions', locale_scope) }}
        </div>
        <div class="card-body">
          <div class="row">
            <!-- 開通服務付款方式 -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="entitlement_renewal_activate_payment_method">{{ I18n.t('renewal_activate_payment_method', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_activate_payment_method', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_renewal_activate_payment_method" name="entitlement[renewal_activate_payment_method]" class="custom-select" v-model="entitlement.renewal_activate_payment_method">
                  <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                  <option v-for="(activate_payment_method, activate_payment_method_index) in activate_payment_methods" :key="activate_payment_method_index" :value="activate_payment_method">
                    {{ I18n.t(`simple_form.labels.entitlement.activate_payment_methods.${activate_payment_method}`) }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 暫停 -->
    <div v-if="entitlement.renewal_option == 'to_suspend'" class="mb-3 card">
      <div class="card-body">
        <!-- 彈性付款方案的訂閱，可編輯結束時間 -->
        <div v-if="entitlement.offer_plan == 'Flexible Plan'" class="row">
          <div class="form-group col-12 col-sm-6">
            <label for="entitlement_end_at">{{ I18n.t('end_at', locale_scope) }}</label>
            <date-time-picker id="entitlement_end_at" :name="'entitlement[end_at]'" :current_date="entitlement.end_at" @changeEndAt="changeEndAt"></date-time-picker>
          </div>
        </div>

        <p class="text-danger">注意 : 暫停後客戶將無法使用此訂閱。暫停時間最長可達 60 天，超過即無法恢復此訂閱。</p>
      </div>
    </div>

    <!-- 合約中途調整 -->
    <div v-if="entitlement.renewal_option == 'to_adjust'">
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('detail', locale_scope) }}
        </div>
        <div class="card-body">
          <div class="row">
            <!-- 調整啟用日 -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="entitlement_adjust_at">{{ I18n.t('adjust_at', locale_scope) }}</label>
                <date-time-picker id="entitlement_adjust_at" :name="'entitlement[adjust_at]'" :current_date="entitlement.adjust_at" @changeDateTime="changeDateTime"></date-time-picker>
              </div>
            </div>
            <!-- 合約期間 -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="entitlement_renewal_duration">{{ I18n.t('renewal_duration', locale_scope) }}</label>
                <select id="entitlement_renewal_duration" name="entitlement[renewal_duration]" class="custom-select" v-model="entitlement.renewal_duration">
                  <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                    {{ duration.text }}
                  </option>
                </select>
                <small class="text-muted d-block">將會影響合約的結束時間。</small>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- 選擇SKU -->
            <div class="col-12 col-sm-6" v-if="entitlement.product_display_name != 'Google Drive storage'">
              <div class="form-group">
                <label for="entitlement_renewal_sku_id">{{ I18n.t('sku', locale_scope) }}</label>
                <input type="hidden" v-if="selected_workspace_sku" name="entitlement[renewal_sku_display_name]" v-model="selected_renewal_sku_display_name" />
                <input type="hidden" v-if="selected_workspace_sku" name="entitlement[renewal_sku_id]" v-model="selected_renewal_sku" />
                <multiselect v-model.trim="selected_workspace_sku" :options="changeable_skus" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="skuWithName" track-by="name"></multiselect>
              </div>
            </div>
            <!-- 授權數 -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="entitlement_renewal_units">{{ I18n.t('renewal_units', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_units', locale_scope)" v-slot="{ errors }" immediate>
                <input type="number" min="0" id="entitlement_renewal_units" class="form-control" name='entitlement[renewal_units]' v-model.trim="entitlement.renewal_units" @input="checked" />
                <small class="text-muted d-block">加購請填寫：總授權數(目前授權數 + 需增加的授權數) <br> 調降請填寫：總授權數(目前授權數 - 需減少的授權數)</small>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('payment_conditions', locale_scope) }}
        </div>
        <div class="card-body">
          <div class="row">
            <template v-if="entitlement.renewal_duration == null && entitlement.renewal_units > 0">
              <!-- 加購服務付款方式 -->
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="entitlement_renewal_add_on_payment_method">{{ I18n.t('renewal_add_on_payment_method', locale_scope) }}</label>
                  <ValidationProvider rules="required" :name="I18n.t('renewal_add_on_payment_method', locale_scope)" v-slot="{ errors }" immediate>
                  <select id="entitlement_renewal_add_on_payment_method" name="entitlement[renewal_add_on_payment_method]" class="custom-select" v-model="entitlement.renewal_add_on_payment_method">
                    <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                    <option v-for="(add_on_payment_method, add_on_payment_method_index) in add_on_payment_methods" :key="add_on_payment_method_index" :value="add_on_payment_method">
                      {{ I18n.t(`simple_form.labels.entitlement.add_on_payment_methods.${add_on_payment_method}`) }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </template>

            <!-- 開通服務付款方式 -->
            <template v-else-if="entitlement.renewal_duration">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="entitlement_renewal_activate_payment_method">{{ I18n.t('renewal_activate_payment_method', locale_scope) }}</label>
                  <ValidationProvider rules="required" :name="I18n.t('renewal_activate_payment_method', locale_scope)" v-slot="{ errors }" immediate>
                  <select id="entitlement_renewal_activate_payment_method" name="entitlement[renewal_activate_payment_method]" class="custom-select" v-model="entitlement.renewal_activate_payment_method">
                    <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                    <option v-for="(activate_payment_method, activate_payment_method_index) in activate_payment_methods" :key="activate_payment_method_index" :value="activate_payment_method">
                      {{ I18n.t(`simple_form.labels.entitlement.activate_payment_methods.${activate_payment_method}`) }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 試用轉正式 -->
    <div v-if="entitlement.is_trial && entitlement.renewal_option == 'to_paid'">
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('detail', locale_scope) }}
        </div>
        <div class="card-body">
          <div class="row">
            <!-- 執行日期 -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_adjust_at">{{ I18n.t('adjust_at', locale_scope) }}</label>
                <date-time-picker id="entitlement_adjust_at" :name="'entitlement[adjust_at]'" :current_date="entitlement.adjust_at" @changeDateTime="changeDateTime"></date-time-picker>
              </div>
            </div>
          </div>
          <!-- 選擇SKU -->
          <div class="form-group">
            <label for="entitlement_renewal_sku_id">{{ I18n.t('sku', locale_scope) }}</label>
            <input type="hidden" v-if="selected_workspace_sku" name="entitlement[renewal_sku_display_name]" v-model="selected_renewal_sku_display_name" />
            <ValidationProvider rules="required" :name="I18n.t('renewal_sku_id', locale_scope)" v-slot="{ errors }" immediate>
            <input type="hidden" v-if="selected_workspace_sku" name="entitlement[renewal_sku_id]" v-model="selected_renewal_sku" />
            <multiselect v-model.trim="selected_workspace_sku" :options="changeable_skus" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="skuWithName" track-by="name"></multiselect>
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="row">
            <!-- Offer -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_renewal_offer_plan">{{ I18n.t('renewal_offer_plan', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_offer_plan', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_renewal_offer_plan" name="entitlement[renewal_offer_plan]" v-model="entitlement.renewal_offer_plan" class="custom-select">
                  <option v-for="(renewal_offer_plan, renewal_offer_plan_index) in renewal_offer_plan_groups" :key="renewal_offer_plan_index" :value="renewal_offer_plan.value">
                    {{ renewal_offer_plan.text }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- 合約期間 -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_renewal_duration">{{ I18n.t('duration', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_duration', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_renewal_duration" name="entitlement[renewal_duration]" class="custom-select" v-model="entitlement.renewal_duration">
                  <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                    {{ duration.text }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- 授權數 -->
            <div class="col-12 col-sm-4">
              <div class="form-group">
                <label for="entitlement_renewal_units">{{ I18n.t('renewal_units', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_units', locale_scope)" v-slot="{ errors }" immediate>
                <input type="number" min="0" id="entitlement_renewal_units" class="form-control" name='entitlement[renewal_units]' v-model.trim="entitlement.renewal_units" @input="checked"/>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 card">
        <div class="card-header">
          {{ I18n.t('payment_conditions', locale_scope) }}
        </div>
        <div class="card-body">
          <div class="row">
            <!-- 開通服務付款方式 -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="entitlement_renewal_activate_payment_method">{{ I18n.t('renewal_activate_payment_method', locale_scope) }}</label>
                <ValidationProvider rules="required" :name="I18n.t('renewal_activate_payment_method', locale_scope)" v-slot="{ errors }" immediate>
                <select id="entitlement_renewal_activate_payment_method" name="entitlement[renewal_activate_payment_method]" class="custom-select" v-model="entitlement.renewal_activate_payment_method">
                  <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                  <option v-for="(activate_payment_method, activate_payment_method_index) in activate_payment_methods" :key="activate_payment_method_index" :value="activate_payment_method">
                    {{ I18n.t(`simple_form.labels.entitlement.activate_payment_methods.${activate_payment_method}`) }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 加購 -->
    <entitlement-add-on :entitlement="entitlement" :created_by_id="created_by_id" :add_on_skus="add_on_skus" v-if="entitlement.product_display_name == 'Google Workspace'"></entitlement-add-on>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {
  components: {
    Multiselect,
  },
  props: {
    entitlement_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      lodash,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/entitlement'
      },
      entitlement: this.entitlement_form.entitlement,
      selected_renewal_sku: this.entitlement_form.entitlement.renewal_sku_id,
      selected_renewal_sku_display_name: this.entitlement_form.entitlement.renewal_sku_display_name,
      renewal_options: this.entitlement_form.renewal_options,
      selected_renewal_option: this.entitlement_form.entitlement.renewal_option,
      selected_workspace_sku: this.entitlement_form.workspace_skus.find((workspace_sku) => {
        return workspace_sku.name.split('/')[3] === this.entitlement_form.entitlement.renewal_sku_id
      }),
      duration_groups: [
        { value: '1', text: '1 個月' },
        { value: '2', text: '2 個月' },
        { value: '3', text: '3 個月' },
        { value: '4', text: '4 個月' },
        { value: '5', text: '5 個月' },
        { value: '6', text: '6 個月' },
        { value: '7', text: '7 個月' },
        { value: '8', text: '8 個月' },
        { value: '9', text: '9 個月' },
        { value: '10', text: '10 個月' },
        { value: '11', text: '11 個月' },
        { value: '12', text: '1 年' },
        { value: '24', text: '2 年' },
        { value: '36', text: '3 年' }
      ],
      workspace_skus: this.entitlement_form.workspace_skus,
      changeable_skus: [],
      add_on_skus: [],
      add_ons: this.entitlement_form.entitlement.add_ons,
      created_by_id: this.entitlement_form.created_by_id,
      cancelSource: null,
      renewal_offer_plan_groups: [
        { value: 'Flexible Plan', text: 'Flexible Plan' },
        { value: 'Annual Plan (Monthly Payment)', text: 'Annual Plan (Monthly Payment)' }
      ],
      current_sku: null,
      activate_payment_methods: this.entitlement_form.activate_payment_methods,
      add_on_payment_methods: this.entitlement_form.add_on_payment_methods
    }
  },
  mounted(){
    this.$nextTick(() => {
      if(this.entitlement){
        this.setSelectableChangeSkus(this.entitlement);
        this.setSelectableAddOnSkus(this.entitlement);
        this.current_sku = {
          marketing_info: { display_name: this.entitlement.sku_display_name },
          name: `products/${this.entitlement.product_id}/skus/${this.entitlement.sku_id}`
        }
      }
    })
  },
  methods: {
    setSelectableChangeSkus(){
      axios.get('/changeable_skus.json', {
        params: {
          customer_id: this.entitlement.workspace_customer.customer_id,
          entitlement_id: this.entitlement.entitlement_id,
          console_domain: this.entitlement.workspace_customer.console_domain
        }
      }).then((response) => {
        if(response.data){
          this.changeable_skus = response.data.upgradable_skus.concat(response.data.downgradable_skus, this.current_sku)
        }
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    },
    skuWithName({marketing_info}){
      return marketing_info.display_name
    },
    setSelectableAddOnSkus(){
      axios.get('/purchasable_skus.json', {
        params: {
          customer_id: this.entitlement.workspace_customer.customer_id,
          console_domain: this.entitlement.workspace_customer.console_domain
        }
      }).then((response) => {
        if(response.data){
          this.add_on_skus = response.data
        }
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    },
    checked(){
      if (this.cancelSource){
        this.cancelSource.cancel()
      }
      this.cancelSource = axios.CancelToken.source();
      axios.get('/sync_assign_units', {
        cancelToken: this.cancelSource.token,
        params: {
          customer_id: this.entitlement.workspace_customer.customer_id,
          entitlement_id: this.entitlement.entitlement_id,
          console_domain: this.entitlement.workspace_customer.console_domain
        }
      }).then((response) => {
        const assigned_units = response.data.assign_units
        const renewal_units = this.entitlement_form.entitlement.renewal_units

        if(renewal_units < assigned_units){
          Toastr('error', '警告：目前已指派授權數大於續約授權數，請客戶調整')
          this.entitlement.renewal_units = assigned_units
        }
        this.cancelSource = null
      }).catch((error) => {

      })
    },
    changeDateTime(date){
      this.entitlement.adjust_at = date
    },
    changeEndAt(date){
      this.entitlement.end_at = date
    },
    clearRenewalOffer(){
      this.entitlement.renewal_offer_plan = null
    },
    clearRenewalDuration(){
      this.entitlement.renewal_duration = null
    }
  },
  watch: {
    'selected_workspace_sku': function(sku){
      this.selected_renewal_sku = sku ? sku.name.split('/')[3] : null
      this.selected_renewal_sku_display_name = sku ? sku.marketing_info.display_name : null

      this.clearRenewalOffer()
      this.clearRenewalDuration()
    }
  },
}
</script>
<template>
  <div class="mb-5 card">
    <div class="card-header card-title">
      <span class="mr-3">{{ I18n.t('upload_rawdata_title', locale_scope) }}</span>
      <span class="text-danger">{{ I18n.t('file_format', locale_scope) }}</span>
      <div class='badge badge-pill float-right' :class="cssClass()">
        {{ I18n.t(`activerecord.separate_marketplaces.${this.task_status}`) }}
      </div>
    </div>
    <div class='card-body'>
      <div class="uppy-area-wrapper uppy-height inline: true">
        <div id="upload-marketplace"></div>
      </div>
      <button
        :disabled="isButtonDisabled"
        @click="startMarkRawdata"
        class="d-block mb-3 mx-auto btn btn-success w-25"
        type="button"
      >
        {{ I18n.t('start_check_rawdata', locale_scope) }}
      </button>
      <div class="p-3 rounded d-flex justify-content-between align-items-center msg-block">
        <div v-if="task_status != 'fail'">
          <span class="mr-3">{{ I18n.t('download_link_notice', locale_scope) }}</span>
          <span v-if="task_status === 'done'">
            <a :href='dowanload_url'>download</a>
          </span>
        </div>
        <div v-else>
          <p v-for="(err_msg_key, index) in Object.keys(err_msgs)" :key="index" class="text-danger">
            <span v-if="err_msg_key === 'main'"> {{ I18n.t('error_message_notice',locale_scope) }} 請檢查SKU ID</span>
            <span v-else>
              <span class="mr-2">{{ err_msg_key }}</span>
              <span>{{ err_msgs[err_msg_key] }}</span>
            </span>
          </p>
        </div>
        <button 
          v-if="task_status === 'fail' || task_status === 'done' "
          @click="windowReload"
          class="d-block btn btn-success w-25 bg-warning text-dark"
          type="button"
        >
          {{ I18n.t('reupload_rawdata', locale_scope) }}
        </button>
      </div>
      <div 
      v-if="task_status != 'fail'"
      class="text-danger mt-2">{{ I18n.t('mark_files_need_more_time', locale_scope) }}
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import Uppy from '@uppy/core';
  import Dashboard from '@uppy/dashboard';
  import XHRUpload from '@uppy/xhr-upload';
  import ZH_TW from '@uppy/locales/lib/zh_TW';

  export default {
    props: {
      url: {
        type: String,
        requred: true
      }
    },
    data(){
      return{
        locale_scope: { scope: 'activerecord.separate_marketplaces'},
        authenticity_token: null,
        dowanload_url: `${window.location.pathname}/dowanload_url`,
        task_status: 'pending',
        err_msgs: {},
        isButtonDisabled: true,
      }
    },
    mounted() {
      this.authenticity_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      this.initUppy()
    },
    methods: {
      initUppy() {
        const uppy = Uppy({
          logger: Uppy.debugLogger,
          meta: {
            authenticity_token: this.authenticity_token
          },
          restrictions: {
            allowedFileTypes: ['.csv'],
            maxNumberOfFiles: 10
          }
        });
        uppy.use(Dashboard, {
          showProgressDetails: true,
          closeModalOnClickOutside: true,
          hideProgressAfterFinish: true,
          locale: ZH_TW,
          inline: true,
          target: '#upload-marketplace',
          replaceTargetContent: true,
          height: '100%',
          width: '100%',
        });
        uppy.use(XHRUpload, {
          endpoint: this.url,
          method: 'post',
          formData: true,
          bundle: true,
        });
        uppy.on('complete', (result) => {
          if (result.failed) {
            this.isButtonDisabled = false
          } 
        })
      },
      startMarkRawdata(){
        this.isButtonDisabled = true;
        axios.defaults.headers.common['X-CSRF-TOKEN'] = this.authenticity_token
        axios.post(`${window.location.pathname}/start_mark_rawdata`)
             .then(({data}) => {
               if(data.success) {
                 Toastr('success', data.message)
                 this.task_status = 'processing'

                 // 每 10 秒確認是否比對完成
                 setTimeout(() => { this.pollingCheckTaskStatus() }, 10000)
               } else {
                 this.isButtonDisabled = false;
                 Toastr('error', I18n.t('reupload_correct_files', this.locale_scope))
               }
             }).catch((e) => {
               Toastr('error', '請上傳檔案或檢查檔案格式')
             })
      },
      pollingCheckTaskStatus() {
        axios.get(`${window.location.pathname}/check_status`)
             .then(({data}) => {
               if(data.status === 'processing') {
                 setTimeout(() => { this.pollingCheckTaskStatus() }, 10000)
               }
               this.task_status = data.status
               this.err_msgs = data.err_msg
             })
             .catch((e) => {
              Toastr('error', I18n.t('system_error', this.locale_scope))
             })
      },
      windowReload(){
        location.reload()
      },
      cssClass() {
        const cssClassTable = {
          pending: 'badge-warning',
          processing: 'badge-primary',
          done: 'badge-success',
          fail: 'badge-danger',
        }
        return cssClassTable[this.task_status]
      },
    }
  }
</script>

<style>
  .card-title {
    background-color: #EFEEED;
  }
  .msg-block {
    background-color: #D3D3D3;
  }
  .uppy-height {
    height: 530px;
  }
  .uppy-Dashboard-innerWrap {
    height: 530px;
  }

  .uppy-DashboardContent-addMore {
    display: none;
  }
</style>

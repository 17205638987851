<template>
  <div class="form-group">
    <button class="m-3 btn btn-primary" @click="genPreviewReport">寄發預覽報表</button>
    <span class="text-danger">產出檔案大約 1 小時，完成後會以信件方式提供，請勿反覆點擊</span>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data(){
      return {}
    },
    methods: {
      genPreviewReport() {
        const headers = { 'Content-Type': 'application/json', 'X-CSRF-Token': this.authenticity_token }

        axios.post(`${window.location.pathname}/generate_preview_report`, "", { headers: headers })
        .then((response) => {
          const toast_type = response.data.type
          const toast_message = response.data.message

          setTimeout(() => {
            Toastr(toast_type, toast_message)
          }, 1000);
        })
        .catch((error) => {
          Toastr('error', error)
        })
      }
    }
  }
</script>
<template>
  <div class="col-12">
    <div class="form-group">
      <label>{{ I18n.t('tax_percent', locale_scope) }}</label>
      <div class="row">
        <div class="input-group col">
          <input type="hidden" name="contract[tax_percent]" v-model="tax_option.decimal_value">
          <input type="hidden" name="contract[tax_name]" v-model="tax_option.text_value">
          <multiselect class="w-75" v-model="tax_option" :options="tax_options" :placeholder="I18n.t('helpers.select.prompt')" label="display_text" track-by="id" :show-labels="false">
          </multiselect>
          <div class="input-group-append">
            <label class="input-group-text" for="contract_tax_percent">％</label>
          </div>
        </div>
        <div class="mt-2 col">
          {{ showTaxNote() }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contract: {
      type: Object
    },
    tax_options: {
      type: Array
    },
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.contract'
      },
      tax_option: this.tax_options.find(tax => tax.decimal_value === this.contract.tax_percent && tax.text_value === this.contract.tax_name) || {}
    }
  },
  methods: {
    showTaxNote() {
      if (this.tax_option) {
        return this.tax_option.note
      } else {
        return ''
      }
    }
  }
}
</script>

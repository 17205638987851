<template>
  <div class="mb-3 card">
    <div class="card-header">
      <input name="contract[delegate_enabled]" type="hidden" value="0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="contract_delegate_enabled" name="contract[delegate_enabled]" v-model="contract.delegate_enabled">
        <label class="custom-control-label"  for="contract_delegate_enabled">FET Support</label>
      </div>
    </div>

    <div class="card-body" v-show="contract.delegate_enabled">
      <input type="hidden" name="contract[fet_support_attributes][category]" value="fet">
      <input type="hidden" name="contract[fet_support_attributes][id]" v-model="fet_support.id">
      <input type="hidden" name="contract[fet_support_attributes][_destroy]" v-model="fet_support._destroy">

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="fet_support_name">{{ I18n.t('name', locale_scope) }}</label>
            <input type="hidden" name="contract[fet_support_attributes][name]" v-model.trim="fet_support.name" >
            <multiselect v-model.trim="fet_support.name" :options="fet_names" :placeholder="I18n.t('helpers.select.prompt')" :show-labels="false" :taggable="true" @tag="addName" :allow-empty="false" :tag-placeholder="I18n.t('create_option')" >
              <template slot="noOptions">
                {{ I18n.t('list_empty') }}
              </template>
            </multiselect>
            <small class="text-muted d-block">{{I18n.t('can_create_option')}} </small>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="fet_support_charge_percent">{{ I18n.t('charge_percent', locale_scope) }}</label>
            <div class="input-group">
              <input type="text" id="fet_support_charge_percent" class="form-control" name='contract[fet_support_attributes][charge_percent]' v-model="fet_support.charge_percent">
              <div class="input-group-append">
                <span class="input-group-text">％</span>
              </div>
            </div>
            <div v-html="percent_msg"></div>
            <div v-html="show_warning"></div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="fet_support_charge_minimum">{{ I18n.t('charge_minimum', locale_scope) }}</label>
            <input type="text" id="fet_support_charge_minimum" class="form-control form-input" name='contract[fet_support_attributes][charge_minimum]' v-model="fet_support.charge_minimum">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="fet_support_currency">{{ I18n.t('currency', locale_scope) }}</label>
            <select v-model="fet_support.currency" id="fet_support_currency" name="contract[fet_support_attributes][currency]" class="custom-select">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(currency, currency_index) in currencies" :value="currency.text_value" :key="currency_index">
                  {{ currency.display_text }}
                </option>
              </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validPercent, showWarning, percentMsg }  from '../../../javascripts/valid_percent';

export default {
  props: {
    contract: {
      type: Object
    },
    currencies: {
      type: Array
    },
    delegate_names: {
      type: Array
    },
    blank_fet_support: {
      type: Object
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.contract_support'
      },
      fet_support: this.contract.fet_support || this.blank_fet_support,
      fet_names: this.delegate_names,
      show_warning: '',
      percent_msg: ''
    }
  },
  created(){
    if(this.contract.fet_support) {
      this.fet_support.name = this.contract.fet_support.name
    } else {
      this.fet_support.name = this.fet_names[0]
    }

    if(!this.contract.delegate_enabled){
      this.fet_support._destroy = '-1'
    }
  },
  methods: {
    addName(new_name) {
      this.fet_support.name = new_name
      this.fet_names.push(new_name)
    },
  },
  mounted() {
    this.show_warning = showWarning(this.fet_support.charge_percent)
    this.percent_msg = percentMsg(this.fet_support.charge_percent, 1)
  },
  watch: {
    'contract.delegate_enabled': function(status){
      if(status){
        this.fet_support._destroy = null
      } else {
        this.fet_support._destroy = '-1'
      }
    },
    'contract.currency': function(currency){
      this.fet_support.currency = currency
    },
    'fet_support.charge_percent': function(percent){
      this.validPercent('fet_support', percent)
    },
    'fet_support.charge_percent': function(percent) {
      this.fet_support.charge_percent = validPercent(percent)
      this.show_warning = showWarning(percent)
      this.percent_msg = percentMsg(percent, 1)
    }
  }
}
</script>

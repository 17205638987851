<template>
  <div>
    <entitlement-customer @getCustomer="setSelectableSkus" :entitlement_form="entitlement_form" :customers="customers"></entitlement-customer>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('detail', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="row">
          <!-- 開始時間 -->
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('start_at', locale_scope)" v-slot="{ errors }" immediate>
              <date-time-picker id="entitlement_start_at" :name="'entitlement[start_at]'" :current_date="entitlement.start_at" @changeDateTime="changeDateTime"></date-time-picker>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- 選擇SKU -->
        <div class="form-group">
          <label for="entitlement_sku_id">{{ I18n.t('sku', locale_scope) }}</label>
          <input type="hidden" name="entitlement[product_id]" v-model="selected_product_id" />
          <input type="hidden" name="entitlement[product_display_name]" v-model="selected_product_display_name" />
          <input type="hidden" name="entitlement[sku_display_name]" v-model="selected_sku_display_name" />

          <ValidationProvider rules="required" :name="I18n.t('sku', locale_scope)" v-slot="{ errors }" immediate>
          <input type="hidden" name="entitlement[sku_id]" v-model="selected_sku" />
          <multiselect v-model.trim="entitlement.sku_id" :options="purchasable_skus" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="skuWithName" track-by="name">
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!-- 選擇Offer -->
        <div class="form-group">
          <label for="entitlement_offer_plan">{{ I18n.t('offer_plan', locale_scope) }}</label>

          <input type="hidden" name="entitlement[offer]" v-model="selected_offer" />
          <ValidationProvider rules="required" :name="I18n.t('renewal_offer_plan', locale_scope)" v-slot="{ errors }" immediate>
          <input type="hidden" name="entitlement[offer_plan]" v-model="selected_offer_plan" />
          <multiselect v-model.trim="entitlement.offer_plan" :options="purchasable_offers" :placeholder="I18n.t('helpers.select.prompt')" label="display_name" track-by="name">
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row">
          <!-- 合約期間 -->
          <div v-if="selected_offer_plan == 'Trial Plan'" class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_duration">{{ I18n.t('duration', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('duration', locale_scope)" v-slot="{ errors }" immediate>
              <select id="entitlement_duration" name="entitlement[duration]" class="custom-select" v-model="entitlement.duration">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option value="1">
                  {{ '1 個月' }}
                </option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div v-else class="col-12 col-sm-6">
            <div class="form-group">
              <label for="entitlement_duration">{{ I18n.t('duration', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('duration', locale_scope)" v-slot="{ errors }" immediate>
              <select id="entitlement_duration" name="entitlement[duration]" class="custom-select" v-model="entitlement.duration">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(duration, duration_index) in duration_groups" :key="duration_index" :value="duration.value">
                  {{ duration.text }}
                </option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- 授權數 -->
          <div class="col-12 col-sm-6" v-if="this.selected_sku == 'SRRpRk3rG7zvB3' || this.selected_sku == 'SEVlaChVhiSE2J' || this.selected_sku == 'SkYiNEdAxQfMSL'">
            <div class="form-group">
              <label for="entitlement_units">{{ I18n.t('units', locale_scope) }}</label>
              <ValidationProvider rules="required|busniss_max_units" :name="I18n.t('units', locale_scope)" v-slot="{ errors }" immediate>
              <input type="number" min="1" id="entitlement_units" class="form-control" name='entitlement[units]' v-model.trim="entitlement.units" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-6" v-else-if="this.selected_sku == 'Svg9jVUmuT7H3I' || this.selected_sku == 'UVOlkJnG7YE46M'">
            <div class="form-group">
              <label for="entitlement_units">{{ I18n.t('units', locale_scope) }}</label>
              <ValidationProvider rules="required|enterprise_max_units" :name="I18n.t('units', locale_scope)" v-slot="{ errors }" immediate>
              <input type="number" min="1" id="entitlement_units" class="form-control" name='entitlement[units]' v-model.trim="entitlement.units" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-6" v-else>
            <div class="form-group">
              <label for="entitlement_units">{{ I18n.t('units', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('units', locale_scope)" v-slot="{ errors }" immediate>
              <input type="number" min="1" id="entitlement_units" class="form-control" name='entitlement[units]' v-model.trim="entitlement.units" />
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.selected_offer_plan == 'Annual Plan (Monthly Payment)' || this.selected_offer_plan == 'Flexible Plan'">
      <entitlement-payment-condition :entitlement="entitlement" :activate_payment_methods="activate_payment_methods"></entitlement-payment-condition>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import { extend } from "vee-validate"

extend("busniss_max_units", {
  // business family
  validate(value) {
    return value < 301;
  },
  message: '授權數量上限必須介於 1 至 300 之間',
})

extend("enterprise_max_units", {
  // enterprise family
  validate(value) {
    return value < 50001;
  },
  message: '授權數量上限必須介於 1 至 50000 之間',
})

export default {
  components: {
    Multiselect,
  },
  props: {
    entitlement_form: {
      type: Object,
      requred: true
    },
  },
  data(){
    return {
      lodash,
      locale_scope: {
        scope: 'activerecord.attributes.workspace/entitlement'
      },
      entitlement: this.entitlement_form.entitlement,
      selected_sku: this.entitlement_form.entitlement.sku_id,
      selected_sku_display_name: this.entitlement_form.entitlement.sku_display_name,
      selected_product_id: this.entitlement_form.entitlement.product_id,
      selected_product_display_name: this.entitlement_form.entitlement.product_display_name,
      duration_groups:[
        { value: '1', text: '1 個月' },
        { value: '2', text: '2 個月' },
        { value: '3', text: '3 個月' },
        { value: '4', text: '4 個月' },
        { value: '5', text: '5 個月' },
        { value: '6', text: '6 個月' },
        { value: '7', text: '7 個月' },
        { value: '8', text: '8 個月' },
        { value: '9', text: '9 個月' },
        { value: '10', text: '10 個月' },
        { value: '11', text: '11 個月' },
        { value: '12', text: '1 年' },
        { value: '24', text: '2 年' },
        { value: '36', text: '3 年' }
      ],
      purchasable_skus: [],
      customers: this.entitlement_form.customers,
      sku_path: null,
      purchasable_offers: [],
      selected_offer_plan: this.entitlement_form.entitlement.offer_plan,
      selected_offer: this.entitlement_form.entitlement.offer,
      activate_payment_methods: this.entitlement_form.activate_payment_methods,
      add_on_payment_methods: this.entitlement_form.add_on_payment_methods
    }
  },
  methods: {
    setSelectableSkus(){
      axios.get('/purchasable_skus.json', {
        params: {
          customer_id: this.entitlement_form.entitlement.workspace_customer.customer_id,
          console_domain: this.entitlement_form.entitlement.workspace_customer.console_domain
        }
      }).then((response) => {
        if(response.data.length > 0){
          this.purchasable_skus = response.data
        } else {
          this.purchasable_skus = []
          Toastr('error', '查無此客戶可購買的方案')
        };
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    },
    skuWithName({marketing_info}){
      return marketing_info.display_name
    },
    changeDateTime(date){
      this.entitlement.start_at = date
    },
    getOffer(){
      axios.get('/purchasable_offers.json', {
        params: {
          customer_id: this.entitlement_form.entitlement.workspace_customer.customer_id,
          console_domain: this.entitlement_form.entitlement.workspace_customer.console_domain,
          sku_path: this.sku_path
        }
      }).then((response) => {
        if(response.status == 200){
          this.purchasable_offers = response.data
        }
      }).catch((error) => {
        Toastr('error', I18n.t('error'))
      })
    },
    resetSelectedOffer(){
      this.purchasable_offers = []
      this.entitlement.offer_plan = null

      this.getOffer()
    },
    clearSelectedDuration(){
      this.entitlement.duration = null
    }
  },
  watch: {
    'entitlement.sku_id': function(sku){
      this.selected_sku = sku ? sku.name.split('/')[3] : null
      this.selected_sku_display_name = sku ? sku.marketing_info.display_name : null
      this.selected_product_id = sku ? sku.name.split('/')[1] : null
      this.selected_product_display_name = sku ? sku.product.marketing_info.display_name : null
      this.sku_path = sku ? sku.name : null

      this.resetSelectedOffer()
      this.clearSelectedDuration()
    },
    'entitlement.offer_plan': function(offer){
      this.selected_offer_plan = offer ? offer.display_name : null
      this.selected_offer = offer ? offer.name : null
    }
  },
}
</script>